import { Component, OnInit, ViewChild, SimpleChanges, OnChanges, HostListener, OnDestroy, ElementRef, Input } from '@angular/core';
import { ClinicServicesComponent } from '../clinic-services/clinic-services.component';
import { OrderSummaryService } from '../order-summary.service';
import { ProductSelectionService } from '../product-selection.service';
import { ModelService } from '../model.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { CalculateTotalService } from '../calculate-total.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { UserService } from '../user.service';
import { environment } from '../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { element } from 'protractor';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-secure-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit, OnChanges, OnDestroy {
  public product: any;
  public modelData: any;
  public subscribeToProductsUpgrade: Subscription;
  public checkoutButtonS: Subscription;
  public localhost = environment.serverUrl;
  public clinicData = 'clinicData';
  public status: boolean;
  public backgroundClass: string;
  public total: any;
  public checkout: Object;
  public Height: number;
  public disabled: string;
  public evergreenEnabled: any;
  public packageInfo: any;
  public showCheckStatus = true;
  public mobileLayout = false;
  public addonIdArray: any[] = [];

  public CBAdded: string;
  public RAAdded: string;
  public token = 'token';
  public pageToken: any;
  @Input() checkStatus;
  constructor(private order: OrderSummaryService,
    private session: SessionStorageService,
    private local: LocalStorageService,
    private productionSelection: ProductSelectionService,
    private model: ModelService,
    private modalService: NgbModal,
    private route: Router,
    private calculate: CalculateTotalService,
    private spinner: SpinnerService,
    private user: UserService,
    public breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private el: ElementRef) {
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.Height = event.target.innerHeight;
    const adjustedHeight = this.Height - 100;
    const adjustToString = adjustedHeight.toString();
    const selectHeight = document.getElementById('bottom-wrapper');
    selectHeight.style.top = adjustToString + 'px';
  }
  ngOnInit() {
    this.packageInfo = JSON.parse(sessionStorage.getItem('packageInfo'));
    this.evergreenEnabled = JSON.parse(sessionStorage.getItem('evergreenEnabled'));
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.pageToken = sessionStorage.getItem('token');
    this.http.post(`${this.localhost}/commerce/products/countrylist_get`, `token=${this.pageToken}`,
      { headers: header })
      .subscribe(cl => {
        sessionStorage.setItem('countries', JSON.stringify(cl));
      });
    if (this.route.url === '/Customize') {
      this.checkStatus = true;
    }
    if (this.route.url === `/Upgrade?token=${this.pageToken}`) {
      let gotProducts = true;
      this.subscribeToProductsUpgrade = this.model.modelAddons.subscribe((p) => {
        if (p) {
          const allProducts = [];
          this.product = p;
          this.bgColorCheck(this.product);
          p.filter(function(val, inde) {
            allProducts.push(val);
          });
          this.product = allProducts;
        } else {
          gotProducts = false;
        }
      });
      this.checkoutButtonS = this.model.checkoutStatusButton.subscribe((s) => {
        if (s === 'Your Plan') {
          this.checkout = {
            button: 'Checkout',
            isSubmitable: false,
            formButton: false,
            email: ''
          };
        }
      });
      if (gotProducts) {
        this.product = JSON.parse(sessionStorage.getItem('modelData').replace('\\\\n\\\\n', '<br>'));
      }
    } else {
      this.product = JSON.parse(sessionStorage.getItem('modelData').replace('\\\\n\\\\n', '<br>'));
    }
    this.bgColorCheck(this.product);
    this.user.getCheckoutStatus(true);
    this.user.getUserNames(JSON.parse(sessionStorage.getItem('user')));
    this.Height = (window.innerHeight);
    const adjustedHeight = this.Height - 100;
    const selectHeight = document.getElementById('bottom-wrapper');
    const adjustToString = adjustedHeight.toString();
    selectHeight.style.top = adjustToString + 'px';
    this.checkout = {
      button: 'Checkout',
      statusText: 'something',
      isSubmitable: true,
      formButton: false
    };

    setTimeout(() => {
      this.purchaseStatus();
    }, 0);

    const data = JSON.parse(sessionStorage.getItem('total'));
    if (data) {
      setTimeout(function() {
        this.calculate.setTotalValue(data.total);
      }, 200);
    }

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileLayout = true;
        } else {
          this.mobileLayout = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkout) {
      if (changes.checkout.currentValue.isSubmitable) {
        this.disabled = 'btn-primary';
      }
    }
  }

  ngOnDestroy() {
    sessionStorage.setItem('shippable', JSON.stringify(this.model.shippableProduct));
  }

  public checkStatusOutput(val) {
    this.checkStatus = val;
  }
  public open = function(content) {
    console.log('open Modal');
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  };
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  private hasServiceLevelPurchased = function(serviceLevel) {
    let isPurchased = false;
    this.packageInfo.filter(function(val) {
      if (val.rate_plan[0].serviceLevel === serviceLevel && val.is_purchased) {
        isPurchased = true;
      }
    });
    return isPurchased;
  };

  public hasProducts() {
    return this.product.length != 0
  }

  public addAddOnCB(data, task, i) {
    this.checkStatus = true;
    sessionStorage.setItem('itemsSelected', JSON.stringify(true));
    if (task === 'add-') {
      this.product[i].highLight = 'secure-highlight-border-cb';
      this.order.setAddOnType(data.Name, data.charges[0].price, '');
      this.CBAdded = task + data.Name;
      this.model.setModelData(this.product[i].Id, '', '');
      this.spinner.checkHttpPending(true);
      const serviceLevel = this.hasServiceLevelPurchased(this.product[i].serviceLevel) ? 0 : this.product[i].serviceLevel;
      this.calculate.calculateTotal(serviceLevel,
        this.model.model, this.model.items, this.model.coupons)
        .subscribe(da => {
          this.total = da;
          this.calculate.setTotalValue(da);
          this.model.isShippable(da.cart);
          this.spinner.checkHttpPending(false);
          sessionStorage.setItem('total', JSON.stringify({ total: da }));
        });
    }
    if (task === 'remove-') {
      this.order.setAddOnType(data.Name, 0, 'remove');
      this.CBAdded = task + data.Name;
      this.model.model.filter(function(val, ind, all) {
        if (val === data.Id) {
          all.splice(ind, 1);
        }
      });
      this.product[i].highLight = '';
      this.spinner.checkHttpPending(true);
      const serviceLevel = this.hasServiceLevelPurchased(this.product[i].serviceLevel) ? 0 : this.product[i].serviceLevel;
      this.calculate.calculateTotal(serviceLevel,
        this.model.model, this.model.items, this.model.coupons).
        subscribe(d => {
          this.total = d;
          this.calculate.setTotalValue(d);
          this.model.isShippable(d.cart);
          this.spinner.checkHttpPending(false);
          sessionStorage.setItem('total', JSON.stringify({ total: d }));
        });
    }
  }

  public addAddOnRA(data, task, price, i) {
    this.checkStatus = true;
    sessionStorage.setItem('itemsSelected', JSON.stringify(true));
    if (task === 'add-') {
      this.product[i].highLight = 'secure-highlight-border-ra';
      this.order.setAddOnType(data.Name, price, '');
      this.productionSelection.selectProduct(this.product[i], 'add');
      this.RAAdded = task + data.Name;
      this.model.setModelData(this.product[i].Id, '', '');
      this.spinner.checkHttpPending(true);
      const serviceLevel = this.hasServiceLevelPurchased(this.product[i].serviceLevel) ? 0 : this.product[i].serviceLevel;
      this.calculate.calculateTotal(serviceLevel,
        this.model.model, this.model.items, this.model.coupons)
        .subscribe(da => {
          this.total = da;
          this.calculate.setTotalValue(da);
          this.model.isShippable(da.cart);
          this.spinner.checkHttpPending(false);
          sessionStorage.setItem('total', JSON.stringify({ total: da }));

        });
    }

    if (task === 'remove-') {
      this.order.setAddOnType(data.Name, 0, 'remove');
      this.productionSelection.selectProduct(this.product[i], 'remove');
      this.RAAdded = task + data.Name;
      this.model.model.filter(function(val, ind, all) {
        if (val === data.Id) {
          all.splice(ind, 1);
        }
      });
      this.product[i].highLight = '';
      this.spinner.checkHttpPending(true);
      const serviceLevel = this.hasServiceLevelPurchased(this.product[i].serviceLevel) ? 0 : this.product[i].serviceLevel;
      this.calculate.calculateTotal(serviceLevel,
        this.model.model, this.model.items, this.model.coupons).
        subscribe(d => {
          this.total = d;
          this.calculate.setTotalValue(d);
          this.model.isShippable(d.cart);
          this.spinner.checkHttpPending(false);
          sessionStorage.setItem('total', JSON.stringify({ total: d }));
        });
    }
  }


  public purchaseStatus = function() {
    for (let i = 0; i < this.product.length; i++) {
      const item = this.product[i];
      let elementAddon: HTMLElement;
      if (item.charges[0]['Entitlement__c'] === 'Radiation Exposure Monitoring') {
        if ((item.valid_status === 'renewable') && (item.renewal_selected)) {
          elementAddon = document.getElementById('radiationExposure') as HTMLElement;
          elementAddon.click();
        } else if (this.product.expiration_date != null) {
          this.RAAdded = 'purchased';
        }
      }
      if (item.charges[0]['Entitlement__c'] === 'Criminal Background Check') {
        if ((item.valid_status === 'renewable') && (item.renewal_selected)) {
          elementAddon = document.getElementById('creminalBackground') as HTMLElement;
          elementAddon.click();
        } else if (this.product.expiration_date != null) {
          this.RAAdded = 'purchased';
        }
      }
    }
  };


  public bgColorCheck = function(results) {
    for (const data in results) {
      if (results[data].Name === 'Criminal Background Check') {
        results[data].bgColor = 'crimialBG';
        results[data].checkColor = 'hot-pink';
      } else if (results[data].Name === 'Expedited Credentialing Service') {
        results[data].bgColor = 'radiationBG';
        results[data].checkColor = 'orange';
      }
    }
  };
}
