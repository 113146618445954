import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';



@Injectable()
export class ProductSelectionService {
  public productData: any;
  public productStream = new Subject<any>();

  constructor() { }
  public selectProduct(product, operation = 'init') {
      this.productData = {
        product,
        operation
      };
      this.productStream.next(this.productData);
  }
}
