import { Component, OnInit, ViewChild, SimpleChanges, OnChanges, HostListener, OnDestroy, ElementRef, Input } from '@angular/core';
import { ClinicServicesComponent } from '../clinic-services/clinic-services.component';
import { OrderSummaryService } from '../order-summary.service';
import { ProductSelectionService } from '../product-selection.service';
import { ModelService } from '../model.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { CalculateTotalService } from '../calculate-total.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { UserService } from '../user.service';
import { environment } from '../environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { element } from 'protractor';

export interface TotalData {
  total: any;
}
@Component({
  selector: 'app-renew-customize',
  templateUrl: './renew-customize.component.html',
  styleUrls: ['./renew-customize.component.scss']
})
export class RenewCustomizeComponent implements OnInit, OnChanges, OnDestroy {
  public loadAddons = false;
  public showAddon = false;
  public showAddons = new Subject<boolean>();
  public product: any[] = [];
  public data = {
     total: 0,
     cart: {}
  };
  public modelData = 'modelData';
  public userData: Object;
  public subscribeToProductsRenewal: Subscription;
  public localhost = environment.serverUrl;
  public clinicData = 'clinicData';
  public status: boolean;
  public backgroundClass: string;
  public total: any;
  public localHost = environment.serverUrl;
  public checkout: Object;
  public Height: number;
  public disabled: string;
  public addonIdArray: any[] = [];
  public renewalProduct;
  public isRARenewal = false;
  public isCBRenewal = false;

  public CBAdded: string;
  public RAAdded: string;
  public pageToken: any;
  constructor(private order: OrderSummaryService,
    private session: SessionStorageService,
    private productionSelection: ProductSelectionService,
    private model: ModelService,
    private modalService: NgbModal,
    private route: Router,
    private calculate: CalculateTotalService,
    private spinner: SpinnerService,
    private user: UserService,
  private http: HttpClient,
  private el: ElementRef ) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.Height = event.target.innerHeight;
    const adjustedHeight = this.Height - 100;
    const adjustToString = adjustedHeight.toString();
    const selectHeight = document.getElementById('bottom-wrapper');
    selectHeight.style.top = adjustToString + 'px';
  }
      ngOnInit() {
        this.user.getCheckoutStatus(true);
        sessionStorage.setItem('user', JSON.stringify(this.userData));
        const unParsedData = sessionStorage.getItem('modelData').replace('\\\\n\\\\n', '<br>');
       this.product = JSON.parse(unParsedData);

       this.bgColorCheck(this.product);
        this.Height = (window.innerHeight);
        const adjustedHeight = this.Height - 100;
        const selectHeight = document.getElementById('bottom-wrapper');
        const adjustToString = adjustedHeight.toString();
        selectHeight.style.top = adjustToString + 'px';
        this.checkout = {
          button: 'Checkout',
          statusText: 'something',
          isSubmitable: true,
          formButton: false
        };
        this.showAddon = true;

        
        this.renewalProduct = this.product.find( x => x.renewal_selected === true );
        if ((this.renewalProduct != undefined) && (this.renewalProduct.charges[0].entitlement === 'Radiation Exposure Monitoring')) {
             this.isRARenewal = true;
        }  else if ((this.renewalProduct != undefined)  && (this.renewalProduct.charges[0].entitlement === 'Criminal Background Check')) {
            this.isCBRenewal = true;
        }


        setTimeout(() => {
          this.purchaseStatus();
        }, 0);

        setTimeout(() => {
          if (this.data.total) {
            this.calculate.setTotalValue(this.data.total);
          }
        }, 200);
      }

      ngOnChanges(changes: SimpleChanges) {
        if (changes.checkout.currentValue.isSubmitable) {
          this.disabled = 'btn-primary';
        }
     }

     ngOnDestroy() {
      sessionStorage.setItem('shippable', JSON.stringify(this.model.shippableProduct));
     }
      public open = function(content) {
        console.log('open Modal');
        this.modalService.open(content).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      };
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
      }
      public addAddOnCB(data, task, i) {
        sessionStorage.setItem('itemsSelected', JSON.stringify(true));
          if (task === 'add-') {
            this.product[i].highLight = 'secure-highlight-border-cb';
            this.order.setAddOnType(data.Name, data.charges[0].price, '');
            this.CBAdded = task + data.Name;
            this.model.setModelData(this.product[i].Id, '', '');
            this.spinner.checkHttpPending(true);
            this.calculate.calculateTotal(this.product[i].serviceLevel,
              this.model.model, this.model.items, this.model.coupons)
            .subscribe(da => {
              this.total = da;
              this.calculate.setTotalValue(da);
              this.model.isShippable(da.cart);
              this.spinner.checkHttpPending(false);
              sessionStorage.setItem('total', JSON.stringify({total: da}));
                });
        }
          if (task === 'remove-') {
            this.order.setAddOnType(data.Name, 0, 'remove');
            this.CBAdded = task + data.Name;
            this.model.model.filter(function(val, ind, all) {
               if (val === data.Id) {
                 all.splice(ind, 1);
               }
            });
            this.product[i].highLight = '';
            this.spinner.checkHttpPending(true);
            this.calculate.calculateTotal(this.product[i].serviceLevel,
              this.model.model, this.model.items, this.model.coupons).
            subscribe(d => {
              this.total = d;
              this.calculate.setTotalValue(d);
              this.model.isShippable(d.cart);
              this.spinner.checkHttpPending(false);
              sessionStorage.setItem('total', JSON.stringify({total: d}));
            });
          }
      }

      public addAddOnRA(data, task, price, i) {
        sessionStorage.setItem('itemsSelected', JSON.stringify(true));
        if (task === 'add-') {
          this.product[i].highLight = 'secure-highlight-border-ra';
          this.model.setModelData(this.product[i].Id, '', '');
          this.order.setAddOnType(data.Name, price, '');
          this.productionSelection.selectProduct(this.product[i], 'add');
          this.RAAdded = task + data.Name;
          this.spinner.checkHttpPending(true);
          this.calculate.calculateTotal(this.product[i].serviceLevel,
          this.model.model, this.model.items, this.model.coupons)
            .subscribe(da => {
              this.total = da;
              this.calculate.setTotalValue(da);
              this.model.isShippable(da.cart);
              this.spinner.checkHttpPending(false);
              sessionStorage.setItem('total', JSON.stringify({total: da}));
                });
        }

        if (task === 'remove-') {
          this.order.setAddOnType(data.Name, 0, 'remove');
          this.productionSelection.selectProduct(this.product[i], 'remove');
          this.RAAdded = task + data.Name;
          this.model.model.filter(function(val, ind, all) {
            if (val === data.Id) {
              all.splice(ind, 1);
            }
         });
         this.product[i].highLight = '';
         this.spinner.checkHttpPending(true);
         this.calculate.calculateTotal(this.product[i].serviceLevel,
          this.model.model, this.model.items, this.model.coupons).
         subscribe(d => {
           this.total = d;
           this.calculate.setTotalValue(d);
           this.model.isShippable(d.cart);
           this.spinner.checkHttpPending(false);
           sessionStorage.setItem('total', JSON.stringify({total: d}));
         });
        }
    }


    public purchaseStatus = function() {
      if (this.product) {
      for (let i = 0; i < this.product.length; i++) {
        const item = this.product[i];
        if (item.charges[0]['Entitlement__c'] === 'Radiation Exposure Monitoring') {
           if (((item.valid_status === 'renewable') || (item.valid_status === 'expired')) && (item.renewal_selected) ) {
               const clickElement: HTMLElement = document.getElementById('radiationExposure') as HTMLElement;
               clickElement.click();
           } else if (this.product.is_purchased) {
               this.RAAdded = 'purchased';
           }
        }
        if (item.charges[0]['Entitlement__c'] === 'Criminal Background Check') {
          if (((item.valid_status === 'renewable') || (item.valid_status === 'expired')) && (item.renewal_selected)) { 
         const foundElement: HTMLElement = document.getElementById('creminalBackground') as HTMLElement;
         foundElement.click();
          } else if (this.product.is_purchased) {
             this.RAAdded = 'purchased';
          }
       }
      }
    }
    };

      public bgColorCheck = function(results) {
        for (const data in results) {
          if (results[data].Name === 'Criminal Background Check') {
            results[data].bgColor = 'crimialBG';
            results[data].checkColor = 'hot-pink';
          } else if (results[data].Name === 'Radiation Exposure Package') {
            results[data].bgColor = 'radiationBG';
            results[data].checkColor = 'orange';
          }
     }
      };
    }
