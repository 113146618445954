import { Component, NgZone, ViewEncapsulation, OnInit, ChangeDetectorRef, ElementRef,  ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs/Subscription';
import { PlatformLocation } from '@angular/common';
import * as moment from 'moment';
import { ValidateCreditCardNumber } from '../validators/credit-card-number.validator';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import { SessionStorageService } from 'angular-web-storage';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var JSEncrypt: any;

import { SpinnerService } from '../spinner/spinner.service';
import { CountrySelect } from '../validators/country-select.validator';
import { Z_ASCII } from 'zlib';

interface ZuoraForm {
  'fieldKey': string;
  'fieldStyle': string;
  'id': string;
  'token': string;
  'signature': string;
  'tenantId': string;
  'encryptedValues': string;
  'host': string;
  'creditCardAddress1': string;
  'creditCardAddress2': string;
  'creditCardCity': string;
  'creditCardCountry': string;
  'creditCardHolderName': string;
  'creditCardPostalCode': string;
  'creditCardState': string;
  'creditCardType': string;
}

@Component({
  selector: 'app-creditcard-registration',
  templateUrl: './creditcard-registration.component.html',
  styleUrls: [`./creditcard-registration.component.scss`],
  styles: [`
  .tip-custom-class .tooltip-inner {
     background-color: white;
     color: black;
     font-size: 90%;
   }`],
   encapsulation: ViewEncapsulation.None
})

export class CreditcardRegistrationComponent implements OnInit {
  public checkOutform: FormGroup;
  public addressVerify: FormGroup;
  public subscription: Subscription;
  public localhost = environment.serverUrl;
  public visaSelected = true;
  public mcSelected = true;
  public pageToken: any;
  public sa: Object;
  public aeSelected = true;
  public dSelected = true;
  public months =  ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years: any[] = [];
  public maskedCardNum: any;
  public PayRefId: any;
  public userPlanType: any;
  public creditCardHolderName: string;


  public ccNum: any;
  public localHost = environment.serverUrl;
  public cardType: string;
  public showShippable: boolean;
  public billingAddress = false;
  public isCreditCard = false;
  public panelId = 'billing';
  public nextState = true;
  public thisYear: any;
  public existingCardData = false;
 
  public ba: Object;
  public cc: object;
  public cardNumber: Number;
  public visa = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?)$');
  public mastercard = new RegExp('^(?:5[1-5][0-9]{14})$');
  public americaexpress = new RegExp('^(?:3[47][0-9]{13})$');
  public discovRegEx = new RegExp('6(?:011|5[0-9]{2})[0-9]{12}');
  public zip5: string;
  public zip3: string;
  public zip: any;
  public billingData: Object;

  public zuora = environment.zuoraUrl;
  public formData: ZuoraForm;
  public submitAbled = false;

  public encrypt = new JSEncrypt();
  public buttonStatus: any;
  public cardBase64: any;
  public disabled: string;
  public payRefId: string;
  public disableButton: string;
  public verifiedCount = 0;
  public editPaymentFlag = false;
  public everGreenOptIn = false;
  public updatePaymentFlag = false;
  public paymentAddressReady = false;
  public errorMsg: any;
  public updateFromSec3ure = false;

  @ViewChild('paymentFrame') paymentFrame: ElementRef;

  constructor(private ngZone: NgZone,
    private router: ActivatedRoute,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private route: Router,
    private http: HttpClient,
    private session: SessionStorageService,
    private model: ModelService,
    private spinner: SpinnerService,
    private user: UserService,
    private elementRef: ElementRef,
    private ref: ChangeDetectorRef,
    public platformLocation: PlatformLocation) { 
      router.queryParams.subscribe((params: any) => {
        if(params['token']) {
          this.pageToken = params['token'];
          sessionStorage.setItem('token', this.pageToken);
        }
        if(params['update_only']) {
          this.updateFromSec3ure = true;
          this.existingCardData = true;
        }
      }, err => {
        console.log(err);
      }, () => {
      });
    }


  ngOnInit() {

    window.card = window.card || {};
    window.card.namespace = window.card.namespace || {};
    window.card.namespace.setPayment = this.setPayment.bind(this);

    sessionStorage.setItem('layoutTag', 'Evergreen');
    this.editPaymentFlag = JSON.parse(sessionStorage.getItem('editPaymentFlag'));
    this.updatePaymentFlag = JSON.parse(sessionStorage.getItem('updatePaymentFlag'));


    this.disabled = 'btn-default disable-gray';
    this.disableButton = 'disabled';
    this.thisYear = Number(moment().format('YYYY'));
    this.spinner.checkHttpPending(true);
    this.pageToken = sessionStorage.getItem('token');

    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };

    this.http.post(`${this.localHost}/commerce/products/userDetails_get`,`token=${this.pageToken}`, 
                    { headers: header }).subscribe((result: User) => {
      this.userPlanType = result.user_plan_type;
      this.user.getUserNames({firstName: result.first_name, 
                              lastName: result.last_name, 
                              userId: result.userId});
      this.spinner.checkHttpPending(false);
    });

    const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
    this.payRefId = sessionStorage.getItem('payRefId');

    if (this.route.url.indexOf(`/EvergreenPaymentInfo`) > -1) {
       if (this.payRefId) {
            this.existingCardData = true;
       }
       this.updatePaymentFlag = true;
       this.spinner.checkHttpPending(true);
       const tokenParams = `token=${this.pageToken}`;
       this.http.post(`${this.localHost}/commerce/UpdateUserData/getExistingPaymentMethodId`,
                 tokenParams, { headers: headerPayment }).subscribe((data: any) => {
                 this.payRefId = data;
                 this.pageToken = sessionStorage.getItem('token');
                 const billingParams = `token=${this.pageToken}&payRefId=${this.payRefId}`;
                 this.http.post(`${this.localHost}/commerce/Checkout/getPaymentMethodData_post`,
                                 billingParams, { headers: headerPayment }).subscribe((billingData: any) => {
                                  this.cardType = billingData.result.CreditCardType;
                                  this.maskedCardNum = billingData.result.CreditCardMaskNumber;
                                  if (this.cardType && this.maskedCardNum) {
                                      this.existingCardData = true;
                                  }
                                  this.model.clearData();
                                  this.billingData = {
                                    cardholder: billingData.result.CreditCardHolderName,
                                    country: billingData.result.CreditCardCountry,
                                    state: billingData.result.CreditCardState,
                                    city: billingData.result.CreditCardCity,
                                    addressOne: billingData.result.CreditCardAddress1,
                                    addressTwo: billingData.result.CreditCardAddress2,
                                    zip: billingData.result.CreditCardPostalCode
                                  };

                                  this.model.getPaymentData({billing: this.billingData});
                                  if ((billingData.result.CreditCardAddress1) && (billingData.result.CreditCardCity) && 
                                      (billingData.result.CreditCardState) && (billingData.result.CreditCardCountry)) {
                                      this.paymentAddressReady = true;
                                  }

                                  this.http.post(`${this.localHost}/commerce/products/userDetails_get`,
                                                 `token=${this.pageToken}`, { headers: headerPayment }).subscribe((result: User) => {
                                    this.userPlanType = result.user_plan_type;
                                    sessionStorage.setItem('email', result.email);
                                    sessionStorage.setItem('first_name', result.first_name);
                                    sessionStorage.setItem('last_name', result.last_name);
                                    sessionStorage.setItem('middle_name', result.middle_name);
                                    sessionStorage.setItem('userId', result.userId);
                                    sessionStorage.setItem('applicationId', result.applicationId);
                                    sessionStorage.setItem('address1', result.address1);
                                    sessionStorage.setItem('address2', result.address2);
                                    sessionStorage.setItem('city', result.city);
                                    sessionStorage.setItem('state', result.state);
                                    sessionStorage.setItem('country', result.country);
                                    sessionStorage.setItem('zip', result.zip);
                                    sessionStorage.setItem('inboundUrl', result.inbound_base_url);
                                    sessionStorage.setItem('campaignId', result.campaignId);
                                    sessionStorage.setItem('campaignToken', result.campaignToken);
                                    sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));

                                    if  (!this.paymentAddressReady) {
                                       if ((result.address1) && (result.city) && (result.state) &&
                                           (result.country)) {
                                            const cardHolderName = result.first_name + ' ' + result.last_name;
                                            this.billingData = {
                                              cardholder: cardHolderName,
                                              country: result.country,
                                              state: result.state,
                                              city: result.city,
                                              addressOne: result.address1,
                                              addressTwo: result.address2,
                                              zip: result.zip
                                            };
                                            this.model.getPaymentData({billing: this.billingData});
                                            this.paymentAddressReady = true;
                                       }
                                    }
                                    this.spinner.checkHttpPending(false);
                                    if (!this.paymentAddressReady) {
                                      sessionStorage.setItem('updatePaymentFlag', JSON.stringify(true));
                                      sessionStorage.setItem('updateAddressFlag', JSON.stringify(true));
                                      this.existingCardData = true; 
                                      this.route.navigateByUrl('WorkAddress');
                                    } else {
                                      this.ref.detectChanges();
                                      //sessionStorage.setItem('updatePaymentFlag', JSON.stringify(true));
                                    }
                                  });
                  });
      });
    }
    for (let i = 0; i < 30; i++) {
      this.years.push(this.thisYear + i);
    }
    this.years.unshift('');
    this.checkOutform = new FormGroup({
      creditCard: new FormGroup({
          creditCardHolderName: new FormControl('', Validators.required),
          cardNumber: new FormControl('', [Validators.required, ValidateCreditCardNumber]),
          month: new FormControl('', Validators.required),
          year: new FormControl('', Validators.required),
          cvv: new FormControl('', Validators.required),
          zip: new FormControl('', Validators.required)
      }),
    });

    this.loadHostedPage();
  }

  public checkLength = function(len, ele, control) {
    const fieldLength = ele.toString().length;
    if (fieldLength <= len) {
      return true;
    } else {
      if (control === 'zip') {
        let str = ele.toString();
        str = str.substring(0, str.length - 1);
        this.checkOutform.controls.creditCard.get('zip').setValue(Number(str));
      }
      if (control === 'cvv') {
        let str = ele.toString();
      str = str.substring(0, str.length - 1);
      this.checkOutform.controls.creditCard.get('cvv').setValue(Number(str));
      }
    }
  };

  public checkCount = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.zip) {
        this.checkLength(total, val.zip, control);
      }
    });
  };

  public checkCountCVV = function(total, control) {
    let cvvVal = this.checkOutform.controls.creditCard.controls.cvv.value;
    let newVal = cvvVal;
    if (cvvVal && cvvVal.toString().length > 4) {
      newVal = cvvVal.toString().substring(0,4)
    }
    this.checkOutform.controls.creditCard.get('cvv').setValue(newVal.replace(/\D/g,''));

    this.checkOutform.valueChanges.subscribe(val => {
      if (val.cvv) {
        this.checkLength(total, val.cvv, control);
      }
    });
  };



  public loadHostedPage = function () {

    var data = 'var callback = function(response) {' +
      'if(response.success) {' +
          'card.namespace.setPayment(response.refId);' +
      '} else {' +
         'alert("errorcode="+response.errorCode + ", errorMessage="+response.errorMessage);' +
     ' }' +
     '}; var prepopulateFields = {};';
     var script = this._renderer2.createElement('script');
     script.type = 'text/javascript';
     script.text = data;
     this._renderer2.appendChild(this._document.body, script);

     const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
     this.pageToken = sessionStorage.getItem('token');
     this.spinner.checkHttpPending(true);
     this.http.post(`${this.localhost}/commerce/Checkout/paymentData_get`,
     `token=${this.pageToken}`, { headers: headerPayment }).subscribe((data: any) => {
      const keyArray = data.zuoraData.result.key.split("\r\n");
        data = 'var params = {' +
          'tenantId: ' + data.zuoraData.result.tenantId + ',' +
          'id:"' + data.pageId + '",' +
          'token:"' + data.zuoraData.result.token + '",' +
          'signature:"' + data.zuoraData.result.signature + '",' +
          'style:"inline",' +
          'key: "' + keyArray[1] + '",' +
          'submitEnabled:"true",' +
          'param_supportedTypes:"AmericanExpress,Visa,MasterCard,Discover",' +
          'url:"' + data.uri + '",' +
          'paymentGateway:"New IC PayPal Gateway" ' +
        '};' +
        'Z.setEventHandler("onloadCallback", function() {' +
        'console.info("HPM page is loaded."); ' +
        '});' +
        'Z.render(' +
            'params,' +
            'prepopulateFields,' +
            'callback' +
        ');' ;
        script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.text = data;
        this._renderer2.appendChild(this._document.body, script);
     });
    };
    

public validateCCInfoAndSave = function() {
  let noErrors = true;
  Object.keys(this.checkOutform.controls.creditCard.controls).forEach(key => {
  const controlErrors: ValidationErrors = this.checkOutform.controls.creditCard.get(key).errors;
  if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
            this.checkOutform.controls.creditCard.get(key).markAsTouched();
          }
        });
    }
  });
  if (noErrors) {
    this.verifiedCount++;
    if (this.verifiedCount === 6) {
         this.verifiedCount = 0;
         this.saveSubmitData();
    }
   }
};

public  goAddPaymentInfo = function() {
    if (!this.payRefId) {
      this.payRefId = null;
    }
    sessionStorage.setItem('payRefId', this.payRefId);
    this.route.navigateByUrl('RegistrationConfirm');
    
};

public setPayment = function(payRefId) {
  this.ngZone.run(() => this.setPaymentId(payRefId));
}

public setPaymentId = function(payRefId) {
  this.payRefId = payRefId;
  if (this.existingCardData) {
    this.updatePaymentInfo();
  }
  else {
    this.goAddPaymentInfo();
  }
}

public goBackSec3ure = function() {
  if  (this.editPaymentFlag) {
    this.route.navigateByUrl('RegistrationConfirm');
  } else {
    window.location.href = sessionStorage.getItem('inboundUrl');
  }
};


public getPaymentIdFromUrl(url) {
  let refId = null;
  const respObject = {};
  const urlData = url.substring(1).split('&');
  for (let i = 0; i < urlData.length; i++) {
    const keyValue = urlData[i].split('=');
    respObject[keyValue[0]] = keyValue[1];
  }
  if (respObject['success'] === 'true') {
    refId =  respObject['refId'];
  }
  else {
    this.spinner.checkHttpPending(true);
    this.errorMsg = "Credit card payment failed: " + decodeURI(respObject['errorMessage'])
    this.spinner.checkHttpPending(false);
  }
  
  return refId;
}

public updatePaymentInfo = function() {
  this.spinner.checkHttpPending(true);
   const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
   const paymentInfoParams = `token=${this.pageToken}&payRefId=${this.payRefId}`;
   this.http.post(`${this.localhost}/commerce/UpdateUserData/updateUserDefaultPaymentMethod`,
                  paymentInfoParams, { headers: headerPayment }).subscribe((data: any) => {
                    if(this.updateFromSec3ure) {
                      window.location.href = sessionStorage.getItem('inboundUrl');    
                    } else if  (this.editPaymentFlag) {
                      this.goAddPaymentInfo();
                    } else {
                      this.goBackSec3ure();
                    }  
      });
};


public frameOnload = function() {
        if (this.paymentFrame.nativeElement.contentWindow.location.search === '') {
          this.spinner.checkHttpPending(false);
          return;
        }
        this.payRefId = this.getPaymentIdFromUrl(this.paymentFrame.nativeElement.contentWindow.location.search);
        if(!this.payRefId) {
          this.spinner.checkHttpPending(false);
          return;
        }
        this.spinner.checkHttpPending(true);
        if (this.existingCardData) {
          this.spinner.checkHttpPending(true);
          this.updatePaymentInfo();
        } else {
          this.goAddPaymentInfo();
        }
};

public paymentSubmit = function() {
  this.spinner.checkHttpPending(true);
};

deslectEvergreen(){
  this.everGreenOptIn = false;
  sessionStorage.setItem('shippingAddress', JSON.stringify(this.everGreenOptIn));
}

selectEvergreen(){
  this.everGreenOptIn = true;
  sessionStorage.setItem('everGreenOptIn', JSON.stringify(this.everGreenOptIn));
}

public saveSubmitData() {
      let zipStr = this.checkOutform.controls.creditCard.get('zip').value.toString();
      if(zipStr.length < 5 ) {
        return;
      }
      const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
      this.pageToken = sessionStorage.getItem('token');
      this.spinner.checkHttpPending(true);
      this.http.post(`${this.localhost}/commerce/Checkout/paymentData_get`,
      `token=${this.pageToken}`, { headers: headerPayment }).subscribe((data: any) => {
        const cardInfo = '#' + this.model.submitData.​card.cardNumber + '#' +
        this.model.submitData.​card.cvv + '#' + this.model.submitData.​card.cardMonth + '#' +
        this.model.submitData.​card.cardYear;
        const publicKey = data.zuoraData.result.key;
        this.cardBase64 = window.btoa(cardInfo);
        this.encrypt.setPublicKey(publicKey);
        const encrypted = this.encrypt.encrypt(this.cardBase64);
            this.formData = {
              'fieldKey': data.zuoraData.result.key,
              'fieldStyle': 'iframe',
              'id': data.pageId,
              'token': data.zuoraData.result.token,
              'signature': data.zuoraData.result.signature,
              'tenantId': data.zuoraData.result.tenantId,
              'encryptedValues': encrypted,
              'host': (this.platformLocation as any).location.origin,
        'creditCardAddress1': this.model.submitData.billing.addressOne,
        'creditCardAddress2': this.model.submitData.billing.addressTwo,
        'creditCardCity': this.model.submitData.billing.city,
        'creditCardCountry': this.model.submitData.billing.country,
        'creditCardHolderName': this.creditCardHolderName,
        'creditCardPostalCode': this.model.submitData.billing.zip.toString(),
        'creditCardState': this.model.submitData.billing.state,
        'creditCardType': this.model.submitData.card.cardType
        };

        this.sa = {
          addressOne: this.model.submitData.billing.addressOne,
          addressTwo: this.model.submitData.billing.addressTwo,
          city: this.model.submitData.billing.city,
          state: this.model.submitData.billing.state,
          country: this.model.submitData.billing.country,
          zip: this.model.submitData.billing.zip.toString()
        };
        // sessionStorage.setItem('shippingAddress', JSON.stringify(this.sa));
        this.disabled = 'btn-primary';
        this.disableButton = '';
        this.submitAbled = true;
        this.spinner.checkHttpPending(false);
      });
    }
}


