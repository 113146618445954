import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent {
  @Input() control: string;
  public validation: ValidationErrors;
 constructor() {
 }
 public checkOutControl = (formControl: AbstractControl) => {
   formControl.get(this.control);
}
}
