import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MembershipComponent } from './membership/membership.component';
import { CustomizeComponent } from './customize/customize.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { RenewalComponent } from './renewal/renewal.component';
import { ProductInterceptComponent } from './product-intercept/product-intercept.component';
import { RenewalRedirectComponent } from './renewal-redirect/renewal-redirect.component';
import { EvergreenMembershipComponent} from './evergreen-membership/evergreen-membership.component';
import { CancelMembershipComponent } from './cancel-membership/cancel-membership.component';
import { CancelMembershipConfirmationComponent } from './cancel-membership-confirmation/cancel-membership-confirmation.component';
import { ActivateRadiationMembershipComponent } from './activate-radiation-membership/activate-radiation-membership.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { WorkAddressComponent} from './work-address/work-address.component';
import { CreditcardRegistrationComponent} from './creditcard-registration/creditcard-registration.component';
import { RegistrationConfirmComponent} from './registration-confirm/registration-confirm.component';
import { CreditCardRadiationComponent } from './credit-card-radiation/credit-card-radiation.component';
import { ActivateRadiationConfirmationComponent } from './activate-radiation-confirmation/activate-radiation-confirmation.component';
import { ManageAutopayComponent } from './manage-autopay/manage-autopay.component';
import { ManageAutopayConfirmComponent } from './manage-autopay-confirm/manage-autopay-confirm.component';
import { TechBundleMembershipComponent } from './tech-bundle-membership/tech-bundle-membership.component';
import { TechBundleMembershipConfirmComponent } from './tech-bundle-membership-confirm/tech-bundle-membership-confirm.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'Membership',
        pathMatch: 'full'
    },
    {
        path: 'ActivateRadiationMembership',
        component: ActivateRadiationMembershipComponent
    },
    {
        path: 'ShippingAddress',
        component: ShippingAddressComponent
    },
    {
        path: 'CancelMembership',
        component: CancelMembershipComponent
    },
    {
        path: 'CancelMembershipConfirmation',
        component: CancelMembershipConfirmationComponent
    },
    {
        path: 'EvergreenMembership',
        component: EvergreenMembershipComponent
    },
    {
        path: 'EvergreenUpgrade',
        component: UpgradeComponent
    },
    {
        path: 'EvergreenRenewal',
        component: RenewalComponent
    },
    {
        path: 'RegistrationConfirm',
        component: RegistrationConfirmComponent
    },
    {
        path: 'Membership',
        component: MembershipComponent
    },
    {
        path: 'Customize',
        component: CustomizeComponent,
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'Checkout',
        component: CheckoutComponent
    },
    {
        path: 'Intercept',
        component: ProductInterceptComponent
    },
    {
        path: 'Confirmation',
        component: ConfirmComponent,
        runGuardsAndResolvers: 'always',
        pathMatch: 'full'
   },
   {
       path: 'Upgrade',
        component: UpgradeComponent,
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'Renewal',
        component: RenewalComponent,
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'RenewalRedirect',
        component: RenewalRedirectComponent,
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'WorkAddress',
        component: WorkAddressComponent,
    },
    {
        path: 'EvergreenPaymentInfo',
        component: CreditcardRegistrationComponent
    },
    {
        path: 'CreditcardRegistration',
        component: CreditcardRegistrationComponent
    },
    {
        path: 'RadiationPayment',
        component: CreditCardRadiationComponent
    },
    {
        path: 'RadiationConfirmation',
        component: ActivateRadiationConfirmationComponent
    },
    {
        path: 'Autopay',
        component: ManageAutopayComponent
    },
    {
        path: 'AutopayConfirm',
        component: ManageAutopayConfirmComponent
    },
    {
        path: 'TechBundleMembership',
        component: TechBundleMembershipComponent
    },
    {
        path: 'TechBundleMembershipConfirm',
        component: TechBundleMembershipConfirmComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: []
})

export class AppRoutingModule {}
