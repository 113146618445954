import { Component, OnInit } from '@angular/core';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-ecommerce-panel-medium',
  templateUrl: './ecommerce-panel-medium.component.html',
  styleUrls: ['./ecommerce-panel-medium.component.scss']
})
export class EcommercePanelMediumComponent {
}

