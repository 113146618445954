import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, ValidationErrors } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { OrderSummaryService } from '../order-summary.service';
import { CalculateTotalService } from '../calculate-total.service';
import { ModelService } from '../model.service';
import { SpinnerService } from '../spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
  public showCouponFields = false;
  public pageToken;
  public productServices: Object;
  public couponform: FormGroup;
  public priorityPurchase = true;
  public receipt_cc_email;
  @Input() zipCode: any;
  @Output() addCoupons = new EventEmitter();

  constructor(private order: OrderSummaryService,
    public session: SessionStorageService,
    private model: ModelService,
    public spinner: SpinnerService,
    private calculate: CalculateTotalService,
    private route: Router) {
    this.showCouponFields = JSON.parse(sessionStorage.getItem('coupon_enabled'));
    this.productServices = JSON.parse(sessionStorage.getItem('modelData'));
    sessionStorage.setItem('priorityPurchase', JSON.stringify(this.priorityPurchase));
  }

  ngOnInit() {
    this.couponform = new FormGroup({
      couponCode: new FormControl('', Validators.required),
    });
  }

selectPurchasePriority() {
    this.priorityPurchase = true;
    sessionStorage.setItem('priorityPurchase', JSON.stringify(this.priorityPurchase));
  }

 deselectPurchasePriority() {
    this.priorityPurchase = false;
   sessionStorage.setItem('priorityPurchase', JSON.stringify(this.priorityPurchase));
 }

  public addCoupon = function() {
    
    const couponCode = this.couponform.controls.couponCode.value;
    this.couponform.controls.couponCode.setValue('');
    this.applyCoupon(couponCode);
  };

  public applyCoupon = function (couponCode: any) {
    this.spinner.checkHttpPending(true);
    let curr_total: Number;
    this.model.setCouponCode(couponCode);

    let serviceLevel = 0;
    
    let currTotalStr = sessionStorage.getItem('total');
    if(currTotalStr) {
      let currTotal = JSON.parse(currTotalStr);
      let cartItems = currTotal.total.cart.cart_items;
      for (let i = 0; i < cartItems.length; i++) {
        if(cartItems[i].type != 'addon') {
          serviceLevel = parseInt(sessionStorage.getItem('serviceLevel'));
        }
      }
    }
    else {
      serviceLevel = parseInt(sessionStorage.getItem('serviceLevel'));
    }    

    this.calculate.calculateTotal(serviceLevel,
      this.model.model, this.model.items, this.model.coupons)
      .subscribe(data => {
        if(data.total == null) {
          data.total = 0;
        }
      this.order.setCouponItem(data.cart.cart_items);
      this.calculate.setTotalValue(data);
      this.model.isShippable(data.cart);
      this.spinner.checkHttpPending(false);
      curr_total = JSON.parse(sessionStorage.getItem('total'));
      sessionStorage.setItem('total', JSON.stringify({total: data}));
      this.addCoupons.emit();
   });
  };
}
