import { Component, NgZone, OnInit, Input, OnChanges, EventEmitter, SimpleChanges, ChangeDetectorRef, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs/Subscription';
import { PlatformLocation } from '@angular/common';
import * as moment from 'moment';
import { ValidateCreditCardNumber } from '../validators/credit-card-number.validator';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import { SessionStorageService } from 'angular-web-storage';
declare var JSEncrypt: any;

import { SpinnerService } from '../spinner/spinner.service';
import { CountrySelect } from '../validators/country-select.validator';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

interface ZuoraForm {
  'fieldKey': string;
  'fieldStyle': string;
  'id': string;
  'token': string;
  'signature': string;
  'tenantId': string;
  'encryptedValues': string;
  'host': string;
  'creditCardAddress1': string;
  'creditCardAddress2': string;
  'creditCardCity': string;
  'creditCardCountry': string;
  'creditCardHolderName': string;
  'creditCardPostalCode': string;
  'creditCardState': string;
  'creditCardType': string;
}

@Component({
  selector: 'app-credit-card-radiation',
  templateUrl: './credit-card-radiation.component.html',
  styleUrls: ['./credit-card-radiation.component.scss']
})

export class CreditCardRadiationComponent implements OnInit {
  public checkOutform: FormGroup;
  public addressVerify: FormGroup;
  public subscription: Subscription;
  public localhost = environment.serverUrl;
  public visaSelected = true;
  public mcSelected = true;
  public pageToken: any;
  public sa: Object;
  public aeSelected = true;
  public dSelected = true;
  public months =  ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years: any[] = [];
  public maskedCardNum: any;
  public PayRefId: any;
  public userPlanType: any;
  public creditCardHolderName: string;


  public ccNum: any;
  public localHost = environment.serverUrl;
  public cardType: string;
  public showShippable: boolean;
  public billingAddress = false;
  public isCreditCard = false;
  public panelId = 'billing';
  public nextState = true;
  public thisYear: any;
  public updatePaymentFlag = false;
 
  public ba: Object;
  public cc: object;
  public cardNumber: Number;
  public visa = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?)$');
  public mastercard = new RegExp('^(?:5[1-5][0-9]{14})$');
  public americaexpress = new RegExp('^(?:3[47][0-9]{13})$');
  public discovRegEx = new RegExp('6(?:011|5[0-9]{2})[0-9]{12}');
  public zip5: string;
  public zip3: string;
  public zip: any;

  public zuora = '';
  public formData: ZuoraForm;
  public submitAbled = false;

  public encrypt = new JSEncrypt();
  public buttonStatus: any;
  public cardBase64: any;
  public disabled: string;
  public payRefId: string;
  public disableButton: string;
  public verifiedCount = 0;
  public errorMsg: any;
  public editPaymentFlag = false;
  public titleFlag: any;

  @ViewChild('paymentFrame') paymentFrame: ElementRef;


  constructor(private router: ActivatedRoute,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private ngZone: NgZone,
    private route: Router,
    private http: HttpClient,
    private session: SessionStorageService,
    private model: ModelService,
    private spinner: SpinnerService,
    private user: UserService,
    private elementRef: ElementRef,
    private ref: ChangeDetectorRef,
    public platformLocation: PlatformLocation) { 
      router.queryParams.subscribe(token => {
        this.pageToken = token['token'];
      }, err => {
        console.log(err);
      }, () => {
      });
    }


  ngOnInit() {

    window.card = window.card || {};
    window.card.namespace = window.card.namespace || {};
    window.card.namespace.setRadiationPayment = this.setRadiationPayment.bind(this);

    sessionStorage.setItem('layoutTag', 'Evergreen');
    this.editPaymentFlag = JSON.parse(sessionStorage.getItem('editPaymentFlag'));
    this.updatePaymentFlag = JSON.parse(sessionStorage.getItem('updatePaymentFlag'));
    this.titleFlag = JSON.parse(sessionStorage.getItem('titleFlag'));
    this.disabled = 'btn-default disable-gray';
    this.disableButton = 'disabled';
    this.thisYear = Number(moment().format('YYYY'));

    this.spinner.checkHttpPending(true);
    const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
    this.payRefId = sessionStorage.getItem('payRefId');

    if (this.route.url.indexOf(`/EvergreenPaymentInfo?token=${this.pageToken}`) > -1) {
       if (this.PayRefId) {
            this.updatePaymentFlag = false;
            this.ref.detectChanges();
       }
    }
    this.pageToken = sessionStorage.getItem('token');
    const params = `token=${this.pageToken}&payRefId=${this.payRefId}`;
    this.http.post(`${this.localHost}/commerce/Checkout/getPaymentMethodData_post`,
                     params, { headers: headerPayment }).subscribe((data: any) => {
           this.cardType = data.result.CreditCardType;
           this.maskedCardNum = data.result.CreditCardMaskNumber;
           this.spinner.checkHttpPending(false);
           if (this.cardType && this.maskedCardNum) {
             this.updatePaymentFlag = false;
           }
    });

    this.http.post(`${this.localHost}/commerce/products/userDetails_get`, `token=${this.pageToken}`, { headers: headerPayment })
    .subscribe((result: User) => {
      this.userPlanType = result.user_plan_type;
      sessionStorage.setItem('email', result.email);
      sessionStorage.setItem('first_name', result.first_name);
      sessionStorage.setItem('last_name', result.last_name);
      sessionStorage.setItem('middle_name', result.middle_name);
      sessionStorage.setItem('userId', result.userId);
      sessionStorage.setItem('applicationId', result.applicationId);

      sessionStorage.setItem('address1', result.address1);
      sessionStorage.setItem('address2', result.address2);
      sessionStorage.setItem('city', result.city);
      sessionStorage.setItem('state', result.state);
      sessionStorage.setItem('country', result.country);
      sessionStorage.setItem('zip', result.zip);

      sessionStorage.setItem('inboundUrl', result.inbound_base_url);
      sessionStorage.setItem('campaignId', result.campaignId);
      sessionStorage.setItem('campaignToken', result.campaignToken);

      sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));

      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    });



    for (let i = 0; i < 30; i++) {
      this.years.push(this.thisYear + i);
    }
    this.years.unshift('');
    this.checkOutform = new FormGroup({
      creditCard: new FormGroup({
          creditCardHolderName: new FormControl('', Validators.required),
          cardNumber: new FormControl('', [Validators.required, ValidateCreditCardNumber]),
          month: new FormControl('', Validators.required),
          year: new FormControl('', Validators.required),
          cvv: new FormControl('', Validators.required),
          zip: new FormControl('', Validators.required)
      }),
    });
    this.loadHostedPage();
  }


  public loadHostedPage = function () {

    var data = 'var callback = function(response) {' +
      'if(response.success) {' +
          'card.namespace.setRadiationPayment(response.refId);' +
      '} else {' +
         'alert("errorcode="+response.errorCode + ", errorMessage="+response.errorMessage);' +
     ' }' +
     '}; var prepopulateFields = {};';
     var script = this._renderer2.createElement('script');
     script.type = 'text/javascript';
     script.text = data;
     this._renderer2.appendChild(this._document.body, script);

     const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
     this.pageToken = sessionStorage.getItem('token');
     this.spinner.checkHttpPending(true);
     this.http.post(`${this.localhost}/commerce/Checkout/paymentData_get`,
     `token=${this.pageToken}`, { headers: headerPayment }).subscribe((data: any) => {
      const keyArray = data.zuoraData.result.key.split("\r\n");
        data = 'var params = {' +
          'tenantId: ' + data.zuoraData.result.tenantId + ',' +
          'id:"' + data.pageId + '",' +
          'token:"' + data.zuoraData.result.token + '",' +
          'signature:"' + data.zuoraData.result.signature + '",' +
          'style:"inline",' +
          'key: "' + keyArray[1] + '",' +
          'submitEnabled:"true",' +
          'param_supportedTypes:"AmericanExpress,Visa,MasterCard,Discover",' +
          'url:"' + data.uri + '",' +
          'paymentGateway:"New IC PayPal Gateway" ' +
        '};' +
        'Z.setEventHandler("onloadCallback", function() {' +
        'console.info("HPM page is loaded."); ' +
        '});' +
        'Z.render(' +
            'params,' +
            'prepopulateFields,' +
            'callback' +
        ');' ;
        script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.text = data;
        this._renderer2.appendChild(this._document.body, script);
     });
    };
    

  public checkLength = function(len, ele, control) {
    const fieldLength = ele.toString().length;
    if (fieldLength <= len) {
      return true;
    } else {
      if (control === 'zip') {
        let str = ele.toString();
        str = str.substring(0, str.length - 1);
        this.checkOutform.controls.creditCard.get('zip').setValue(Number(str));
      }
      if (control === 'cvv') {
        let str = ele.toString();
      str = str.substring(0, str.length - 1);
      this.checkOutform.controls.creditCard.get('cvv').setValue(Number(str));
      }
    }
  };

  public checkCount = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.zip) {
        this.checkLength(total, val.zip, control);
      }
    });
  };

  public checkCountCVV = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.cvv) {
        this.checkLength(total, val.cvv, control);
      }
    });
  };

public validateCCInfoAndSave = function() {
  let noErrors = true;
  Object.keys(this.checkOutform.controls.creditCard.controls).forEach(key => {
  const controlErrors: ValidationErrors = this.checkOutform.controls.creditCard.get(key).errors;
  if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
            this.checkOutform.controls.creditCard.get(key).markAsTouched();
          }
        });
    }
  });
  if (noErrors) { 
    this.verifiedCount++;
    if (this.verifiedCount === 6) {
         this.verifiedCount = 0;
         this.saveSubmitData();
    }
  }
};

public checkForError = function() {
   this.saveCcData();
};

public saveCcData = function() {
    this.checkOutform.valueChanges.subscribe(val => {
      const ccYear = val.creditCard.year.toString();
      const cvvString = val.creditCard.cvv.toString();
      const zipString = val.creditCard.zip.toString();

      this.cardNumber = this.checkOutform.controls.creditCard.controls.cardNumber.value;
      if (this.visa.test(this.cardNumber)) {
        this.visaSelected = false;
        this.dSelected = true;
        this.mcSelected = true;
        this.aeSelected = true;
        this.cardType = 'Visa';
  
      } else if (this.mastercard.test(this.cardNumber)) {
        this.visaSelected = true;
        this.dSelected = true;
        this.mcSelected = false;
        this.aeSelected = true;
        this.cardType = 'MasterCard';
      } else if (this.americaexpress.test(this.cardNumber)) {
        this.visaSelected = true;
        this.dSelected = true;
        this.mcSelected = true;
        this.aeSelected = false;
        this.cardType = 'AmericanExpress';
      } else if (this.discovRegEx.test(this.cardNumber)) {
        this.visaSelected = true;
        this.dSelected = false;
        this.mcSelected = true;
        this.aeSelected = true;
        this.cardType = 'Discover';
      } else if (this.cardNumber === '' || this.cardNumber === null) {
          this.visaSelected = true;
          this.dSelected = true;
          this.mcSelected = true;
          this.aeSelected = true;
      }

      if ((((cvvString.length === 3) && ((this.cardType === 'MasterCard') ||
         (this.cardType === 'Visa') || (this.cardType === 'Discover')))   ||
         ((cvvString.length === 4) && (this.cardType === 'AmericanExpress'))) &&
           val.creditCard.cardNumber && (val.creditCard.month !== '') &&
         (ccYear.length > 0)  && (zipString.length > 0 ) && (val.creditCard.creditCardHolderName.length > 2)) {
          this.creditCardHolderName = val.creditCard.creditCardHolderName;
            this.disabled = 'secure-enabled-btn btn-primary ';
            this.disableButton = '';
            this.submittable = true;
            this.cc = {
              cvv: val.creditCard.cvv,
              cardNumber: val.creditCard.cardNumber,
              cardMonth: val.creditCard.month,
              cardYear: val.creditCard.year,
              cardType: this.cardType
            };
            const billingAddress = JSON.parse(sessionStorage.getItem('billingAddress'));
            this.model.getPaymentData({card: this.cc, billing: billingAddress});
            this.validateCCInfoAndSave();
            this.disabled = 'btn-default active-btn';
      } else {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
      }
    });
  };

  public  goAddPaymentInfo = function() {
    if (!this.payRefId) {
      this.payRefId = null;
    }
    sessionStorage.setItem('payRefId', this.payRefId);
    if (this.titleFlag === 'TechBundle') {
      this.route.navigateByUrl('TechBundleMembershipConfirm');
    } else {
       this.route.navigateByUrl('RadiationConfirmation');
    }
  }

  public setRadiationPayment = function(payRefId) {
    this.ngZone.run(() => this.setPaymentId(payRefId));
  }
  
  public setPaymentId = function(payRefId) {
    
    if (payRefId) {
      this.payRefId = payRefId;
      this.goAddPaymentInfo();
    }
  }

  public goBackSec3ure = function() {
   if  (this.editPaymentFlag) {
      this.route.navigateByUrl('RadiationConfirmation');
    } else {
       window.location.href = sessionStorage.getItem('inboundUrl');
    }
};

public onBlur = function() {
   this.saveCcData();
};

public getPaymentIdFromUrl(url) {
  let refId = null;
  const respObject = {};
  const urlData = url.substring(1).split('&');
  for (let i = 0; i < urlData.length; i++) {
    const keyValue= urlData[i].split('=');
    respObject[keyValue[0]] = keyValue[1];
  }
  if (respObject['success'] === 'true') {
    refId =  respObject['refId'];
  }  else {
    this.spinner.checkHttpPending(true);
    this.errorMsg = "Credit card payment failed: " + decodeURI(respObject['errorMessage'])
    this.spinner.checkHttpPending(false);
  }
  return refId;
};


public frameOnload = function() {
        if (this.paymentFrame.nativeElement.contentWindow.location.search === '') {
          this.spinner.checkHttpPending(false);
          return;
        }
        this.payRefId = this.getPaymentIdFromUrl(this.paymentFrame.nativeElement.contentWindow.location.search);
        if(!this.payRefId) {
          this.spinner.checkHttpPending(false);
          return;
        }
        this.goAddPaymentInfo();
      };

public paymentSubmit = function() {
  this.spinner.checkHttpPending(true);
};

public saveSubmitData() {

    let zipStr = this.checkOutform.controls.creditCard.get('zip').value.toString();
    if(zipStr.length < 5 ) {
      return;
    }
      const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
      const cardHolderName = this.creditCardHolderName;
      this.zuora = environment.zuoraUrl;
      this.http.post(`${this.localhost}/commerce/Checkout/paymentData_get`,
      `token=${this.pageToken}`, { headers: headerPayment }).subscribe((data: any) => {
        const cardInfo = '#' + this.model.submitData.​card.cardNumber + '#' +
        this.model.submitData.​card.cvv + '#' + this.model.submitData.​card.cardMonth + '#' +
        this.model.submitData.​card.cardYear;
        const publicKey = data.zuoraData.result.key;
        this.cardBase64 = window.btoa(cardInfo);
        this.encrypt.setPublicKey(publicKey);
        const encrypted = this.encrypt.encrypt(this.cardBase64);
            this.formData = {
              'fieldKey': data.zuoraData.result.key,
              'fieldStyle': 'iframe',
              'id': data.pageId,
              'token': data.zuoraData.result.token,
              'signature': data.zuoraData.result.signature,
              'tenantId': data.zuoraData.result.tenantId,
              'encryptedValues': encrypted,
              'host': (this.platformLocation as any).location.origin,
        'creditCardAddress1': this.model.submitData.billing.address,
        'creditCardAddress2': this.model.submitData.billing.suite,
        'creditCardCity': this.model.submitData.billing.city,
        'creditCardCountry': this.model.submitData.billing.country,
        'creditCardHolderName': cardHolderName,
        'creditCardPostalCode': this.model.submitData.billing.zip.toString(),
        'creditCardState': this.model.submitData.billing.state,
        'creditCardType': this.model.submitData.card.cardType
        };
      });

      this.disabled = 'btn-primary';
      this.disableButton = '';
      this.submitAbled = true;
    }
}


