import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-manage-autopay-confirm',
  templateUrl: './manage-autopay-confirm.component.html',
  styleUrls: ['./manage-autopay-confirm.component.scss']
})
export class ManageAutopayConfirmComponent implements OnInit {
  public userNames;
  public pageToken;
  public localHost = environment.serverUrl;
  public productName: any;
  public myGroup: FormGroup;
  public header = { 'Content-Type': 'application/x-www-form-urlencoded' };
  public expirationDate: any;
  public productPrice: any;
  public disabled: string;
  public disableButton: string;
  public errorMsg;

  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private spinner: SpinnerService,
    private userInfo: UserService) { 
      this.myGroup = new FormGroup({
        isAutoRenewal: new FormControl()
     });
     this.spinner.checkHttpPending(false);
     router.queryParams.subscribe(token => {
      this.pageToken = token['token'];
      sessionStorage.setItem('token', this.pageToken);
    });
     
      this.spinner.checkHttpPending(true);
      this.productName = sessionStorage.getItem('productName');
      this.expirationDate = sessionStorage.getItem('expiration_date');
      this.productPrice = sessionStorage.getItem('price');
      this.setProductName();
      this.setCurrentSubscription();
      this.disabled = 'btn-default disable-gray';
      this.disableButton = 'disabled';
    }

  ngOnInit() {
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.localHost}/commerce/product_renewal/userData_get`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: User) => {
          sessionStorage.setItem('inboundUrl', result.inbound_base_url);
          this.userNames = { firstName: result.first_name, lastName: result.last_name, email: result.email, userId: result.userId };
          sessionStorage.setItem('user', JSON.stringify({
            firstName: this.userNames.firstName,
            lastName: this.userNames.lastName,
             email: this.userNames.email
            }));
          this.userInfo.getUserNames(this.userNames);
        });

  }

  termsCheck() {
    const newSelection = this.myGroup.controls.isAutoRenewal.value;
    if(newSelection) {
      this.disabled = 'btn-primary';
      this.disableButton = '';
    }
    else {
      this.disabled = 'btn-default disable-gray';
      this.disableButton = 'disabled';
    }
  }
  
  setProductName() {
    this.http.post(`${this.localHost}/commerce/products/getRatePlanName`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: any) => {
          this.productName  = result.productName.replace('SEC3URE', 'SEC<sup>3</sup>URE');
          this.productPrice= result.sumPrice
        });
  }

  setCurrentSubscription() {
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.localHost}/commerce/products/getCurrentSubscription`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: any) => {
          sessionStorage.setItem('hasAutoRenew', result.autoRenew);
          this.expirationDate = result.endOfTermDate
          sessionStorage.setItem('sub_id', result.zId);
          this.spinner.checkHttpPending(false);
        });
  }
  confirm() {    
    this.route.navigateByUrl('/AutopayConfirm');
    const status = false;
    this.spinner.checkHttpPending(true);
    const sub_id = sessionStorage.getItem('sub_id');
    this.http.post(`${this.localHost}/commerce/Checkout/optinAutoRenew`, `token=${this.pageToken}&subscription_id=${sub_id}&auto_renew_status=${status}`, { headers: this.header })
        .subscribe((result: any) => {
          this.spinner.checkHttpPending(false);
          this.goBackSec3ure();
        });

  }

  goBackSec3ure() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }
}
