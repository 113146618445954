import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { SpinnerService } from '../spinner/spinner.service';
import { User } from '../interfaces/user';
import { UserService } from '../user.service';
import { environment } from '../environments/environment';
import { NgbModal, NgbAccordionConfig, NgbPanelChangeEvent,
  ModalDismissReasons, NgbModalRef, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-cancel-membership',
  templateUrl: './cancel-membership-confirmation.component.html',
  styleUrls: ['./cancel-membership-confirmation.component.scss']
})
export class CancelMembershipConfirmationComponent implements OnInit {
  public pageToken: any;
  private localHost = environment.serverUrl;
  private header = { 'Content-Type': 'application/x-www-form-urlencoded' };
  public packageInfo: any;
  public passportId: any;
  public productPrice: any;
  public productName: any;
  public firstName: String;
  public lastName: String;
  private ref: ChangeDetectorRef;
  public expirationDate: String;

  @ViewChild('content') private content;

  constructor(private router: ActivatedRoute,
    private modalService: NgbModal,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private spinner: SpinnerService,
    private userInfo: UserService) { 
    this.pageToken = sessionStorage.getItem('token');
    this.expirationDate = sessionStorage.getItem('expiration_date');
    this.productPrice = sessionStorage.getItem('package_price');
  }

  ngOnInit() {
    if(this.pageToken) {
      this.setUserDetails();
    }
  }

  setUserDetails() {
    this.http.post(`${this.localHost}/commerce/products/userDetails_get`, `token=${this.pageToken}`, { headers: this.header })
            .subscribe((result: User) => {
              //this.userPlanType = result.user_plan_type;
              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              this.firstName = result.first_name;
              this.lastName = result.last_name;
              this.spinner.checkHttpPending(false);
            });
  }

  goBackToParentPortal() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }

  showBenefits() {
    this.modalService.open(this.content);
  }

}