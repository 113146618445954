import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';


@Injectable()
export class TermsAcceptService {
  public statusData: any;
  public termsStream = new Subject<any>();

  constructor() { }

  public acceptTerms(termsAccepted) {
    this.statusData = {
       termsAccepted
    };
    this.termsStream.next(this.statusData);
  }
}
