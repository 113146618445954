import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class SpinnerService {
public isPending: boolean;
public  pendingSubject = new Subject<any>();
  constructor() { }

  public checkHttpPending(isPending) {
      this.pendingSubject.next(isPending);
  }

  public pending() {
    return this.isPending;
  }
}
