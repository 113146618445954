
import { Component, OnInit, Input} from '@angular/core';
import { ProductSelectionService } from '../product-selection.service';
import { TermsAcceptService } from '../terms-accept.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { environment } from '../environments/environment';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  public showRdpTerms = false;
  public showMinuteClinicTerms = false;
  public showClearStarTerms = false;
  public rdpTermsCheckBox: string;
  public clearStarTermsCheckBox: string;
  public minuteClinicTermsCheckBox: string;
  public subscription: Subscription;
  public productType: any;
  public entitlement: any;
  public clearStarCount = 0 ;
  public minuteClinicCount = 0;
  private modalRef: NgbModalRef;
  private agreedCount = 0;
  public message: boolean;
  public checkBoxCount = 0;
  public rdpAgreeFlag = 0;
  public minuteClinicAgreeFlag = 0;
  public clearStarAgreeFlag = 0;
  public checkoutButtonEnable = 0;

  public localHost = environment.serverUrl;
  public pageToken: any;
  public rdpTerms: any;
  public minuteClinicTerms: any;
  public clearStarTerms: any;
  public displayText: any;
  public displayHeader: any;

  constructor(private session: SessionStorageService,
    private product: ProductSelectionService,
    private termsAccept: TermsAcceptService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private route: Router,
    private spinner: SpinnerService) {
    /*
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.pageToken = this.session.get('token');
    this.spinner.checkHttpPending(true);
    if (this.pageToken) {
      const termsContents = this.http.post(`${this.localHost}/commerce/Products/terms_get`,
        `token=${this.pageToken}`, { headers: header });
      this.spinner.checkHttpPending(false);
      termsContents.subscribe((res: Response) => {
        this.rdpTerms = res['rdp_terms_and_conditions'];
        this.clearStarTerms = res['cs_terms_and_conditions'];
        this.minuteClinicTerms = res['mc_terms_and_conditions'];
      });
    }
    */
  }

  ngOnInit() {
    /*
    this.rdpTermsCheckBox = 'remove-rdp';
    this.clearStarTermsCheckBox = 'remove-clearStar';
    this.minuteClinicTermsCheckBox = 'remove-minuteClinic';
    this.subscription = this.product.productStream.subscribe((productData) => {
      if ((productData.operation === 'add') || (productData.operation === 'remove')) {
             this.productType = productData.product.charges[0].ProductType__c;
             this.entitlement = productData.product.charges[0].Entitlement__c;
      }
      this.checkBoxCount = 0;
      if (productData.operation === 'add') {
        if (this.productType === 'ClearStar') {
          this.showClearStarTerms = true;
          this.clearStarCount++;
        } else if (this.productType === 'MinuteClinic') {
          this.showMinuteClinicTerms = true;
          this.minuteClinicCount++;
        } else if  (this.entitlement === 'Radiation Exposure Monitoring') {
           this.showRdpTerms = true;
        }
      } else {
        if (this.productType === 'ClearStar') {
          if (this.clearStarCount > 0) {
            this.clearStarCount--;
          }
          if (this.clearStarCount === 0) {
              this.showClearStarTerms = false;
          }
        } else if (this.productType === 'MinuteClinic') {
          if (this.minuteClinicCount > 0) {
            this.minuteClinicCount--;
          }
          if (this.minuteClinicCount === 0) {
            this.showMinuteClinicTerms = false;
          }
        } else if  (this.entitlement === 'Radiation Exposure Monitoring') {
           this.showRdpTerms = false;
        }
      }
      if (this.showRdpTerms && this.showClearStarTerms && this.showMinuteClinicTerms) {
        this.checkBoxCount = 3;
      } else if (this.showRdpTerms && this.showClearStarTerms || this.showRdpTerms && this.showMinuteClinicTerms ||
                 this.showClearStarTerms && this.showMinuteClinicTerms) {
                  this.checkBoxCount = 2;
      } else if (this.showRdpTerms || this.showClearStarTerms || this.showMinuteClinicTerms) {
        this.checkBoxCount = 1;
      }
      this.isTermsChecked();
    });
    */
  }


  public agreeTerms(addOnName, task) {
    /*
    if (addOnName === 'rdp') {
      this.rdpTermsCheckBox = task + addOnName;
      if (task === 'add-') {
        this.rdpAgreeFlag = 1;
      } else if (task === 'remove-') {
        this.rdpAgreeFlag = 0;
      }
    } else if (addOnName === 'clearStar') {
      this.clearStarTermsCheckBox =  task + addOnName;
      if (task === 'add-') {
        this.clearStarAgreeFlag = 1;
      } else if (task === 'remove-') {
        this.clearStarAgreeFlag = 0;
      }
    } else if (addOnName === 'minuteClinic') {
      this.minuteClinicTermsCheckBox = task + addOnName;
      if (task === 'add-') {
        this.minuteClinicAgreeFlag = 1;
      } else if (task === 'remove-') {
        this.minuteClinicAgreeFlag = 0;
      }
    }

    this.isTermsChecked();
    */
  }

  public isTermsChecked() {
    /*
    this.checkoutButtonEnable = 0;
      if (this.showClearStarTerms) {
        if (this.clearStarAgreeFlag === 1)  {
          this.checkoutButtonEnable++;
        }
      }
      if (this.showRdpTerms) {
        if (this.rdpAgreeFlag === 1)  {
          this.checkoutButtonEnable++;
        }
      }

      if (this.showMinuteClinicTerms) {
        if (this.minuteClinicAgreeFlag === 1)  {
          this.checkoutButtonEnable++;
        }
      }

      if (this.checkoutButtonEnable === this.checkBoxCount) {
         this.message = true;
      } else {
          this.message = false;
      }
      this.termsAccept.acceptTerms(this.message);
      */
  }


  public showTermsContent(content, addOnCategory) {
    /*
    this.modalRef =  this.modalService.open(content, { size: 'lg', keyboard: false, backdrop: 'static' });
    if (addOnCategory === 'clearStar') {
      this.displayHeader = `<strong>SUPPLEMENTAL TERMS OF USE
                            – ClearStar Medical Testing Services through
                            <span class=\"palatinoFont\">SEC<sup>3</sup>URE</span> </strong>`;
      this.displayText = this.clearStarTerms;
    } else if (addOnCategory === 'rdp') {
        this.displayHeader = `<strong>SUPPLEMENTAL TERMS OF USE
                             – <span class=\"palatinoFont\">SEC<sup>3</sup>URE</span>
                             RADIATION EXPOSURE PACKAGE </strong>`;
        this.displayText = this.rdpTerms;
    } else if (addOnCategory === 'minuteClinic') {
      this.displayHeader = `<strong>Supplemental Terms of Use – MinuteClinic Vaccination
                             Services through <span class=\"palatinoFont bold\">SEC<sup>3</sup>URE</span> </strong>`;
       this.displayText = this.minuteClinicTerms;
    }
     return false;
     */
  }
}
