import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-manage-autopay',
  templateUrl: './manage-autopay.component.html',
  styleUrls: ['./manage-autopay.component.scss']
})
export class ManageAutopayComponent implements OnInit {
  public userNames;
  public pageToken;
  public localHost = environment.serverUrl;
  public productName: any;
  public myGroup: FormGroup;
  public hasAutoRenew = false;
  public header = { 'Content-Type': 'application/x-www-form-urlencoded' };
  public errorMsg;
  
  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private spinner: SpinnerService,
    private userInfo: UserService) { 
      router.queryParams.subscribe(token => {
        this.pageToken = token['token'];
        sessionStorage.setItem('token', this.pageToken);
      });

      this.myGroup = new FormGroup({
        isAutoRenewal: new FormControl()
     });
     this.spinner.checkHttpPending(true);
     this.setProductName();
     this.setCurrentSubscription();
    }

  ngOnInit() {
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.localHost}/commerce/product_renewal/userData_get`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: User) => {
          sessionStorage.setItem('inboundUrl', result.inbound_base_url);
          this.userNames = { firstName: result.first_name, lastName: result.last_name, email: result.email, userId: result.userId };
          sessionStorage.setItem('user', JSON.stringify({
            firstName: this.userNames.firstName,
            lastName: this.userNames.lastName,
             email: this.userNames.email
            }));
          this.userInfo.getUserNames(this.userNames);
        });

      

  }

  setProductName() {
    this.http.post(`${this.localHost}/commerce/products/getRatePlanName`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: any) => {
          this.productName  = result.productName.replace('SEC3URE', 'SEC<sup>3</sup>URE');
          sessionStorage.setItem('productName', this.productName);
          sessionStorage.setItem('price', result.sumPrice);
          this.hasAutoRenew = false;
        });
  }

  setCurrentSubscription() {
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.localHost}/commerce/products/getCurrentSubscription`, `token=${this.pageToken}`, { headers: this.header })
        .subscribe((result: any) => {
          this.hasAutoRenew = result.autoRenew;
          sessionStorage.setItem('hasAutoRenew', result.autoRenew);
          sessionStorage.setItem('expiration_date', result.endOfTermDate);
          sessionStorage.setItem('sub_id', result.zId);
          this.spinner.checkHttpPending(false);
        });
  }

  confirm() {
    this.updateAuopayStatus(true);
  }

  updateAuopayStatus(status) {
    this.spinner.checkHttpPending(true);
    const sub_id = sessionStorage.getItem('sub_id');
    this.http.post(`${this.localHost}/commerce/Checkout/optinAutoRenew`, `token=${this.pageToken}&subscription_id=${sub_id}&auto_renew_status=${status}`, { headers: this.header })
        .subscribe((result: any) => {
          this.spinner.checkHttpPending(false);
          this.goBackSec3ure();
        });
  }

  goBackSec3ure() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }
}
