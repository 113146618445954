import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { environment } from './environments/environment';

@Injectable()
export class UserService {
public countryList = new Subject<any>();
public userNames = new Subject<any>();
public showCheckout = new Subject<any>();
public localHost = environment.serverUrl;
public pageToken: any;
  constructor(
    private http: HttpClient,
    private session: SessionStorageService
  ) {}
  public getCountryList = function(cl) {
    const countryIso = [];
    for (const key in cl) {
      if (cl) {
        countryIso.push({'country_name': cl[key].country_name, 'iso': key});
      }
    }
     return countryIso;
  };

  public getStateList = function(cl) {
    const stateArray = [];
    for (const key in cl) {
      if (cl) {
        stateArray.push(cl[key].state_abbr);
      }
    }
     return stateArray;
  };

  public getCountries = function() {
    this.pageToken = sessionStorage.getItem('token');
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.localHost}/commerce/products/countrylist_get`, `token=${this.pageToken}`,
    {headers: headers} );
  };
  public getUserNames = function(users) {
    this.userNames.next(users);
  };

  public getStates = function(countryCode) {
    this.pageToken = sessionStorage.getItem('token');
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.localHost}/commerce/products/stateList_get`, `token=${this.pageToken}&country_code=${countryCode}`,
    {headers: headers} );
  }

  public getCheckoutStatus = function(isCheckout) {
    this.showCheckout.next(isCheckout);
  };

}
