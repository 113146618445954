import { Component, OnInit, ChangeDetectorRef , ElementRef} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user.service';
import { ModelService } from '../model.service';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { CountrySelect } from '../validators/country-select.validator';


@Component({
  selector: 'app-work-address',
  templateUrl: './work-address.component.html',
  styleUrls: ['./work-address.component.scss']
})
export class WorkAddressComponent implements OnInit {

  public checkOutform: FormGroup;
  public addressVerify: FormGroup;
  public subscription: Subscription;
  public localhost = environment.serverUrl;
  public pageToken: any;
  public States: string[] = [];
  public countries: any[];
  public checkout: any;
  public submitAbled = false;
  public billingAddress = false;
  public panelId = 'billing';
  public ba: Object;
  public billingData: Object;
  public addressOne: string;
  public addressTwo: string;
  public country: string;
  public city: string;
  public state: string;
  public zip5: string;
  public zip3: string;
  public zip: string;
  public disabled: string;
  public disableButton: string;
  public updateAddressFlag = false;
  public updatePaymentFlag = false;
  public selectBillingMenu = true;
  public isCanada = false;
  public renewal_id = null;

  constructor(private router: ActivatedRoute,
    private route: Router,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private model: ModelService,
     private session: SessionStorageService,
    private spinner: SpinnerService,
    private user: UserService,
    private elementRef: ElementRef) { }


ngOnInit() {
   this.updatePaymentFlag = JSON.parse(sessionStorage.getItem('updatePaymentFlag'));
   this.renewal_id = sessionStorage.getItem('renewal_id');
   this.updateAddressFlag = JSON.parse(sessionStorage.getItem('updateAddressFlag'));
   if(this.renewal_id) {
    this.updateAddressFlag = true;
   }
   this.spinner.checkHttpPending(true);
   const header = {'Content-Type': 'application/x-www-form-urlencoded'};
   this.pageToken = sessionStorage.getItem('token');
   this.http.post(`${this.localhost}/commerce/products/countrylist_get`,
                 `token=${this.pageToken}`, {headers: header}).subscribe(cl => {
      const countryStr = JSON.stringify(cl);
      this.countries = this.user.getCountryList(JSON.parse(countryStr));

      if (this.updateAddressFlag) {
          let addressTwoData = '';
          if (sessionStorage.getItem('address2')) {
              addressTwoData = sessionStorage.getItem('address2');
          }
          if (sessionStorage.getItem('address1') !== 'null' &&  sessionStorage.getItem('city') !== 'null') {
            this.checkOutform.get('billingAddress.addressOne').setValue(sessionStorage.getItem('address1') || '');
            this.checkOutform.get('billingAddress.addressTwo').setValue(addressTwoData);
            this.checkOutform.get('billingAddress.country').setValue(sessionStorage.getItem('country'));
            this.checkOutform.get('billingAddress.city').setValue(sessionStorage.getItem('city'));
            this.checkOutform.get('billingAddress.state').setValue(sessionStorage.getItem('state'));
            this.checkOutform.get('billingAddress.zip').setValue(sessionStorage.getItem('zip'));
          }
          this.disabled = 'btn-default disable-gray';
          this.disableButton = 'disabled';
          this.ref.detectChanges();
      }

      let baString = sessionStorage.getItem('billingAddress');

      if(baString) {
        let billingAddr = JSON.parse(baString);
        this.billingAddress = billingAddr;
        this.checkOutform.controls.billingAddress.setValue({
          addressOne: billingAddr.addressOne,
          addressTwo: billingAddr.addressTwo,
          country: billingAddr.country,
          city: billingAddr.city,
          state: billingAddr.state,
          zip: billingAddr.zip
        });
        this.checkForError();
        this.setBillingStateValue();
      }
      
      this.spinner.checkHttpPending(false);
  });

  this.checkOutform = new FormGroup({
       billingAddress: new FormGroup({
           addressOne: new FormControl('', Validators.required),
           addressTwo: new FormControl(''),
           country: new FormControl('', [Validators.required]),
           city: new FormControl('', Validators.required),
           state: new FormControl('', [Validators.required, CountrySelect]),
           zip: new FormControl('', Validators.required)
        })
  });
   this.checkForError();
   this.setBillingStateValue();
   sessionStorage.setItem('layoutTag', 'Evergreen');
   this.pageToken = sessionStorage.getItem('token');
   this.disabled = 'btn-default disable-gray';
   this.disableButton = 'disabled';
   this.submitAbled = false;
}

private setBillingStateValue = function () {
  this.countryField = this.checkOutform.controls.billingAddress.get('country');
  this.countryField.valueChanges.subscribe((val: string) => {
      this.checkOutform.controls.billingAddress.get('state').reset();
      this.checkOutform.controls.billingAddress.get('zip').reset();
      if ((val) && ((val === 'US') || (val === 'CA'))) {
         this.user.getStates(val).subscribe(cl => {
               this.States = this.user.getStateList(cl);
               this.selectBillingMenu = true;
               if (val === 'CA') {
                  this.isCanada = true;
               }
               this.ref.detectChanges();
         });
      } else {
          this.selectBillingMenu = false;
          this.ref.detectChanges();
      }
  });
};

public checkLength = function(len, ele, control) {
    const fieldLength = ele.toString().length;
    if (fieldLength <= len) {
       return true;
    } else {
       if (control === 'zip') {
          let str = ele.toString();
           str = str.substring(0, str.length - 1);
          this.checkOutform.controls.billingAddress.get('zip').setValue(Number(str));
        }
    }
};

  public checkCount = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.billingAddress.zip) {
        this.checkLength(total, val.billingAddress.zip, control);
      }
    });
  };

public validateBillingAndSave = function() {
  let noErrors = true;
  Object.keys(this.checkOutform.controls.billingAddress.controls).forEach(key => {
  const controlErrors: ValidationErrors = this.checkOutform.controls.billingAddress.get(key).errors;
  if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
          }
        });
    }
  });
  if (noErrors) {
    this.spinner.checkHttpPending(true);
    this.model.clearData();

    this.billingData = {
      cardholder: this.ba.firstName + '' + this.ba.lastName,
      country: this.ba.country,
      state: this.ba.state,
      city: this.ba.city,
      addressOne: this.ba.addressOne,
      addressTwo: this.ba.addressTwo,
      zip: this.ba.zip
    };
    sessionStorage.setItem('billingAddress', JSON.stringify(this.billingData));
    this.model.getPaymentData({billing: this.billingData});
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const postData = `token=${this.pageToken}&userId=${this.ba.userId}&applicationId=${this.ba.applicationId}&` + 
                      `address1=${this.ba.addressOne}&address2=${this.ba.addressTwo}&city=${this.ba.city}&state=${this.ba.state}` + 
                      `&zip=${this.ba.zip}&country=${this.ba.country}`;
     const saveAddress = this.http.post(`${this.localhost}/commerce/Checkout/saveUserAddress_post`, postData,
                         { headers: header }).subscribe(response => {
          this.spinner.checkHttpPending(false);
        });
    }
};

public checkForError = function() {
  const header = {'Content-Type': 'application/x-www-form-urlencoded'};
  this.checkOutform.valueChanges.subscribe(val => {

      const zip = val.billingAddress.zip == null ? '' : val.billingAddress.zip.toString();
      const applicationId = sessionStorage.getItem('applicationId');
      const userId = sessionStorage.getItem('userId');
      const firstName = sessionStorage.getItem('first_name');
      const lastName = sessionStorage.getItem('last_name');

      if (firstName && lastName && val.billingAddress.country && val.billingAddress.city && 
          val.billingAddress.state && ((zip.length === 5) && (val.billingAddress.country === 'US') ||
         ((zip.length > 0 ) && (val.billingAddress.country !== 'US')))) {
          this.disabled = 'btn-primary';
          this.disableButton = '';
      } else {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
      }

      this.ba = {
        applicationId: applicationId,
        userId:  userId,
        country: val.billingAddress.country,
        state: val.billingAddress.state,
        city: val.billingAddress.city,
        firstName: firstName,
        lastName: lastName,
        addressOne: val.billingAddress.addressOne,
        addressTwo: val.billingAddress.addressTwo,
        zip: val.billingAddress.zip
      };
  });
};

public  goCredCardRegistration = function() {
    this.validateBillingAndSave();
    if (this.updateAddressFlag && this.updatePaymentFlag) {
      sessionStorage.setItem('updatePaymentFlag', JSON.stringify(true));
    }
    this.route.navigateByUrl('CreditcardRegistration');
};

public  goRegistrationConfirmation = function() {
  this.validateBillingAndSave();
  this.route.navigateByUrl('RegistrationConfirm');
};



public goBackSec3ure = function() {
  if (this.updateAddressFlag  &&  !this.updatePaymentFlag) {
    this.route.navigateByUrl('RegistrationConfirm');
  } else {
    window.location.href = sessionStorage.getItem('inboundUrl');
  }
};

public onBlur = function() {
    this.checkOutform.valueChanges.subscribe(val => {
      const zip = val.billingAddress.zip.toString();

      const applicationId = sessionStorage.getItem('applicationId');
      const userId = sessionStorage.getItem('userId');
      const firstName = sessionStorage.getItem('first_name');
      const lastName = sessionStorage.getItem('last_name');

      if (firstName && lastName && val.billingAddress.country && val.billingAddress.city && 
          val.billingAddress.state && ((zip.length === 5) && (val.billingAddress.country === 'US') ||
          ((zip.length > 0 ) && (val.billingAddress.country !== 'US')))) {
            this.disabled = 'btn-primary';
            this.disableButton = '';
      } else {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
      }

      this.ba = {
        applicationId: applicationId,
        userId: userId,
        country: val.billingAddress.country,
        state: val.billingAddress.state,
        city: val.billingAddress.city,
        firstName: firstName,
        lastName: firstName,
        addressOne: val.billingAddress.addressOne,
        addressTwo: val.billingAddress.addressTwo,
        zip: val.billingAddress.zip
      };
    });
 };
}

















