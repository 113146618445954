import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { CalculateTotalService } from '../calculate-total.service';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { TermsAcceptService } from '../terms-accept.service';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { environment } from '../environments/environment';
import { PlatformLocation } from '@angular/common';
import { SpinnerService } from '../spinner/spinner.service';
import { Subscriber } from 'rxjs/Subscriber';
declare var JSEncrypt: any;

export interface Product {
  name: String;
  price: Number;
}

export interface TaxType {
  success: boolean;
  taxAmount: any;
  cancelAmt: any;

}

interface ZuoraForm {
  'fieldKey': string;
  'fieldStyle': string;
  'id': string;
  'token': string;
  'signature': string;
  'tenantId': string;
  'encryptedValues': string;
  'host': string;
  'creditCardAddress1': string;
  'creditCardAddress2': string;
  'creditCardCity': string;
  'creditCardCountry': string;
  'creditCardHolderName': string;
  'creditCardPostalCode': string;
  'creditCardState': string;
  'creditCardType': string;
}
@Component({
  selector: 'app-checkout-status',
  templateUrl: './checkout-status.component.html',
  styleUrls: ['./checkout-status.component.scss']
})
export class CheckoutStatusComponent implements OnInit, OnChanges {
  public total: any;
  public tax = 0;
  public creditAmount = 0;
  public submitAbled = false;
  public taxes: Subscription;
  public subscription: Subscription;
  public localhost = environment.serverUrl;
  public host = 'http://localhost:4200';
  public zuora = '';
  public formData: ZuoraForm;
  public initTotal: Number;
  public termsAccepted = true;
  public encrypt = new JSEncrypt();
  public buttonStatus: any;
  public grandTotal: Number;
  public pageToken: any;
  public cardBase64: any;
  public showInitNumber = true;
  public showTotal = false;
  public couponToZero = false;
  public disabled: string;
  public purchasedMembership: Boolean;
  public disableButton: string;
  public product: Product;
  public payRefId: string;
  public receipt_cc_email: any;
  public errorMsg: string;
  @ViewChild('paymentFrame') paymentFrame: ElementRef;

  @Input() checkout?: any;
  constructor(private calculate: CalculateTotalService,
    private session: SessionStorageService,
    private termsStatus: TermsAcceptService,
    private route: Router,
    private http: HttpClient,
    private model: ModelService,
    public spinner: SpinnerService,
    public platformLocation: PlatformLocation) { }

  ngOnInit() {
    this.product = sessionStorage.getItem('product') ? JSON.parse(sessionStorage.getItem('product')) : {};
    this.pageToken = sessionStorage.getItem('token');
    this.purchasedMembership = JSON.parse(sessionStorage.getItem('isPurchased'));
    if (!this.checkout.isSubmitable) {
      this.disabled = 'btn-default disable-gray';
      this.disableButton = 'disabled';
      this.submitAbled = false;
    }
    if (this.checkout.button === 'Checkout') {
      this.initTotal = this.purchasedMembership ? this.product.price : 0;

      if (this.initTotal === 0) {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
        this.submitAbled = false;
      }
      this.calculate.totalSet.subscribe((t: any) => {
        this.total = t;
        this.showInitNumber = false;
        this.buttonStatus = this.checkout.button;

        if (this.total.total > 0 || this.couponToZero) {
          this.disabled = 'btn-primary';
          this.disableButton = '';
          this.submitAbled = true;
        } else {
          this.disabled = 'btn-default disable-gray';
          this.disableButton = 'disabled';
          this.submitAbled = false;
        }
      });
    } else if (this.checkout.button === 'Place Order' || this.checkout.button === 'Order') {
      this.calculate.totalSet.subscribe((t: any) => {
        this.total = t;
        this.grandTotal = t;
        this.showTotal = true;
        if (sessionStorage.getItem('coupon_enabled') && t.total === 0) {
          this.couponToZero = true;
        }
      });
      const tota = JSON.parse(sessionStorage.getItem('total'));
      if (!sessionStorage.getItem('total')) {
        this.grandTotal = this.product.price ? this.product.price : 0;
        this.showTotal = true;
      } else if (tota.total.total >= 0) {
        this.initTotal = this.purchasedMembership ? this.product.price : 0;
        this.total = tota.total;
        this.grandTotal = this.total.total;
        this.showTotal = true;
      }
    }

    this.subscription = this.termsStatus.termsStream.subscribe((statusData) => {
      this.termsAccepted = statusData.termsAccepted;
      if (statusData.termsAccepted) {
        this.disabled = 'btn-primary';
        this.disableButton = '';
      } else {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
      }
    });
    this.taxes = this.model.sentTaxes.subscribe((tax: TaxType) => {
      if (tax.success) {
        // tax.taxAmount;
        this.creditAmount = tax.cancelAmt;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkout.currentValue.email) {
      this.receipt_cc_email = changes.checkout.currentValue.email;
    }
    const headerPayment = { 'Content-Type': 'application/x-www-form-urlencoded' };
    if (changes.checkout.currentValue.isSubmitable) {
      this.disabled = 'btn-primary';
      this.disableButton = '';
      this.submitAbled = true;
    } else if (changes.checkout.currentValue.paymentZero) {
      this.disabled = 'btn-primary';
      if (changes.checkout.currentValue.isSubmitable) {
        this.disableButton = '';
      } else {
        if ((changes.checkout.currentValue.status === 'allValidateNoPayment') &&
          (changes.checkout.currentValue.paymentZero)) {
          this.disableButton = '';

        } else {
          this.disabled = 'btn-default disable-gray';
          this.disableButton = 'disabled';
        }
      }
    } else {
      if ((changes.checkout.currentValue.status === 'billValidate') &&
        (changes.checkout.currentValue.isSubmitable)) {
        this.disabled = 'btn-primary';
        this.disableButton = '';
        this.submitAbled = true;
      } else {
        this.disabled = 'btn-default disable-gray';
        this.disableButton = 'disabled';
        this.submitAbled = false;
      }
    }

  }

  public campaignDataTracking = function(trackStep): void {
    const campaignId = sessionStorage.getItem('campaignId');
    const campaignToken = sessionStorage.getItem('campaignToken');

    if ((campaignId) && (campaignToken)) {
      const httpHeader = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const current_token = sessionStorage.getItem('token');
      this.spinner.checkHttpPending(true);
      this.http.post(`${this.localhost}/commerce/checkout/campaign_data_tracking`,
        `token=${current_token}&step=${trackStep}`, { headers: httpHeader })
        .subscribe(result => {
        }, err => {
          console.log(err);
        }, () => {
          this.spinner.checkHttpPending(false);
        });
    }
  };

  public runCheckout = function() {
    
    this.errorMsg = '';
    if (this.checkout.button === 'Checkout' && this.termsAccepted) {
      this.campaignDataTracking('4');
      this.route.navigateByUrl('Checkout');
      return;
    }
    this.payRefId = sessionStorage.getItem('payRefId');
    if (!this.payRefId) {
      this.payRefId = null;
    }
    this.spinner.checkHttpPending(true);
    const receipt_cc_email = this.receipt_cc_email;
    const headerPayment = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const shippingAddress = sessionStorage.getItem('shippingAddress');
    const billingAddress = sessionStorage.getItem('billingAddress');
    const priorityPurchase = sessionStorage.getItem('priorityPurchase');
    if(!billingAddress) {
        this.errorMsg = 'Error: Invalid Address';
        this.spinner.checkHttpPending(false);
        return;
    }

    this.spinner.checkHttpPending(true);
    const params = `token=${this.pageToken}&paymentMethodId=${this.payRefId}&receipt_cc_email=${receipt_cc_email}&purchase_priority=${priorityPurchase}&shippingAddress=${shippingAddress}&billingAddress=${billingAddress}`;
    this.http.post(`${this.localhost}/commerce/Checkout/createSubscription_post`,
      params, { headers: headerPayment }).subscribe((data: any) => {
        if (data.subscriptionStatus === 'Success') {
          this.campaignDataTracking('5');
          this.route.navigateByUrl('Confirmation');
        }
        else {
          this.errorMsg = 'Error: ' + data.reason;
        }
        this.spinner.checkHttpPending(false);
      });
  };
}
