import { Component, Output, OnInit, EventEmitter, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import { CalculateTotalService } from '../calculate-total.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OrderSummaryService } from '../order-summary.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
interface User {
  first_name: string;
  last_name: string;
  email: string;
  userId: string;
  coupon_enabled: string;
  inbound_base_url: string;
  campaignId: string;
  campaignToken: string;
  campaignProduct: string;
  tdapOnFile: string;
  admin_user_id: string;
}
@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit, AfterViewInit {
  public localHost = environment.serverUrl;
  public pageToken: any;
  public buttonType: any[] = [];
  public updateSelected = 'Choose';
  public firstName: string;
  public loadAddons = false;
  public lastName: string;
  public membership: any[] = [];
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public passportName: string;
  public token = 'token';
  public runAddons = false;
  public includes: any[] = [];
  public policy: string[] = [];
  public productPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public productName: string;
  public packageInfo: any;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public isCouponEnabled;
  public packageID = '';
  public isLoadAnotherProduct = false;
  public showSuccess = false;
  public showWarningMsg = false;
  public showPurchasedMsg = false;
  public evergreenEnabled = false;

  public basicIncludes: any = [];
  public basicIncludesArray: any = [];
  public checkStatus: any;

  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private calculate: CalculateTotalService,
    private spinner: SpinnerService,
    private userInfo: UserService,
    private domSanitizer: DomSanitizer,
  private order: OrderSummaryService,
  private ref: ChangeDetectorRef) {
    const includes = [];
    const includesArray = [];
    let finalIncluds = '';
    let trustedHtml: SafeHtml;
    const policy = [];
    const buttonType = [];
    const membership = [];
    let packageID = '';
    const pushedInclude = [];
    let initPrice = null;
    let currentSl = '';
    let runAddons = false;
    let passportId = '';
    let passportName = '';
    let isPurchased: boolean;
    
    sessionStorage.clear();
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    router.queryParams.subscribe(token => {
      this.spinner.checkHttpPending(true);
      this.pageToken = token['token'];
     
      if (token['token']) {
        const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${token['token']}`, { headers: header });
        packages.subscribe((res: any) => {
          this.membership = res;
          this.packageInfo = sessionStorage.setItem('packageInfo', JSON.stringify(res));
          res.filter(function(val) {
            if (val.is_purchased) {
              isPurchased = val.is_purchased;
              currentSl = val.rate_plan[0].serviceLevel;
              buttonType.push({buttonType: 'Your Plan', disabled: 'disabled'});
              packageID = val.Id;
            } else {
              isPurchased = false;
              buttonType.push({buttonType: 'Choose', disabled: ''});
            }
            if (val.detailed_description === '') {
              policy.push('');
            } else {
              policy.push(JSON.parse(val.detailed_description));
            }
          });
          sessionStorage.setItem('serviceLevel', currentSl);
          this.packageID = packageID;
          this.buttonType = buttonType;
          sessionStorage.setItem('isPurchased', JSON.stringify({ isPurchased }));
          this.buttonType.filter(function(val, ind, all) {
            if (val.buttonType === 'Choose') {
              // A membership is able to be choosen
            } else {
              runAddons = true;
            }
          });
          this.runAddons = runAddons;
          this.policy = policy;
          this.policy.filter(function(val, index, all) {
            if(all[index] != '') {
              includes.push(all[index][1]);
            }
          });
          includes.filter(function(valu, inde, a) {
            includesArray.push(a[inde]);
          });

          includesArray.filter(function(v, ins, al) {
            v.filter(function(va, inde, a) {
              if (va[inde + 1] === undefined) {
                _.forEach(va, function(value) {
                  if (value instanceof Object) {
                    if (value.length > 1) {
                      finalIncluds += `<ul>`;
                      _.forEach(value, function(sub) {
                        for (const key in sub) {
                          if (sub) {
                            let vSub = '';
                            vSub = sub[key];
                            if (vSub === undefined) {
                            finalIncluds += domSanitizer.bypassSecurityTrustHtml(
                              `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                            aria-hidden="true"></i></div><div class="font-size-20">${vSub}</div></div>`);
                            } else {
                              finalIncluds += `<li>${vSub}</li>`;
                            }
                          }
                        }
                      });
                      finalIncluds += `</ul>`;
                    }
                    for (const key in value) {
                      if (value.length === undefined) {
                        if (value[key] !== undefined) {
                          const tot = value[key];
                          finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${value[key]}</div></div>`;
                        }
                      }
                    }
                  }
                });
              } else {
                finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${va[inde + 1]}</div></div>`;
              }
            });
            trustedHtml = domSanitizer.bypassSecurityTrustHtml(finalIncluds);
            pushedInclude.push(trustedHtml);
            finalIncluds = '';
          });

          this.membership.filter(function(val) {
            if (val.rate_plan.sumPrice !== 'FREE') {
              val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
            }
            if (val.Name === 'SEC3URE Passport') {
              val.is_purchased ? initPrice = 0 : initPrice = Number(val.rate_plan[0].sumPrice);
              passportId = val.Id;
              passportName = val.Name;
            }
            val.detailed_description = pushedInclude;
          });
          this.productPrice = initPrice;
          this.productName = passportName;
          this.http.post(`${this.localHost}/commerce/products/userData_get`, `token=${token['token']}`, { headers: header })
            .subscribe((result: User) => {
              this.userNames = { firstName: result.first_name, lastName: result.last_name, email: result.email, userId: result.userId };
              this.userInfo.getUserNames(this.userNames);
              sessionStorage.setItem('user', JSON.stringify(this.userNames));
              this.isCouponEnabled = (result.coupon_enabled === '1') || (parseInt(result.admin_user_id) > 0);
              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              sessionStorage.setItem('campaignId', result.campaignId);
              sessionStorage.setItem('campaignToken', result.campaignToken);
              sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));
              if (result.tdapOnFile !== null) {
                sessionStorage.setItem('tdapOnFile', JSON.stringify(result.tdapOnFile['tdapOnFile']));
              } else {
                sessionStorage.setItem('tdapOnFile',  null);
              }



              if (result.campaignId !=='') {
                if (!(this.runAddons)) {
                     this.showWarningMsg = true;
                     setTimeout(() => {
                         this.showWarningMsg = false;
                         this.ref.detectChanges();
                     }, 8000);
                 }
              }
              this.ref.markForCheck();
              if (this.runAddons) {
                let addonElements;
                const head = { 'Content-Type': 'application/x-www-form-urlencoded' };
                this.http.post(`${this.localHost}/commerce/products/addonList_get`,
                `token=${this.pageToken}&package_id=${this.packageID}`, { headers: head })
                .subscribe(data => {
                  sessionStorage.setItem(this.modelData, JSON.stringify(data));
                  if (this.productPrice) {
                    sessionStorage.setItem('updateProductPrice', JSON.stringify(this.productPrice));
                  }
                  this.checkStatus = true;
                  this.model.getModelAddons(data, '');
                  addonElements = document.getElementById('addonsClinic');
                  sessionStorage.setItem('product', JSON.stringify({ price: this.productPrice, name: this.productName }));
                  sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled));
                  this.loadAddons = true;
                  this.ref.markForCheck();
                  setTimeout(() => {
                    this.purchaseStatus();
                  }, 0);
                  }, err => {
                    console.log(err);
                  }, () => {
                  const offSetTop = document.getElementById('addonsClinic').offsetTop;
                  let count = 0;
                  let currPos;
                  const scrollStep = Math.PI / (1000 / 10);
                  const diff = offSetTop - document.body.scrollTop;
                  const start = document.getElementById('addonsClinic').scrollTop;
                  const cancel = setInterval(function() {
                    if (offSetTop !== currPos) {
                    count = count + 1;
                    currPos = start + diff * (0.5 - 0.5 * Math.cos(count * scrollStep));
                    window.scrollTo(0, currPos);
                    } else {
                      clearInterval(cancel);
                    }
                    // window.scrollTo(0, 500);
                  }, 10);
                  });
              }
            });

          this.spinner.checkHttpPending(false);
        });
      } else {
        // redirect user in navigated to without valid token
      }
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  public purchaseStatus = function() {
    const addOns = JSON.parse(sessionStorage.getItem('modelData'));
    if (addOns) {
       for (let i = 0; i < addOns.length; i++) {
           const item = addOns[i];
           if (item.is_campaign_product) {
            if ((!item.expiration_date) ||
                (item.valid_status === 'renewable') ||
                (item.charges[0].ProductType__c === 'MinuteClinic')) {
                  const elementFound: HTMLElement = document.getElementById(item['Id']) as HTMLElement;
                  elementFound.click();
                  this.showSuccess = true;
                    setTimeout(() => {
                         this.showSuccess = false;
                         this.ref.detectChanges();
                    }, 8000);
            } 
            if ((item.valid_status === 'valid') && (item.charges[0].ProductType__c !== 'MinuteClinic')){
                   this.showSuccess = false;
                   this.showPurchasedMsg = true;
                   setTimeout(() => {
                        this.showPurchasedMsg = false;
                        this.ref.detectChanges();
                   }, 8000);
            }
           }
        }
        this.ref.markForCheck();
     }
  };
  goToPurchaseHistory() {
    location.href = sessionStorage.getItem('inboundUrl');
  }
  ngOnInit() {
      sessionStorage.setItem('itemsSelected', JSON.stringify(false));
      sessionStorage.clear();
      if (this.route.url.indexOf(`/EvergreenUpgrade?token=${this.pageToken}`) > -1) {
        sessionStorage.setItem('evergreenEnabled', JSON.stringify(true));
        this.evergreenEnabled = true;
      } else {
        sessionStorage.setItem('evergreenEnabled', JSON.stringify(false));
        this.evergreenEnabled = false;
      }
      sessionStorage.setItem(this.token, this.pageToken);
      this.model.setModelData('', '', '');
      this.model.shippableProduct = false;
  }

  ngAfterViewInit() {
      this.purchaseStatus();
  }


  private goToAddOns = function(id: any, i, buttonType, serviceLevel) {
    this.order.getServiceLevel(serviceLevel);
    sessionStorage.setItem('serviceLevel', serviceLevel);
    if (id && (this.updateSelected === buttonType)) {
      this.checkStatus = true;
      sessionStorage.removeItem('modelData');
      this.calculate.setTotalValue(this.productPrice);
      this.model.model = [];
      this.spinner.checkHttpPending(true);
      const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
      this.http.post(`${this.localHost}/commerce/products/addonList_get`,
        `token=${this.pageToken}&package_id=${id}&service_level=${serviceLevel}`, { headers: header })
        .subscribe(result => {
          sessionStorage.setItem(this.modelData, JSON.stringify(result));
          this.model.getModelAddons(result, 'choose');
          sessionStorage.setItem('user', JSON.stringify(
            { firstName: this.userNames.firstName,
              lastName: this.userNames.lastName,
               email: this.userNames.email,
               userId: this.userNames.userId
              }));
          sessionStorage.setItem('product', JSON.stringify({ price: this.productPrice, name: this.productName }));
          sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled));
          this.loadAddons = true;
          if (buttonType === 'Choose') {
            this.order.updateTotal(this.productPrice);
            if (this.productPrice) {
              sessionStorage.setItem('updateProductPrice', JSON.stringify(this.productPrice));
            }
            this.buttonType.filter(function(val, index, all) {
              if (i === index) {
                if (val.buttonType === buttonType) {
                  all[i].buttonType = 'In Cart';
                }
              }
            });
          } else {
            this.buttonType.filter(function(val, index, all) {
              if (val.buttonType === 'In Cart') {
                all[index].buttonType = 'Choose';
              }
            });
          }
          this.spinner.checkHttpPending(false);
          this.route.navigate(['Upgrade'],
          { queryParams: { token: this.pageToken } });
        }, err => {
          console.log(err);
        }, () => {
        });
    }
    if (buttonType === 'Your Plan') {
      /*this.order.updateTotal(null);
      this.calculate.setTotalValue(0);
      this.buttonType.filter(function(val, index, all) {
          if (val.buttonType === 'In Cart') {
            val.buttonType = 'Choose';
          }
      });
      this.updateSelected = 'Choose';
      const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
      this.http.post(`${this.localHost}/commerce/products/addonList_get`,
        `token=${this.pageToken}&package_id=${id}`, { headers: header })
        .subscribe(result => {
          sessionStorage.setItem(this.modelData, JSON.stringify(result));
          this.model.getModelAddons(result, 'Your Plan');
          this.order.resetCart();
          this.model.model = [];
          sessionStorage.setItem('user', JSON.stringify({
            firstName: this.userNames.firstName,
             lastName: this.userNames.lastName,
              email: this.userNames.email
             }));
          sessionStorage.setItem('product', JSON.stringify({ price: this.productPrice, name: this.productName }));
          sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled));
          this.loadAddons = true;
          }, err => {
            console.log(err);
          }, () => {
          });*/
    }
  };

}
