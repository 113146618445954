import { Component, Output, OnInit, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import { CalculateTotalService } from "../calculate-total.service";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import { CountrySelect } from '../validators/country-select.validator';

@Component({
  selector: 'app-registration-confirm',
  templateUrl: './registration-confirm.component.html',
  styleUrls: ['./registration-confirm.component.scss']
})
export class RegistrationConfirmComponent implements OnInit {

  public localHost = environment.serverUrl;
  public pageToken: any;
  public firstName: string;
  public lastName: string;
  public membership: any[] = [];
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public productName: string;
  public token = 'token';
  public includes: any[] = [];
  public policy: string[] = [];
  public productPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public packageInfo: any;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public isCouponEnabled;
  public passportPrice = 0;
  public userPlanType: any;
  public receipt_cc_email = '';
  public cardType: any;
  public maskedCardNum: any;
  public addressOne: string;
  public addressTwo: string;
  public city: string;
  public state: string;
  public country: string;
  public zip: string;
  public total: number;
  public serviceLevel: any;
  public everGreenOptIn = false;
  public autoRenewPlanName = 'SEC3URE Passport 2.0';
  public addressTwoFlag = false;
  public renewal_id = null;
  public errorMsg: string;
  public shippingAddress: any;
  public billingAddress: any;
  public reactivationRequired = false;
  public hasReward = false
  public priorityPurchase = false;
  public cartItems: any;
  public taxAmount: any;
  public showCouponFields = false;
  public couponform: FormGroup;

  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private spinner: SpinnerService,
    private calculate: CalculateTotalService,
    private ref: ChangeDetectorRef,
    private userInfo: UserService) {
      this.renewal_id = sessionStorage.getItem('renewal_id');
      this.productPrice = sessionStorage.getItem('secure_passport_price');
      this.showCouponFields = JSON.parse(sessionStorage.getItem('coupon_enabled'));
  }

  ngOnInit() {
    this.couponform = new FormGroup({
      couponCode: new FormControl('', Validators.required),
    });
    this.everGreenOptIn = (sessionStorage.getItem('everGreenOptIn') === 'true');
    sessionStorage.setItem('everGreenOptIn', 'false');
    this.reactivationRequired = JSON.parse(sessionStorage.getItem('reactivation_required'));
    this.hasReward = JSON.parse(sessionStorage.getItem('hasReward'));
    this.pageToken = sessionStorage.getItem('token');
    sessionStorage.setItem('layoutTag', 'Evergreen');
    const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
    const payRefId = sessionStorage.getItem('payRefId');
    this.spinner.checkHttpPending(true);
    const params = `token=${this.pageToken}&payRefId=${payRefId}`;
    this.http.post(`${this.localHost}/commerce/Checkout/getPaymentMethodData_post`,
                     params, { headers: headerPayment }).subscribe((data: any) => {
           this.cardType = data.result.CreditCardType;
           this.maskedCardNum = data.result.CreditCardMaskNumber;
           this.ref.detectChanges();
     });
     
     let address = sessionStorage.getItem('shippingAddress');

     if(address) {
       this.shippingAddress = JSON.parse(address);
     }
     this.billingAddress = JSON.parse(sessionStorage.getItem('billingAddress'));
     if (this.shippingAddress) {
        this.addressOne = this.shippingAddress.address;
        this.addressTwo = this.shippingAddress.suite;
        if (this.addressTwo) {
          this.addressTwoFlag = true;
        }
        this.city =  this.shippingAddress.city;
        this.state = this.shippingAddress.state;
        this.zip = this.shippingAddress.zip;
        this.country = this.shippingAddress.country;
        const self = this;
        setTimeout(function() {
        self.setAddressToCart();
        }, 3000);
     }

  }

  public setAddressToCart = function() {
    this.spinner.checkHttpPending(true);
    const userId = sessionStorage.getItem('userId');
    const header = {'Content-Type': 'application/x-www-form-urlencoded'};
     const applicationId = sessionStorage.getItem('applicationId');
    const postData = `token=${this.pageToken}&userId=${userId}&applicationId=${applicationId}` + 
    `&address1=${this.addressOne}&address2=${this.addressTwo}&city=${this.city}&state=${this.state}` + 
    `&zip=${this.zip}&country=${this.country}`;
this.http.post(`${this.localHost}/commerce/Checkout/setUserAddressInCart`,
    postData, { headers: header }).subscribe((data: any) => {
    const user_fname = sessionStorage.getItem('first_name');
    const user_lname = sessionStorage.getItem('last_name');
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.localHost}/commerce/checkout/saveContactDataGetTax`,
    'user_fname=' + user_fname + '&user_lname=' + user_lname +
    '&user_address1=' + this.addressOne + '&user_address2=' + this.addressTwo +
    '&user_city=' + this.city + '&user_state=' + this.state + '&user_zip=' + this.zip +
    '&user_country=' + this.country + '&token=' + this.pageToken, {headers: header}).subscribe((result: any) => {
        this.total = result.totalAmount;
        this.taxAmount = result.taxAmount;
        this.checkReactivation(result);
        this.spinner.checkHttpPending(false);
        this.ref.detectChanges();
    });
});
  }


  public addCoupon() {
    const couponCode = this.couponform.controls.couponCode.value;
    this.couponform.controls.couponCode.setValue('');
    console.log(couponCode);
    if (couponCode.length < 2) {
      return;
    }
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.spinner.checkHttpPending(true);
    let reactivationPlanId = sessionStorage.getItem('reactivation_plan_id');
    let serviceLevel = sessionStorage.getItem('serviceLevel');
    let reactivationPlanArg = `&reactivation_plan_id=${reactivationPlanId}`;
    this.passportId = sessionStorage.getItem('productId');
    let couponCodeArg = `&coupon_code=${couponCode}`
    this.http.post(`${this.localHost}/commerce/products/addonList_get`,
      `token=${this.pageToken}&package_id=${this.passportId}&service_level=${serviceLevel}${reactivationPlanArg}${couponCodeArg}`, { headers: header })
      .subscribe(result => {
        this.setAddressToCart();
        this.spinner.checkHttpPending(false);
      }, err => {
        console.log(err);
        this.spinner.checkHttpPending(false);
      }, () => { });



  }

  private checkReactivation(result) {
    this.reactivationRequired = JSON.parse(sessionStorage.getItem('reactivation_required'));
    if (this.reactivationRequired || this.hasReward) {
      this.cartItems = result.items;
    }
  }

  public  goBackSec3ure = function() {
      window.location.href = sessionStorage.getItem('inboundUrl');
  };

  public confirmation() {
      this.spinner.checkHttpPending(true);
      const receipt_cc_email = this.receipt_cc_email;
      const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
      const payRefId = sessionStorage.getItem('payRefId');
      let extra = '';
      if(this.renewal_id) {
        extra = `&renewal_id=${this.renewal_id}`;
      }
      let shippAddress = sessionStorage.getItem('shippingAddress');
      let billAddress = sessionStorage.getItem('billingAddress');
      const params = `token=${this.pageToken}&paymentMethodId=${payRefId}&receipt_cc_email=${receipt_cc_email}&purchase_priority=${this.priorityPurchase}` +
                     `&shippingAddress=${shippAddress}&billingAddress=${billAddress}&autoRenewPlanName=${this.autoRenewPlanName}&autoRenew=${this.everGreenOptIn}${extra}`;
      this.http.post(`${this.localHost}/commerce/Checkout/createSubscription_post`,
                       params, { headers: headerPayment }).subscribe((data: any) => {
             if (data.subscriptionStatus === 'Success') {
                 window.location.href = sessionStorage.getItem('passportUrl');
             }
             else {
                this.errorMsg = 'Error: ' + data.reason;
                this.spinner.checkHttpPending(false);
             }
       });
  }

  goWorkAddress() {
    sessionStorage.setItem('updateAddressFlag', JSON.stringify(true));
    this.route.navigateByUrl('ShippingAddress');
  }

  selectEvergreen(){
    this.everGreenOptIn = true;
  }

  deslectEvergreen(){
    this.everGreenOptIn = false;
  }

  selectPurchasePriority() {
    this.priorityPurchase = true;
  }

  deselectPurchasePriority() {
    this.priorityPurchase = false;
  }


  goCredCardRegistration() {
      sessionStorage.setItem('editPaymentFlag', JSON.stringify(true));
      this.route.navigateByUrl('EvergreenPaymentInfo');
  }
}

