import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderSummaryService } from '../order-summary.service';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { CalculateTotalService } from '../calculate-total.service';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
interface User {
  first_name: string;
  last_name: string;
  email: string;
  userId: string;
  coupon_enabled: string;
  inbound_base_url: string;
  campaignId: string;
  campaignToken: string;
  campaignProduct: string;
  tdapOnFile: string;
}


@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss']
})

export class RenewalComponent implements OnInit {
  public product:  Object;
  public localHost = environment.serverUrl;
  public pageToken: any;
  public firstName: string;
  public lastName: string;
  public currentMembership: any[] = [];
  public renewMembership: any[] = [];
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public passportName: string;
  public token = 'token';
  public renewPackage: any;
  public currentPackage: any;
  public renewal_id: any;
  public includes: any[] = [];
  public oldIncludes: any[] = [];
  public policy: string[] = [];
  public oldPolicy: string[] = [];
  public passportPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public isCouponEnabled;
  public itemPurchased = false;
  public campRenewFlag = false;
  public basicIncludes: any = [];
  public basicIncludesArray: any = [];
  public upgradeData: any;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private spinner: SpinnerService,
    private userInfo: UserService) {
    sessionStorage.clear();

    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.router.queryParams.subscribe((p: any) => {
      this.spinner.checkHttpPending(true);
      this.pageToken = p['token'];
      sessionStorage.setItem(this.token, p['token']);
      if (this.route.url.indexOf(`/EvergreenRenewal?token=${this.pageToken}`) > -1) {
        sessionStorage.setItem('evergreenEnabled', JSON.stringify(true));
      } else {
        sessionStorage.setItem('evergreenEnabled', JSON.stringify(false));
      }
      this.renewal_id = p['renewal_id'];
      sessionStorage.setItem('renewal_id', this.renewal_id);
      if (p['token']) {
        this.http.post(`${this.localHost}/commerce/product_renewal/userData_get`, `token=${p['token']}`, { headers: header })
        .subscribe((result: User) => {
          this.userNames = { firstName: result.first_name, lastName: result.last_name, email: result.email, userId: result.userId };
          sessionStorage.setItem('user', JSON.stringify({
            firstName: this.userNames.firstName,
            lastName: this.userNames.lastName,
             email: this.userNames.email
            }));
          this.userInfo.getUserNames(this.userNames);
          let coupon;
           result.coupon_enabled === '1' ? coupon = true : coupon = false;
          sessionStorage.setItem('isCouponEnabled', JSON.stringify(coupon));

          sessionStorage.setItem('inboundUrl', result.inbound_base_url);
          sessionStorage.setItem('campaignId', result.campaignId);
          sessionStorage.setItem('campaignToken', result.campaignToken);
          sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));
          sessionStorage.setItem('tdapOnFile', JSON.stringify(result.tdapOnFile['tdapOnFile']));
        });
        const packages = this.http.post(`${this.localHost}/commerce/product_renewal/packageList`,
          `token=${p['token']}&renewal_id=${this.renewal_id}`, { headers: header });
          packages.subscribe((res: any) => {
            let currentMembershipArray = [];
             res['latest_package'] ? currentMembershipArray = res['latest_package'] :
             currentMembershipArray = [];
            if (res['old_package']) {
              currentMembershipArray = [];
              sessionStorage.setItem('isOldPackage', JSON.stringify(true));
              return;
            }
            sessionStorage.setItem('product', JSON.stringify({
              price: currentMembershipArray[0].rate_plan[0].sumPrice,
              name: ''
            }));
            sessionStorage.setItem('renewMemberships', JSON.stringify(currentMembershipArray));
            this.http.post(`${this.localHost}/commerce/product_renewal/addonList`,
            `token=${this.pageToken}&package_id=${currentMembershipArray[0].Id}&renewal_id=${this.renewal_id}`, { headers: header })
            .subscribe(result => {
              sessionStorage.setItem(this.modelData, JSON.stringify(result));
              this.spinner.checkHttpPending(false);
              this.route.navigateByUrl('/RenewalRedirect');
            }, err => {
              console.log(err);
            });
        });
      } else {
        // redirect user in navigated to without valid token
      }
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  ngOnInit() {
    sessionStorage.setItem('productRenewal', JSON.stringify(true));
  }
}
