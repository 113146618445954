import { AbstractControl } from '@angular/forms';

export function ValidateCreditCardNumber(control: AbstractControl): { [key: string]: string } | null {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

  if (visaRegEx.test(control.value)) {
    return null ;
  } else if (mastercardRegEx.test(control.value)) {
    return null;
  } else if (amexpRegEx.test(control.value)) {
    return null;
  } else if (discovRegEx.test(control.value)) {
    return null;
  } else {
      return { 'creditCardtype': 'card invalid' };
  }
}
