import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import { environment } from './environments/environment';
import { CalculateTotalService } from './calculate-total.service';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderSummaryService {
  public serviceAddon: any;
  public servicePackagePlan: any;
  public serviceLevel;
  public token: any;
  public sessionToken = 'token';
  public price: any;
  public calculatedPrice: any;
  public modelData = 'modelData';
  public calculate: any[] = [];
  public model: any[] = [];
  public localhost = environment.serverUrl;
  public removeType: string;
  public addons = new Subject<any>();
  public updateAddons = new Subject<any>();
  public resetListCart = new Subject<any>();
  public currentServiceLevel = new Subject<any>();
  public packagePlans = new Subject<any>();
  constructor(private session: SessionStorageService,
  private http: HttpClient,
private total: CalculateTotalService) {
}

  public getAddonType() {
     return this.addons;
  }

  public getServiceLevel(sl) {
    this.currentServiceLevel.next(sl);
  }

  public resetCart() {
    this.resetListCart.next();
  }

  public updateTotal = function(total) {
    this.updateAddons.next(total);
  };

  public setCouponItem = function (data) {
    const coupons = [];
    const self = this;
    data.filter(function(val, index) {
      if (val.isDiscountItem) {
          coupons.push({
          name: val.ProductName,
          price: val.sumPrice
        });
        self.session.set('addedCoupons', coupons);
        const coupon = coupons[0].name;
        const couponPrice = coupons[0].price;
        self.serviceAddon = {
          'addon': coupon,
          'price': couponPrice
        };
        self.addons.next(self.serviceAddon);
        self.addons.complete();
      }
    });
  };
  public setPackagePlanType(packagePlan, price, remove) {
    if (!remove) {
      this.servicePackagePlan = {
        packagePlan,
        price
      };
      this.price = price;
      this.packagePlans.next(this.servicePackagePlan);
    } else {
      this.servicePackagePlan = {
        packagePlan,
        price,
        remove
      };
      this.packagePlans.next(this.servicePackagePlan);
    }
  }
  public setAddOnType(addon, price, remove) {
    if (!remove) {
      this.serviceAddon = {
        addon,
        price
      };
    this.model = JSON.parse(sessionStorage.getItem(this.modelData));
    this.token = sessionStorage.getItem (this.sessionToken);
      this.model.filter(function(val, index, all) {
        if (val.addon === addon) {
        this.calculate.push(val.addon);
        this.serviceLevel = val.serviceLevel;
        }
      });
      let addonVal = '';
     this.calculate.filter(function(v, i, a) {
      addonVal += '&addon[' + i + ']=' + v.addon;
     });
      this.price = price;
      this.addons.next(this.serviceAddon);
    } else {
      this.serviceAddon = {
        addon,
        price,
        remove
      };
      this.addons.next(this.serviceAddon);
    }
  }

  public setRemoveAddon (remove) {
     this.removeType = remove;
  }
  public removeAddOnType (): string {
    return this.removeType;
  }
}
