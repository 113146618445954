import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from './environments/environment';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';

@Injectable()
export class CalculateTotalService {
public localhost = environment.serverUrl;
public dynamicQuery: any;
public totalSet = new Subject<number>();
public pageToken: any;
public renewPackage: any;
public productRenewal: any;
public emptyValue: boolean;
  constructor(public http: HttpClient,
              private session: SessionStorageService) { }
  public setTotalValue  = function(total) {
      this.totalSet.next(total);
  };
  public calculateTotal = function(serviceLevel, model: any[], itemsDetails: any[], coupons: any[]): Observable<any> {
    let arrayOnAddons = '';
    let itemDetailArray = '';
    let couponArray = '';
    let renewFlag = '';
    const quanityArray = [];
    const itemsArray = [];

    const addOnArray = [];
    const header = {'Content-Type': 'application/x-www-form-urlencoded'};
    this.pageToken = sessionStorage.getItem('token');
    this.renewPackage = JSON.parse(sessionStorage.getItem('renewPackage'));
    this.productRenewal = sessionStorage.getItem('productRenewal');
    if (model !== undefined) {
      if (model.length !== 0) {
      model.filter(function(val, index, all) {
        arrayOnAddons += `&addons[${index}]=${val}`;
      });
    } else {
      arrayOnAddons = `&addons=null`;
    }
    } else {
      arrayOnAddons = `&addons=null`;
    }

    if (coupons !== undefined) {
      if (coupons.length !== 0) {
        coupons.filter(function(val, index, all) {
          couponArray += `&coupons[${index}]=${val}`;
        });
      } else {
        couponArray = `&coupons=null`;
      }
    } else {
      couponArray = `&coupons=null`;
    }

    if ( typeof itemsDetails === 'object') {
      if (itemsDetails !== undefined) {
        itemsDetails.filter(function(vl, ind, all) {
          itemDetailArray += `&itemsDetails[${ind}]${itemsDetails[ind].itemDetailArray}`;

        });
      } else {
        itemDetailArray = '';
      }
    } else {
      itemDetailArray = '';
    }
    if (this.productRenewal) {
      if (!this.renewPackage) {
        renewFlag = '&renew_flag=addon_only';
     }
    }
    let productRenewal = sessionStorage.getItem('productRenewal');
    let renewal_id = null;
    if(productRenewal) {
      renewal_id = sessionStorage.getItem('renewal_id');
    }
    

    this.dynamicQuery = `token=${this.pageToken}${renewFlag}&service_level=${serviceLevel}${arrayOnAddons}${itemDetailArray}${couponArray}&renewal_id=${renewal_id}`;
    return this.http.post(`${this.localhost}/commerce/products/calculateTotal_post`, `${this.dynamicQuery}`, { headers: header });
  };
}
