import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { CountrySelect } from '../validators/country-select.validator';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { environment } from '../environments/environment';
import { UserService } from '../user.service';
import { NgbModal, NgbAccordionConfig, NgbPanelChangeEvent,
         ModalDismissReasons, NgbModalRef, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss']
})

export class ShippingAddressComponent implements OnInit {
  public checkOutform: FormGroup;
  public addressVerify: FormGroup;
  public url = environment.serverUrl;
  public billingAddress: any;
  public shippingAddress: any;
  public States: string[] = [];
  public countries: any[];
  public pageToken: any;
  public sa: Object;
  public hideShipping: boolean = true;
  public disabled: string;
  public disableButton: string;
  public selectBillingMenu = true;
  public isCanadaBilling = false;
  public selectShippingMenu = true;
  public isCanadaShipping = false;
  public updateAddressFlag = false;
  public countryField: any;
  public shippingCountryField: any;
  private modalRef: NgbModalRef;
  public addressOne: string;
  public addressTwo: string;
  public country: string;
  public city: string;
  public Height: any;
  public pageHeight: string;
  public state: string;
  public zip5: string;
  public zip3: string;
  public sameAddress = true;
  public verifyingAddress = false;
  public addressVerified = false;
  public renewal_id = null;
  public shippingAddressCheckDone = false;
  public billingAddressStatus: any;
  public shippingAddressStatus: any;
  public pristineStatus: any;
  public billingZip: any;
  public shippingZip: any;
  public verificationCompleted = false;
  public titleFlag: any;
  public selectedCountryValue: string = 'US';

  @ViewChild('content') private content;
  @ViewChild('contentWarning') private contentWarning;
  localhost: any;

  constructor(private http: HttpClient,
    private user: UserService,
    private route: Router,
    private model: ModelService,
    private modalService: NgbModal,
    private ref: ChangeDetectorRef,
    public spinner: SpinnerService) {

      this.checkOutform = new FormGroup({
        billingAddress: new FormGroup({
           addressOne: new FormControl('', Validators.required),
           addressTwo: new FormControl(''),
           country: new FormControl('', [Validators.required, CountrySelect]),
           city: new FormControl('', Validators.required),
           state: new FormControl('', [Validators.required, CountrySelect]),
           zip: new FormControl('', Validators.required),
           isShippingSame: new FormControl('')
        }),
        shippingAddress: new FormGroup({
          shippingAddressOne: new FormControl('', Validators.required),
          shippingAddressTwo: new FormControl(''),
          shippingCountry: new FormControl('', [Validators.required, CountrySelect]),
          shippingCity: new FormControl('', Validators.required),
          shippingState: new FormControl('', [Validators.required, CountrySelect]),
          shippingZip: new FormControl('', Validators.required)
       }),
      });
    this.renewal_id = sessionStorage.getItem('renewal_id');
    const header = {'Content-Type': 'application/x-www-form-urlencoded'};
    this.pageToken = sessionStorage.getItem('token');
    this.spinner.checkHttpPending(true);
    this.http.post(`${this.url}/commerce/products/countrylist_get`, 
                  `token=${this.pageToken}`, {headers: header}).subscribe(cl => {
       const countryStr = JSON.stringify(cl);
       this.countries = this.user.getCountryList(JSON.parse(countryStr));
       this.countries.unshift({
       country_name: 'Select Country',
       region: '',
       abbr: '',
       abbr_three: ''
      });
      this.checkForPreviousAddress();
      this.spinner.checkHttpPending(false);
     });
  }

  ngOnInit() {
    this.updateAddressFlag = JSON.parse(sessionStorage.getItem('updateAddressFlag'));
    this.titleFlag = JSON.parse(sessionStorage.getItem('titleFlag'));
    this.addressVerify = new FormGroup({
      chooseAddress: new FormControl('recommended')
    });
    this.checkForError();
    this.setBillingStateValue();
    this.setShippingStateValue();
    this.disabled = 'btn-default disable-gray';
    this.disableButton = 'disabled';
  }

  checkForPreviousAddress() {
    const bAddress = sessionStorage.getItem('billingAddress');
    const sAddress = sessionStorage.getItem('shippingAddress');
    const hideShippingFlag = sessionStorage.getItem('hideShipping');
    if (hideShippingFlag) {
      this.hideShipping = JSON.parse(hideShippingFlag);
    }
    if (bAddress) {
      this.billingAddress = JSON.parse(bAddress);
      this.checkOutform.controls.billingAddress.setValue({
        addressOne: this.billingAddress.address,
        addressTwo: this.billingAddress.suite,
        country: this.billingAddress.country,
        city: this.billingAddress.city,
        state: this.billingAddress.state,
        zip: this.billingAddress.zip,
        isShippingSame: this.hideShipping
      });
    }
    if (sAddress) {
      this.shippingAddress = JSON.parse(sAddress);
      this.checkOutform.controls.shippingAddress.setValue({
        shippingAddressOne: this.shippingAddress.address,
        shippingAddressTwo: this.shippingAddress.suite,
        shippingCountry: this.shippingAddress.country,
        shippingCity: this.shippingAddress.city,
        shippingState: this.shippingAddress.state,
        shippingZip: this.shippingAddress.zip,
      });
    }
  }

  public addAddressInfo = function() {
    sessionStorage.setItem('hideShipping', JSON.stringify(this.hideShipping)); 
    if (this.verificationCompleted) {
       sessionStorage.setItem('billingAddress', JSON.stringify(this.billingAddress));
       sessionStorage.setItem('shippingAddress', JSON.stringify(this.shippingAddress));
       this.model.getPaymentData({billing: this.billingAddress});
       this.spinner.checkHttpPending(true);
      const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
      const postData = `token=${this.pageToken}&userId=${this.sa.userId}&applicationId=${this.sa.applicationId}&` + 
                      `address1=${this.sa.addressOne}&address2=${this.sa.addressTwo}&city=${this.sa.city}&state=${this.sa.state}` + 
                      `&zip=${this.sa.zip}&country=${this.sa.country}`;
      const saveAddress = this.http.post(`${this.url}/commerce/Checkout/saveUserAddress_post`, postData,
                         { headers: header }).subscribe(response => {
          this.spinner.checkHttpPending(false);

          if (this.updateAddressFlag) {
            if (this.titleFlag === 'TechBundle') {
              this.route.navigateByUrl('TechBundleMembershipConfirm');
            }
            else if(this.titleFlag === 'Passport_2.0') {
              this.route.navigateByUrl('RegistrationConfirm');
            } else {
              this.route.navigateByUrl('RadiationConfirmation');
            }
          } else {
            if(this.titleFlag === 'Passport_2.0'){
              this.route.navigateByUrl('CreditcardRegistration');
            }
            else {
              this.route.navigateByUrl('RadiationPayment');
            }
          }
        });
    }
  }
  onBlur() {
    this.checkForError();
  }

  checkboxChange() {
    this.hideShipping = !this.hideShipping;
    this.checkForError();
  }

  public checkForError = function() {

    this.checkOutform.valueChanges.subscribe(val => {
        this.verificationCompleted = false;
        this.ref.detectChanges();
        this.billingZip = '';
        let btnEnabled = false;
        if (val.billingAddress.zip) {
          this.billingZip = val.billingAddress.zip.toString();
        }
        this.billingAddressStatus = this.checkOutform.controls.billingAddress.status;

        if (val.billingAddress.addressOne && val.billingAddress.country && 
            val.billingAddress.city &&  val.billingAddress.state &&
            ((this.billingZip.length === 5) && (val.billingAddress.country === 'US') ||
            ((this.billingZip.length > 0 ) && (val.billingAddress.country !== 'US')))) {
                 btnEnabled = true;
        } else {
                 btnEnabled = false;
        }

        if (val.billingAddress.isShippingSame !== '') {
          if (this.sameAddress !== val.billingAddress.isShippingSame) {
            this.verificationCompleted = false;
            this.sameAddress = val.billingAddress.isShippingSame;
            if (!this.sameAddress) {
              this.checkOutform.controls.shippingAddress.get('shippingAddressOne').reset();
              this.checkOutform.controls.shippingAddress.get('shippingAddressTwo').reset();
              this.checkOutform.controls.shippingAddress.get('shippingCity').reset();
              this.checkOutform.controls.shippingAddress.get('shippingCountry').reset();
              this.checkOutform.controls.shippingAddress.get('shippingState').reset();
              this.checkOutform.controls.shippingAddress.get('shippingZip').reset();
              this.shippingAddress = {};
            } 
          }
        }

       if (btnEnabled && !this.sameAddress) {
          this.shippingZip = '';
          if (val.shippingAddress.shippingZip){
              this.shippingZip = val.shippingAddress.shippingZip.toString();
          }
          this.shippingAddressStatus = this.checkOutform.controls.shippingAddress.status;

          if (val.shippingAddress.shippingAddressOne &&
              val.shippingAddress.shippingCountry &&
              val.shippingAddress.shippingCity &&
              val.shippingAddress.shippingState &&
              (((this.shippingZip.length === 5) &&  (val.shippingAddress.shippingCountry === 'US')) ||
               ((this.shippingZip.length > 0) && (val.shippingAddress.shippingCountry !== 'US')))) {
                btnEnabled = true;
          } else {
              btnEnabled = false;
          }
        }

        if (btnEnabled) {
             this.addressVerified = false;
             this.disabled = 'secure-enabled-btn btn-primary';
             this.disableButton = '';
             this.ref.detectChanges();
        } else {
          this.disabled = 'btn-default disable-gray';
          this.disableButton = 'disabled';
          this.ref.detectChanges();
        }
        this.billingAddress = {
          country: val.billingAddress.country,
          state: val.billingAddress.state,
          city: val.billingAddress.city,
          address: val.billingAddress.addressOne,
          suite: val.billingAddress.addressTwo,
          zip: val.billingAddress.zip
        };

        if (this.sameAddress) {
          this.shippingAddress = this.billingAddress;
        }  else {
          this.shippingAddress = {
            country: val.shippingAddress.shippingCountry,
            state: val.shippingAddress.shippingState,
            city: val.shippingAddress.shippingCity,
            address: val.shippingAddress.shippingAddressOne,
            suite: val.shippingAddress.shippingAddressTwo,
            zip: val.shippingAddress.shippingZip
          };
        }
          const applicationId = sessionStorage.getItem('applicationId');
          const userId = sessionStorage.getItem('userId');
          const firstName = sessionStorage.getItem('first_name');
          const lastName = sessionStorage.getItem('last_name');
          this.sa = {
            applicationId: applicationId,
            userId: userId,
            country: this.shippingAddress.country,
            state: this.shippingAddress.state,
            city:  this.shippingAddress.city,
            firstName: firstName,
            lastName: lastName,
            addressOne: this.shippingAddress.address,
            addressTwo: this.shippingAddress.suite,
            zip: this.shippingAddress.zip
          };
        
    });

  };

  public setBillingStateValue = function () {
     this.countryField = this.checkOutform.controls.billingAddress.get('country');
     this.countryField.valueChanges.subscribe((val: string) => {
         this.checkOutform.controls.billingAddress.get('state').reset();
         this.checkOutform.controls.billingAddress.get('zip').reset();

          if ((val) && ((val === 'US') || (val === 'CA'))) {
              this.user.getStates(val).subscribe(cl => {
                   this.States = this.user.getStateList(cl);
                   this.selectBillingMenu = true;
                   if (val === 'CA') {
                      this.isCanadaBilling = true;
                   }
                   this.ref.detectChanges();
             });
          } else {
              this.selectBillingMenu = false;
          }
      });
  };

  private setShippingStateValue = function () {
    this.shippingCountryField = this.checkOutform.controls.shippingAddress.get('shippingCountry');
    this.shippingCountryField.valueChanges.subscribe((val: string) => {
         this.shippingAddressCheckDone = false;
         if ((val) && ((val === 'US') || (val === 'CA'))) {
             this.user.getStates(val).subscribe(cl => {
                  this.States = this.user.getStateList(cl);
                  this.selectShippingMenu = true;
                  if (val === 'CA') {
                     this.isCanadaShipping = true;
                  }
            });
         } else {
             this.selectShippingMenu = false;
             this.checkOutform.controls.shippingAddress.get('shippingState').reset();
         }
     });
 };


  public checkLength = function(len, ele, control) {
    const fieldLength = ele.toString().length;
    if (fieldLength <= len) {
       return true;
    } else {
       if (control === 'zip') {
          let str = ele.toString();
           str = str.substring(0, str.length - 1);
          this.checkOutform.controls.billingAddress.get('zip').setValue(Number(str));
        }
    }
};

  public checkCount = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.billingAddress.zip) {
        this.checkLength(total, val.billingAddress.zip, control);
      }
    });
  };


  goBackSec3ure() {
    if (this.updateAddressFlag) {
      this.route.navigateByUrl('RadiationConfirmation');
    }  else  {
      window.location.href = sessionStorage.getItem('inboundUrl');
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


public checkAddressError = function() {
  let noErrors = true;

  if (this.sameAddress) {
  Object.keys(this.checkOutform.controls.billingAddress.controls).forEach(key => {
    const controlErrors: ValidationErrors = this.checkOutform.controls.billingAddress.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            if (keyError) {
              noErrors = false;
          this.checkOutform.controls.billingAddress.get(key).markAsTouched();
          }
          });
        }
      });
    }  else {
      Object.keys(this.checkOutform.controls.shippingAddress.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.checkOutform.controls.shippingAddress.get(key).errors;
        if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError) {
                  noErrors = false;
              this.checkOutform.controls.shippingAddress.get(key).markAsTouched();
              }
              });
            }
          });
    }

    if (noErrors) {
      if (this.sameAddress) {
         const val = {
                 shippingAddress: {
                        addressOne: this.checkOutform.controls.billingAddress.controls.addressOne.value,
                        addressTwo: this.checkOutform.controls.billingAddress.controls.addressTwo.value,
                        city: this.checkOutform.controls.billingAddress.controls.city.value,
                        state: this.checkOutform.controls.billingAddress.controls.state.value,
                        zip: this.checkOutform.controls.billingAddress.controls.zip.value,
                 }
          };
      }
      this.verifyShippingAddress();
  }
};

  public verifyShippingAddress = function () {
    let val;
    if (this.sameAddress) {
      val = {
        shippingAddress: {
          addressOne: this.checkOutform.controls.billingAddress.controls.addressOne.value,
          addressTwo: this.checkOutform.controls.billingAddress.controls.addressTwo.value,
          city: this.checkOutform.controls.billingAddress.controls.city.value,
          state: this.checkOutform.controls.billingAddress.controls.state.value,
          zip: this.checkOutform.controls.billingAddress.controls.zip.value,
        }
      };
    } else {
      val = {
        shippingAddress: {
          addressOne: this.checkOutform.controls.shippingAddress.controls.shippingAddressOne.value,
          addressTwo: this.checkOutform.controls.shippingAddress.controls.shippingAddressTwo.value,
          city: this.checkOutform.controls.shippingAddress.controls.shippingCity.value,
          state: this.checkOutform.controls.shippingAddress.controls.shippingState.value,
          zip: this.checkOutform.controls.shippingAddress.controls.shippingZip.value,
        }
      };
    }

         const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
         const verifyAddress = this.http.post(`${this.url}/commerce/Checkout/verifyAddress`, 
                `token=${this.pageToken}&address1=${val.shippingAddress.addressOne}&address2=${val.shippingAddress.addressTwo}` + 
                `&city=${val.shippingAddress.city}&state=${val.shippingAddress.state}&zip=${val.shippingAddress.zip}`,
         { headers: header }).subscribe(address => {
         if (address.Address.Address2) {
            if ((address.Address.Address1) && (address.Address.Address1 !== 'NULL')) {
                this.addressOne = address.Address.Address1;
            }
            this.addressTwo = address.Address.Address2;
            this.city = address.Address.City;
            this.country = val.shippingAddress.country;
            this.state = address.Address.State;
            this.zip5 = address.Address.Zip5;
            if (address.Address.Zip3) {
               this.zip3 = address.Address.Zip3;
            }
            if (address.Address.Zip4) {
               this.zip4 = address.Address.Zip4;
            }
            if (this.shippingAddress) {
                   this.modalRef = this.modalService.open(this.content);
                   this.modalRef.result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                   }, (reason) => {
                      this.verificationCompleted = true;
                      this.ref.detectChanges();
                      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
          }
        } else {
           if (!address.Address.Valid) {
                  this.modalRef = this.modalService.open(this.contentWarning);
                  this.modalRef.result.then((result) => {
                      this.closeResult = `Closed with: ${result}`;
                      this.verificationCompleted = true;
                      this.ref.detectChanges();
                  }, (reason) => {
                      this.verificationCompleted = true;
                      this.ref.detectChanges();
                      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
           }
        }
      }, err => {
        console.log(err);
      });
  };

  public modifyAddress() {
    if (this.addressVerify.controls.chooseAddress.value === 'recommended') {
      if (this.addressOne === '' || this.addressOne === undefined) {
       this.addressOne = ' ';
      }
      if (this.sameAddress) {
        this.checkOutform.controls.billingAddress.setValue({
             addressOne: this.addressTwo,
             addressTwo: this.addressOne,
             country: this.billingAddress.country,
             city: this.city,
             state: this.state,
             zip: this.zip5,
             isShippingSame: true
         }, { emitEvent: false });
         this.billingAddress = {
          country: this.billingAddress.country,
          state: this.state,
          city: this.city,
          address: this.addressTwo,
          suite: this.addressOne,
          zip: this.zip5
        };
        this.shippingAddress = this.billingAddress;
      } else {
        this.checkOutform.controls.shippingAddress.setValue({
             shippingAddressOne: this.addressTwo,
             shippingAddressTwo: this.addressOne,
             shippingCountry: this.shippingAddress.country,
             shippingCity: this.city,
             shippingState: this.state,
             shippingZip: this.zip5,
        }, { emitEvent: false });
        this.shippingAddress = {
          country: this.shippingAddress.country,
          state: this.state,
          city: this.city,
          address: this.addressTwo,
          suite: this.addressOne,
          zip: this.zip5
        };
      }
      /*
      const applicationId = sessionStorage.getItem('applicationId');
          const userId = sessionStorage.getItem('userId');
          const firstName = sessionStorage.getItem('first_name');
          const lastName = sessionStorage.getItem('last_name');
          this.sa = {
            applicationId: applicationId,
            userId: userId,
            country: this.shippingAddress.country,
            state: this.shippingAddress.state,
            city: this.shippingAddress.city,
            firstName: firstName,
            lastName: lastName,
            addressOne: this.shippingAddress.address,
            addressTwo: this.shippingAddress.suite,
            zip: this.shippingAddress.zip
          };
          */
       this.modalRef.close();
   } else {
     this.modalRef.close();
   }
          const applicationId = sessionStorage.getItem('applicationId');
          const userId = sessionStorage.getItem('userId');
          const firstName = sessionStorage.getItem('first_name');
          const lastName = sessionStorage.getItem('last_name');
          this.sa = {
            applicationId: applicationId,
            userId: userId,
            country: this.shippingAddress.country,
            state: this.shippingAddress.state,
            city: this.shippingAddress.city,
            firstName: firstName,
            lastName: lastName,
            addressOne: this.shippingAddress.address,
            addressTwo: this.shippingAddress.suite,
            zip: this.shippingAddress.zip
          };
   this.verificationCompleted = true;
 }
}
