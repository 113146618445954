import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../spinner/spinner.service';
import { ModelService } from '../model.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { exists } from 'fs';

@Component({
  selector: 'app-tech-bundle-membership-confirm',
  templateUrl: './tech-bundle-membership-confirm.component.html',
  styleUrls: ['./tech-bundle-membership-confirm.component.scss']
})



export class TechBundleMembershipConfirmComponent implements OnInit {



  public maskedCardNum: any;
  public pageToken: any;
  public localHost = environment.serverUrl;
  public cardType: any;
  public productPrice: any;
  public header: any;
  public errorMsg: any;
  public billingAddressOne: string;
  public billingAddressTwo: string;
  public billingCity: string;
  public billingState: string;
  public billingCountry: string;
  public billingZip: string;
  public shippingAddressOne: string;
  public shippingAddressTwo: string;
  public shippingCity: string;
  public shippingState: string;
  public shippingCountry: string;
  public shippingZip: string;
  public total: number;
  public serviceLevel: any;
  public radiationOptIn = false;
  public billingAddressTwoFlag = false;
  public shippingAddressTwoFlag = false;
  public renewal_id = null;

  constructor(private http: HttpClient,
    private route: Router,
    private model: ModelService,
    private spinner: SpinnerService) { 
    this.pageToken = sessionStorage.getItem('token');
    this.renewal_id = sessionStorage.getItem('renewal_id');
    this.spinner.checkHttpPending(true);
    this.header = {'Content-Type': 'application/x-www-form-urlencoded'};
    const payRefId = sessionStorage.getItem('payRefId');
    this.spinner.checkHttpPending(true);
    const params = `token=${this.pageToken}&payRefId=${payRefId}`;
    this.http.post(`${this.localHost}/commerce/Checkout/getPaymentMethodData_post`,
                     params, { headers: this.header }).subscribe((data: any) => {
           this.cardType = data.result.CreditCardType;
           this.maskedCardNum = data.result.CreditCardMaskNumber;
           this.spinner.checkHttpPending(false);
     });

  }

  ngOnInit() {
    const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
    this.billingAddressOne = this.model.submitData.billing.address;
    this.billingAddressTwo = this.model.submitData.billing.suite;
    this.billingCity =  this.model.submitData.billing.city;
    this.billingState = this.model.submitData.billing.state;
    this.billingZip = this.model.submitData.billing.zip;
    this.billingCountry = this.model.submitData.billing.country;
    this.billingAddressTwoFlag = this.billingAddressTwo != '' && this.billingAddressTwo != ' ';
    const  shippingData =  JSON.parse(sessionStorage.getItem('shippingAddress'));
    this.shippingAddressOne = shippingData.address;
    this.shippingAddressTwo = shippingData.suite;
    this.shippingCity =  shippingData.city;
    this.shippingState = shippingData.state;
    this.shippingZip = shippingData.zip;
    this.shippingCountry = shippingData.country;
    this.shippingAddressTwoFlag = this.shippingAddressTwo != '' && this.shippingAddressTwo != ' ';
    const userId = sessionStorage.getItem('userId');
    const applicationId = sessionStorage.getItem('applicationId');

    const postData = `token=${this.pageToken}&userId=${userId}&applicationId=${applicationId}` + 
                     `&state=${this.billingState}&address1=${this.billingAddressOne}` +
                     `&address2=${this.billingAddressTwo}&city=${this.billingCity}` + `&state=${this.billingState}` +
                     `&zip=${this.billingZip}&country=${this.billingCountry}`;
    this.http.post(`${this.localHost}/commerce/Checkout/setUserAddressInCart`,
                     postData, { headers: headerPayment }).subscribe((data: any) => {
                     const user_fname = sessionStorage.getItem('first_name');
                     const user_lname = sessionStorage.getItem('last_name');
                     this.http.post(`${this.localHost}/commerce/checkout/saveContactDataGetTax`,
                     'user_fname=' + user_fname + '&user_lname=' + user_lname +
                     '&user_address1=' + this.billingAddressOne + '&user_address2=' + this.billingAddressTwo +
                     '&user_city=' + this.billingCity + '&user_state=' + this.billingState + '&user_zip=' + this.billingZip +
                     '&user_country=' + this.billingCountry + '&token=' + 
                     this.pageToken, {headers: headerPayment}).subscribe((result: any) => {
                         this.total = result.totalAmount;
                         this.spinner.checkHttpPending(false);
                     });
   });

  }
  goBackToParentPortal() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }

  confirmSelection() {
    const shippingAddress = sessionStorage.getItem('shippingAddress');
    const billingAddress = sessionStorage.getItem('billingAddress');
    const payRefId = sessionStorage.getItem('payRefId');

    this.spinner.checkHttpPending(true);
    let extra = '';
      if(this.renewal_id) {
        extra = `&renewal_id=${this.renewal_id}`;
      }
    const params = `token=${this.pageToken}&shippingAddress=${shippingAddress}` +
                   `&billingAddress=${billingAddress}&paymentMethodId=${payRefId}&autoRenew=${this.radiationOptIn}${extra}`;
    this.http.post(`${this.localHost}/commerce/Checkout/createSubscription_post`,
                     params, { headers: this.header }).subscribe((data: any) => {

                      if (data.subscriptionStatus === 'Success') {
                        window.location.href = sessionStorage.getItem('passportUrl');
                      }  else {
                        this.errorMsg = 'Error: ' + data.reason;
                        this.spinner.checkHttpPending(false);
                      }
     });
  }

  goWorkAddress() {
    sessionStorage.setItem('updateAddressFlag', JSON.stringify(true));
    this.route.navigateByUrl('ShippingAddress');
  }

  selectRadiationMonitoring(){
    this.radiationOptIn = true;
  }

  deselectRadiationMonitoring(){
    this.radiationOptIn = false;
  }

  goCredCardRegistration() {
      sessionStorage.setItem('editPaymentFlag', JSON.stringify(true));
      this.route.navigateByUrl('RadiationPayment');
  }

}
