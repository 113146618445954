import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-ecommerce-small-panel',
  templateUrl: './ecommerce-small-panel.component.html',
  styleUrls: ['./ecommerce-small-panel.component.scss']
})
export class EcommerceSmallPanelComponent implements OnInit {
  public clinicServices = {
    serviceAdded : false
  };
  @Output() addcart = new EventEmitter<boolean>();
constructor(private session: SessionStorageService) {
}
ngOnInit() {
}
  public addService() {
    this.addcart.emit(this.clinicServices.serviceAdded);
  }

}
