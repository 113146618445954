import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderSummaryService } from '../order-summary.service';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { CalculateTotalService } from '../calculate-total.service';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
interface User {
  first_name: string;
  last_name: string;
  email: string;
  coupon_enabled: string;
  inbound_base_url: string;
  campaignId: string;
  campaignToken: string;
  campaignProduct: string;
  tdapOnFile: string;
}


@Component({
  selector: 'app-renewal-redirect',
  templateUrl: './renewal-redirect.component.html',
  styleUrls: ['./renewal-redirect.component.scss']
})

export class RenewalRedirectComponent implements OnInit {
  public product:  Object;
  public localHost = environment.serverUrl;
  public pageToken: any;
  public firstName: string;
  public lastName: string;
  public currentMembership: any;
  public renewMembership: any;
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public passportName: string;
  public token = 'token';
  public renewPackage: any;
  public currentPackage: any;
  public renewal_id: any;
  public includes: any[] = [];
  public oldIncludes: any[] = [];
  public policy: string[] = [];
  public oldPolicy: string[] = [];
  public passportPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public isCouponEnabled;
  public itemPurchased = false;
  public campRenewFlag = false;
  public evergreenEnabled = false;


  public basicIncludes: any = [];
  public basicIncludesArray: any = [];
  public upgradeData: any;

  constructor(private order: OrderSummaryService,
    private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private spinner: SpinnerService,
    private calculate: CalculateTotalService,
    private userInfo: UserService) {
  }


  ngOnInit() {
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.evergreenEnabled = JSON.parse(sessionStorage.getItem('evergreenEnabled'));
    this.http.post(`${this.localHost}/commerce/products/countrylist_get`, `token=${this.pageToken}`,
    {headers: header} )
    .subscribe(cl => {
    sessionStorage.setItem('countries', JSON.stringify(cl) );
  });
    const includes = [];
    const includesArray = [];
    let finalIncluds = '';
    const policy = [];
    const pushedInclude = [];
    let initPrice = null;
    let renewPackage = true;
    let currentPackage = true;
    let passportId = '';
      if (sessionStorage.getItem('token')) {
        this.renewMembership = JSON.parse(sessionStorage.getItem('renewMemberships'));
          this.renewMembership.filter(function(val) {
            policy.push(JSON.parse(val.detailed_description));
          });
          this.policy = policy;
          this.policy.filter(function(val, index, all) {
            includes.push(all[index][1]);
          });
          includes.filter(function(valu, inde, a) {
            includesArray.push(a[inde]);
          });

          includesArray.filter(function(v, ins, al) {
            v.filter(function(va, inde, a) {
              if (va[inde + 1] === undefined) {
                _.forEach(va, function(value) {
                  if (value instanceof Object) {
                    if (value.length > 1) {
                      finalIncluds += `<ul>`;
                      _.forEach(value, function(sub) {
                        for (const key in sub) {
                          if (sub) {
                            let vSub = '';
                            vSub = sub[key];
                            if (vSub === undefined) {
                              finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                            aria-hidden="true"></i></div><div class="font-size-20">${vSub}</div></div>`;
                            } else {
                              finalIncluds += `<li>${vSub}</li>`;
                            }
                          }
                        }
                      });
                      finalIncluds += `</ul>`;
                    }
                    for (const key in value) {
                      if (value.length === undefined) {
                        if (value[key] !== undefined) {
                          const tot = value[key];
                          finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${value[key]}</div></div>`;
                        }
                      }
                    }
                  }
                });
              } else {
                finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${va[inde + 1]}</div></div>`;
              }
            });
            pushedInclude.push(finalIncluds);
            finalIncluds = '';
          });

          this.renewMembership.filter(function(val) {
            if (val.rate_plan.sumPrice !== 'FREE') {
             val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
            }
            if (val.Name === 'SEC3URE Passport') {
                currentPackage =  val.current_plan ? true : false;
                renewPackage = val.is_renewable ? true : false;
                initPrice = Number(val.rate_plan[0].sumPrice);
                passportId = val.Id;
                sessionStorage.setItem('passportId', val.Id);
            }
            val.detailed_description = pushedInclude;
          });
          this.passportPrice = initPrice;
          sessionStorage.setItem('currentPackage', JSON.stringify(currentPackage));
          sessionStorage.setItem('renewPackage', JSON.stringify(renewPackage));
          /*const isOldPackage = JSON.parse(sessionStorage.getItem('isOldPackage'));
           if (isOldPackage) {
              this.processOldPackage();
           }*/
           // this.currentMembership.push(this.renewMembership[0]);

          const campaignId = sessionStorage.getItem('campaignId');
          const campaignToken = sessionStorage.getItem('campaignToken');
          const campaignProduct = JSON.parse(sessionStorage.getItem('campaignProduct'));

          if ((campaignId) && (campaignToken) && (renewPackage)) {
              this.campRenewFlag = true;
          }
          setTimeout(() => {
            this.purchaseStatus();
          }, 0);
          this.goToAddOns(passportId);
          this.spinner.checkHttpPending(false);
      } else {
        // redirect user in navigated to without valid token
      }
    sessionStorage.setItem('itemsSelected', JSON.stringify(false));
    // sessionStorage.clear();
    this.model.setModelData('', '', '');
    this.model.shippableProduct = false;

  }

   private processOldPackage = function () {
    const oldIncludes = [];
    const oldIncludesArray = [];
    let   oldFinalIncluds = '';
    const oldPolicy = [];
    const oldPushedInclude = [];

    this.renewMembership.filter(function(val) {
      const renewData = val.detailed_description;
       oldPolicy.push(renewData);
    });

    this.oldPolicy = oldPolicy;
    this.oldPolicy.filter(function(val, index, all) {
       oldIncludes.push(all[index][1]);
    });
    oldIncludes.filter(function(valu, inde, a) {
      oldIncludesArray.push(a[inde]);
    });

    oldIncludesArray.filter(function(v, ins, al) {
      v.filter(function(va, inde, a) {
        if (va[inde + 1] === undefined) {
          _.forEach(va, function(value) {
            if (value instanceof Object) {
              if (value.length > 1) {
                oldFinalIncluds += `<ul>`;
                _.forEach(value, function(sub) {
                  for (const key in sub) {
                    if (sub) {
                      let vSub = '';
                      vSub = sub[key];
                      if (vSub === undefined) {
                        oldFinalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                      aria-hidden="true"></i></div><div class="font-size-20">${vSub}</div></div>`;
                      } else {
                        oldFinalIncluds += `<li>${vSub}</li>`;
                      }
                    }
                  }
                });
                oldFinalIncluds += `</ul>`;
              }
              for (const key in value) {
                if (value.length === undefined) {
                  if (value[key] !== undefined) {
                    const tot = value[key];
                    oldFinalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
          aria-hidden="true"></i></div><div class="font-size-20">${value[key]}</div></div>`;
                  }
                }
              }
            }
          });
        } else {
          oldFinalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
          aria-hidden="true"></i></div><div class="font-size-20">${va[inde + 1]}</div></div>`;
        }
      });
      oldPushedInclude.push(oldFinalIncluds);
      oldFinalIncluds = '';
    });

    this.renewMembership.filter(function(val) {
      if (val.rate_plan.sumPrice !== 'FREE') {
       val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
      }
      val.detailed_description = oldPushedInclude;
    });
  };

  public purchaseStatus = function() {
      const renewMember = this.renewMembership[0];

      if ((renewMember.is_renewable) || (renewMember.valid_status === 'expired')) {
        const  elementFound: HTMLElement = document.getElementById('addPackage') as HTMLElement;
         elementFound.click();
         }
  };

  private addPackage = function(id: any) {

    this.order.setPackagePlanType('', this.passportPrice, '');
    sessionStorage.setItem('renewPackage', JSON.stringify(true));
    sessionStorage.setItem('isPurchased', JSON.stringify(true));
    const IsCoupon = sessionStorage.getItem('isCouponEnabled');
    sessionStorage.setItem('coupon_enabled', IsCoupon);

    this.itemPurchased = true;
    sessionStorage.setItem('renewPackage', JSON.stringify(true));
    const serviceLevel = this.renewMembership[0].rate_plan[0].serviceLevel;
    this.calculate.calculateTotal(serviceLevel,
      this.model.model, this.model.items, this.model.coupons).subscribe(d => {
        this.total = d;
        this.calculate.setTotalValue(d);
        this.spinner.checkHttpPending(false);
        sessionStorage.setItem('total', JSON.stringify({total: d}));
      });
  };

  private removePackage = function(id: any) {
    this.itemPurchased = false;
    this.order.setPackagePlanType('', 0, 'remove');
    sessionStorage.setItem('renewPackage', JSON.stringify(false));
    this.spinner.checkHttpPending(true);
    this.calculate.calculateTotal(0,
      this.model.model, this.model.items, this.model.coupons).subscribe(d => {
        this.total = d;
        this.calculate.setTotalValue(d);
        this.spinner.checkHttpPending(false);
        sessionStorage.setItem('total', JSON.stringify({total: d}));
      });
  };

  private goToAddOns = function(id: any) {
    if (id) {
      this.spinner.checkHttpPending(true);
      sessionStorage.setItem('passport', this.passportPrice);
          const serviceLevel = this.renewMembership[0].rate_plan[0].serviceLevel;
          this.calculate.calculateTotal(serviceLevel,
            this.model.model, this.model.items, this.model.coupons).subscribe(d => {
              this.total = d;
              this.calculate.setTotalValue(d);
              sessionStorage.setItem('total', JSON.stringify({total: d}));
            });
    }
  };
}
