import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';

@Component({
  selector: 'app-secure-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
  providers: []
})

export class MembershipComponent implements OnInit {
  public localHost = environment.serverUrl;
  public pageToken: any;
  public firstName: string;
  public lastName: string;
  public membership: any[] = [];
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public productName: string;
  public token = 'token';
  public includes: any[] = [];
  public policy: string[] = [];
  public productPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public packageInfo: any;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public isCouponEnabled;
  public userId: any;

  public basicIncludes: any = [];
  public basicIncludesArray: any = [];

  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private spinner: SpinnerService,
    private userInfo: UserService) {
    const includes = [];
    const includesArray = [];
    let finalIncluds = '';
    const policy = [];
    const membership = [];
    const pushedInclude = [];
    let initPrice = null;
    let productName = '';
    let passportId = '';
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    router.queryParams.subscribe(token => {
      this.spinner.checkHttpPending(true);
      this.pageToken = token['token'];
      if (token['token']) {
        const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${token['token']}`, { headers: header });
        packages.subscribe((res: any) => {
          this.membership = res;
          this.packageInfo = sessionStorage.setItem('packageInfo', JSON.stringify(res));
          res.filter(function(val) {
            policy.push(JSON.parse(val.detailed_description));
          });
          this.policy = policy;
          this.policy.filter(function(val, index, all) {
            includes.push(all[index][1]);
          });
          includes.filter(function(valu, inde, a) {
            includesArray.push(a[inde]);
          });

          includesArray.filter(function(v, ins, al) {
            v.filter(function(va, inde, a) {
              if (va[inde + 1] === undefined) {
                _.forEach(va, function(value) {
                  if (value instanceof Object) {
                    if (value.length > 1) {
                      finalIncluds += `<ul>`;
                      _.forEach(value, function(sub) {
                        for (const key in sub) {
                          if (sub) {
                            let vSub = '';
                            vSub = sub[key];
                            if (vSub === undefined) {
                              finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                            aria-hidden="true"></i></div><div class="font-size-20">${vSub}</div></div>`;
                            } else {
                              finalIncluds += `<li>${vSub}</li>`;
                            }
                          }
                        }
                      });
                      finalIncluds += `</ul>`;
                    }
                    for (const key in value) {
                      if (value.length === undefined) {
                        if (value[key] !== undefined) {
                          const tot = value[key];
                          finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${value[key]}</div></div>`;
                        }
                      }
                    }
                  }
                });
              } else {
                finalIncluds += `<div class="flex margin-bottom-10"><div><i class="fa fa-check-square-o secure-check"
                aria-hidden="true"></i></div><div class="font-size-20">${va[inde + 1]}</div></div>`;
              }
            });
            pushedInclude.push(finalIncluds);
            finalIncluds = '';
          });

          this.membership.filter(function(val) {
            if (val.rate_plan.sumPrice !== 'FREE') {
              val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
            }
            if (val.Name === 'SEC3URE Passport') {
              initPrice = Number(val.rate_plan[0].sumPrice);
              productName = val.Name;
              passportId = val.Id;
            } /*else {
              initPrice = Number(val.rate_plan[0].sumPrice);
              productName = val.Name;
            }*/
            val.detailed_description = pushedInclude;
          });
          this.productPrice = initPrice;
          this.productName = productName;
          this.http.post(`${this.localHost}/commerce/products/userData_get`, `token=${token['token']}`, { headers: header })
            .subscribe((result: User) => {
              this.userNames = { firstName: result.first_name, lastName: result.last_name, email: result.email, userId: result.userId};
              this.userInfo.getUserNames(this.userNames);
              this.isCouponEnabled = result.coupon_enabled === '1';
              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              sessionStorage.setItem('user', JSON.stringify({ firstName: result.first_name, lastName: result.last_name, email: result.email,userId: result.userId }));
              sessionStorage.setItem('campaignId', result.campaignId);
              sessionStorage.setItem('campaignToken', result.campaignToken);
              sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));
              sessionStorage.setItem('tdapOnFile', JSON.stringify(result.tdapOnFile['tdapOnFile']));
            });

          this.spinner.checkHttpPending(false);
        });
      } else {
        // redirect user in navigated to without valid token
      }
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  ngOnInit() {
    sessionStorage.clear();
    sessionStorage.setItem('itemsSelected', JSON.stringify(false));    
    sessionStorage.setItem(this.token, this.pageToken);
    this.model.setModelData('', '', '');
    this.model.shippableProduct = false;
  }

  goToPurchaseHistory() {
    location.href = sessionStorage.getItem('inboundUrl');
  }

  private goToAddOns = function(id: any, serviceLevel: any) {
    const campaignId = sessionStorage.getItem ('campaignId');
    const campaignToken = sessionStorage.getItem('campaignToken');
    const campaignProduct = JSON.parse(sessionStorage.getItem('campaignProduct'));
    if (id) {
      sessionStorage.setItem('serviceLevel', serviceLevel);
      this.spinner.checkHttpPending(true);
      const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
      this.http.post(`${this.localHost}/commerce/products/addonList_get`,
        `token=${this.pageToken}&package_id=${id}&service_level=${serviceLevel}`, { headers: header })
        .subscribe(result => {

          sessionStorage.setItem(this.modelData, JSON.stringify(result));
          sessionStorage.setItem('user', JSON.stringify({ firstName: this.userNames.firstName, lastName: this.userNames.lastName, email: this.userNames.email, userId: this.userNames.userId }));
          sessionStorage.setItem('product', JSON.stringify({ price: this.productPrice, name: this.productName }));
          sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled));
          sessionStorage.setItem('isPurchased', JSON.stringify({ isPurchased: true }));
        }, err => {
          console.log(err);
        }, () => {
          this.spinner.checkHttpPending(false);
          this.route.navigateByUrl('Customize');
        });
        if ((campaignId) && (campaignToken)) {
          const httpHeader = { 'Content-Type': 'application/x-www-form-urlencoded' };
          const current_token = sessionStorage.getItem ('token');
          this.spinner.checkHttpPending(true);
          this.http.post(`${this.localHost}/commerce/checkout/campaign_data_tracking`,
          `token=${current_token}&step=3`, { headers: httpHeader })
           .subscribe(result => {
           }, err => {
               console.log(err);
           }, () => {
              this.spinner.checkHttpPending(false);
              
           });
        }
    }
  };
}
