import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { OrderSummaryService } from '../order-summary.service';
import { ModelService } from '../model.service';
import { ProductSelectionService } from '../product-selection.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalculateTotalService } from '../calculate-total.service';
import { CapitalizePipe } from '../pipes/capitalization-pipe';
import { ReplaceDashPipe } from '../pipes/replace-pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { of } from 'rxjs/observable/of';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { map, filter } from 'rxjs/operators';
import { SessionStorageService } from 'angular-web-storage';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-clinic-services',
  templateUrl: './clinic-services.component.html',
  styleUrls: ['./clinic-services.component.scss'],
})
export class ClinicServicesComponent implements OnInit, AfterViewInit {
  public id: number;
  public productServices: Object;
  public userNames: any;
  public addOnFilters: Object;
  public modalOption: NgbModalOptions = {};
  public checkForDuplicates: string[] = [];
  public filters: string[] = [];
  public closeResult: string;
  private countForDelete = 0;
  public service: boolean;
  public routeFlow = '';
  public number: number;
  public numberVal = 0;
  public Numbers: Number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public numberValue: Number = 1;
  public form: Object[] = [];
  public arrayOfUsers: string[] = [];
  public AddOnForm: FormGroup;
  public addForm: any[] = [];
  public organizedArray: any[] = [];
  public totalValue = new Subject<any>();
  public updateFormPrice: number;
  public addonIdArray: any[] = [];
  public itemsDetailsArray: any[] = [];
  public userToken: any;
  public saveAddonDetails: any[] = [];
  public total: number;
  public pageToken: any;
  public subscribeToProductsUpgrade: Subscription;
  private modalRef: NgbModalRef;
  public dataExist: boolean;
  public packageInfo: any;
  public serviceAdded: boolean;
  public showInitNumber = true;
  public quanityUpdate: any[] = [];
  private itemDetailsCount = 0;
  public  showWarning = false;
  public tdapOnFile = '';


  @Output() checkStatusOutput = new EventEmitter<any>();

  constructor(private order: OrderSummaryService,
      private session: SessionStorageService,
      private productionSelection: ProductSelectionService,
      private modalService: NgbModal,
      private model: ModelService,
      private calculate: CalculateTotalService,
      private route: Router,
      public activeModal: NgbActiveModal,
      public spinner: SpinnerService) {
        this.AddOnForm = new FormGroup({
          numberSelect: new FormControl()
        });
        this.pageToken = sessionStorage.getItem('token');
        if ((this.route.url === `/Upgrade?token=${this.pageToken}`) || 
            (this.route.url === `/EvergreenUpgrade?token=${this.pageToken}`))  {
          let gotProducts = true;
          this.subscribeToProductsUpgrade = this.model.modelClinic.subscribe((p) => {
            if (p) {
              this.productServices = p;
              this.filterContent(this.productServices);
            } else {
              gotProducts = false;
            }
          });
          if (gotProducts) {
            this.productServices = JSON.parse(sessionStorage.getItem('modelData'));
          }
        } else {
          this.productServices = JSON.parse(sessionStorage.getItem('modelData'));
        }
      this.productServices = JSON.parse(sessionStorage.getItem('modelData'));
      this.filterContent(this.productServices);
      }
  ngOnInit() {
    this.routeFlow = this.route.url;
    this.packageInfo = JSON.parse(sessionStorage.getItem('packageInfo'));
    this.userNames = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {};
    this.userToken = sessionStorage.getItem('token');
    this.tdapOnFile = sessionStorage.getItem('tdapOnFile');
    if (JSON.parse(sessionStorage.getItem('itemsSelected')) === true) {
      this.route.navigate(['Membership'],
    { queryParams: {
      token: this.userToken
    }});
    }

    this.addForm = [
      { user: {
      firstName: this.userNames.firstName,
      lastName: this.userNames.lastName,
      email: this.userNames.email
    }}];

    if ((this.routeFlow.includes('/Renewal')) || (this.routeFlow.includes('/Upgrade')) ||
        (this.routeFlow.includes('/EvergreenUpgrade'))){
      this.purchaseStatus();
    }
  }

  public hideInitNumber() {
    this.showInitNumber = false;
  }

  public open = function(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  };
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  public openMinuteClinic = function(content, price, id) {

    this.AddOnForm.controls['numberSelect'].setValue(this.numberValue, {onlySelf: true});
    this.modalRef =  this.modalService.open(content, { size: 'lg', keyboard: false, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.resetAddUserForm();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  };

  public closeMinuteClinic() {
    this.modalRef.close();
  }
  private getDismissReasonMin(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  public resetAddUserForm = () => {
    this.AddOnForm.controls['numberSelect'].reset();
    this.numberValue = 1;
  }
  public addtocart = function(serviceAdded) {
    serviceAdded ? this.serviceAdded = false : this.serviceAdded = true;
  };
  private hasServiceLevelPurchased = function(serviceLevel) {
    let isPurchased = false;
    this.packageInfo.filter(function(val) {
      if (val.rate_plan[0].serviceLevel === serviceLevel && val.is_purchased) {
        isPurchased = true;
      }
    });
    return isPurchased;
  };
  public addAddon = function(addon: string, id: number, price:  number, content) {
    this.showWarning = false;
    this.checkStatusOutput.emit(true);
    this.dataExist = false;
    let updateQuanity = null;
    sessionStorage.setItem('itemsSelected', JSON.stringify(true));
    this.productionSelection.selectProduct(this.productServices[id], 'add');
    if (this.productServices[id].charges[0].ProductType__c === 'MinuteClinic') {
      if (this.addForm.length === 0) {
        this.addForm = [
          { user: {
          firstName: this.userNames.firstName,
          lastName: this.userNames.lastName,
          email: this.userNames.email
        }}];
      }
      if (this.productServices[id].charges[0].Entitlement__c === 'Td Booster (MinuteClinic)') {
        for (const index in this.productServices) {
          if ((this.productServices[index].charges[0].Entitlement__c === 'Tdap Vaccine (MinuteClinic)') &&
              (this.productServices[index].cartSelected === false) && 
              ((!this.productServices[index].hasOwnProperty('purchasedStatus')) || 
              (this.productServices[index].purchasedStatus === false)) &&
              (this.tdapOnFile === 'false') && (this.productServices[index].valid_status !== 'valid') && 
              (this.productServices[index].valid_status !== 'renewable')) {

              this.showWarning = true;
              setTimeout(function() {
                this.showWarning = false;
            }.bind(this), 9000);
            }
        }
      }
      this.resetAddUserForm();
      this.checkForms('');
      this.updateFormPrice = price;
      this.openMinuteClinic(content,'', this.productServices[id].Id);
    } else {
      this.spinner.checkHttpPending(true);
      this.order.setAddOnType(addon, price);
      this.productServices[id].highLight = 'secure-highlight-border';
      this.productServices[id].cartSelected = true;
      this.saveAddonDetails.filter(function(val, ind, a) {
        updateQuanity = val.index;
      });
      const serviceLevel = this.hasServiceLevelPurchased(this.productServices[id].serviceLevel) ? 0 : this.productServices[id].serviceLevel;
      this.model.setModelData(this.productServices[id].Id,  '');
      this.calculate.calculateTotal(serviceLevel,
         this.model.model, this.model.items, this.model.coupons)
      .subscribe(data => {
        this.total = data;
        this.calculate.setTotalValue(data);
        this.model.isShippable(data.cart);
        this.spinner.checkHttpPending(false);
        sessionStorage.setItem('total', JSON.stringify({total: data}));
      });
    }
  };
  public deleteAll = function(i, addon, addonId) {
    const tobeDeleted = [];
    this.countForDelete = 0;
    this.productServices[i].cartSelected = false;
    this.total = null;
    this.selectedIndex = '';
    this.order.setAddOnType(addon, 0, 'remove');
    this.productionSelection.selectProduct(this.productServices[i], 'remove');
    this.productServices[i].highLight = '';
    this.model.modelForUI.length = 0;
    _.remove(this.model.model, function(n) {
        return n === addonId;
    });
    _.remove(this.model.items.Id, function(n) {
        return n === addonId;
    });
     _.remove(this.model.modelForUI.Id, function(n) {
        return n === addonId;
    });

    this.cleanItemContext(addonId);


    this.spinner.checkHttpPending(true);
    const serviceLevel = this.hasServiceLevelPurchased(this.productServices[i].serviceLevel) ? 0 : this.productServices[i].serviceLevel;
    this.calculate.calculateTotal(serviceLevel,
       this.model.model, this.model.items, this.model.coupons).
    subscribe(data => {
      this.total = data;
      this.calculate.setTotalValue(data);
      this.spinner.checkHttpPending(false);
      this.saveAddonDetails = [];
      this.model.isShippable(data.cart);
      this.resetAddUserForm();
      this.checkForms('');
      this.closeMinuteClinic();
      sessionStorage.setItem('total', JSON.stringify({total: data}));
    });
  };
  public confirmDelete = function() {
    this.countForDelete = 0;
    this.closeMinuteClinic();
  };
  public removeAddon = function(addon: string, index: number, id, content): void {
    let updateQuan = null;
    this.productionSelection.selectProduct(this.productServices[index], 'remove');
    if (this.productServices[index].charges[0].ProductType__c === 'MinuteClinic') {
      if (this.saveAddonDetails) {
        this.dataExist = true;
        this.countForDelete = 0;
        this.addForm = [];

        for (let i = 0; i < this.model.items.length; i++) {
          if (this.model.items[i].Id === id) {
            const itemJson = JSON.parse(this.model.items[i].itemDetailArray.substring(1));
             for (let j = 0; j < itemJson.quantity; j++) {
                this.addForm.push({ user: {
                                    firstName: itemJson['people'][j].first_name,
                                    lastName: itemJson['people'][j].last_name,
                                    email: itemJson['people'][j].email
                }});
                this.countForDelete++;
             }
            this.numberValue = itemJson.quantity;;
          }
        }
        this.updateFormPrice = this.productServices[index].charges[0].price * this.countForDelete;
      }
      this.openMinuteClinic(content, '', id);
    } else {
    this.total = null;
    this.selectedIndex = '';
    this.order.setAddOnType(addon, 0, 'remove');
    this.productServices[index].highLight = '';
    this.productServices[index].cartSelected = false;
    this.model.model.filter(function(val, i, a) {
      if (val === id) {
         a.splice(i, 1);
         updateQuan = i;
        }
    });
    this.model.items.filter(function(val, inde, all) {
      all.splice(updateQuan, 1);
    });
    const serviceLevel =
    this.hasServiceLevelPurchased(this.productServices[index].serviceLevel) ? 0 : this.productServices[index].serviceLevel;
    this.spinner.checkHttpPending(true);
    this.calculate.calculateTotal(serviceLevel,
      this.model.model, this.model.items, this.model.coupons).
    subscribe(data => {
      this.total = data;
      this.calculate.setTotalValue(data);
      this.model.isShippable(data.cart);
      this.spinner.checkHttpPending(false);
      sessionStorage.setItem('total', JSON.stringify({total: data}));
    });
  }
  };
  public filterContent = function(results): void {
    this.addOnFilters = [];
    this.checkForDuplicates = [];
    this.filters = [];
    for (const data in results) {
      if (results) {
        if (results[data].Name === 'Criminal Background Check') {
          results.splice(data, 1);
        }
        if(this.productServices[data]) {
          this.productServices[data].cartSelected = false;
        this.productServices[data].showFilter = false;
        this.checkForDuplicates.push(results[data].filterCategory);
        this.addOnFilters = Array.from(new Set(this.checkForDuplicates));
        }
      }
    }
  for (const ind in results) {
    if (results) {
      if (results[ind].Name === 'Radiation Exposure Package') {
        results.splice(ind, 1);
      }
    }
  }
  for (const filt in this.addOnFilters) {
    if (this.addOnFilters) {
      this.filters.push({filter: this.addOnFilters[filt]});
    }
  }
};
public checkForms = function(price) {
  this.addForm.splice(1);
  let newerValue = Number(this.AddOnForm.value.numberSelect);
  if (newerValue === 0) {
    newerValue = 1;
  }

    this.updateFormPrice = price * newerValue;
    for (let i = 0; i < newerValue - 1; i++) {
      if (this.updateFormPrice) {
      } else {
        this.numberVal = 1;
      }
      if (this.numberVal === 1) {
        this.updateFormPrice = price;
      }
      this.numberVal = newerValue;
      this.addForm.push({ user: {
        firstName: '',
        lastName: '',
        email: ''
      }});
    }
};

public cleanItemContext = function(Id) {
  this.saveAddonDetails.filter(function(v, i, a) {
    if (v.Id ===  Id) {
      a.splice(i, 1);
    }
  });

  this.itemsDetailsArray.filter(function(v, i, a) {
     if (v.SubId ===  Id) {
         a.splice(i, 1);
     }
    });

   this.model.modelForUI.filter(function(v, i, a){
      if (v.Id === Id) {
        a.splice(i, 1);
      }
    });

    this.model.items.filter(function(v, i, a){
      if (v.Id === Id) {
        a.splice(i, 1);
      }
    });
}

public getUser = function(val, name, serviceLevel, Id, price, i) {
  let index = 0;
  let itemDetailArray = '';
  const unique = Math.floor((Math.random() * 50) + 1);
  const peopleArray: any[] = [];
  const allItemUsers: any[] = [];
  const itemDetails = [];

  this.cleanItemContext(Id);

  for (const key in val) {
     if (val) {
        const firstName = val[key].firstName;
        const lastName = val[key].lastName;
        const email = val[key].email;

        this.model.setModelforUI({first_name: val[key].firstName,
        last_name: val[key].lastName, email_address: val[key].email, itemCount: this.itemDetailsCount, Id});
        let hcir: boolean;
        if (index === 0) {
            hcir = true;
        } else {
           hcir = false;
        }
        peopleArray.push( '{"first_name":"' + firstName +
                          '","last_name":"' + lastName + '","email":"' + email + '","is_hcir:":' + hcir + '}');
         this.itemsDetailsArray.push({ firstName : firstName, lastName : lastName, email : email, SubId : Id, hcir });
         index++;
         this.saveAddonDetails.push({modelIndex: this.model.model.length,
         firstName, Id, lastName, email, index, numberSelect: Number(this.AddOnForm.value.numberSelect) });
       }
  }
  itemDetailArray += '={"subscription_id":"' +
  Id + '","quantity":"' + Number(this.AddOnForm.value.numberSelect) +
  '","people":[' + peopleArray + ']}';

  peopleArray.filter(function(value, ind, al) {
    itemDetails.push(al[ind]);
  });
  this.itemDetailsCount++;
  this.model.setModelData(Id,  {itemDetailArray, Id}, {peopleArray, Id});
  this.spinner.checkHttpPending(true);
  this.closeMinuteClinic();
  const isServiceLevel = this.hasServiceLevelPurchased(serviceLevel) ? 0 : this.productServices[i].serviceLevel;
  this.calculate.calculateTotal(isServiceLevel, this.model.model, this.model.items, this.model.coupons).subscribe(d => {
      this.total = d;
      this.calculate.setTotalValue(d);
      this.model.isShippable(d.cart);
      this.order.setAddOnType(name, this.updateFormPrice);
      this.productServices[i].highLight = 'secure-highlight-border';
      this.productServices[i].cartSelected = true;
      this.spinner.checkHttpPending(false);
      sessionStorage.setItem('total', JSON.stringify({total: d}));
  });
};

  public filterSearch = function(filterSearch, index): void {
    filterSearch.active = !filterSearch.active;
    this.productServices.filter(function(val, i, all) {
      if (!all[i].filterApplied) {
        if (all[i].filterCategory === filterSearch.filter) {
          all[i].showFilter = true;
          all[i].filterApplied = true;
          all[i].cardShadow = 'cardShadow';
        }
      } else {
        if (all[i].filterCategory === filterSearch.filter) {
          all[i].showFilter = false;
          all[i].filterApplied = false;
          all[i].cardShadow = '';
        }
      }
    });
  };

ngAfterViewInit() {
  this.purchaseStatus();
}

  public purchaseStatus = function() {
    for (let i = 0; i < this.productServices.length; i++) {
      let regex = new RegExp('SEC3URE', 'g');
      this.productServices[i].Name = this.productServices[i].Name.replace(regex, 'SEC<sup>3</sup>URE');

       const item = this.productServices[i];
       if (item.is_campaign_product) {
          if ((!item.expiration_date) || (item.valid_status === 'renewable') ||
             (item.charges[0].ProductType__c === 'MinuteClinic')) {
             const elementFound: HTMLElement = document.getElementById(item['Id']) as HTMLElement;
              if (elementFound !== null) {
                  elementFound.click();
              }
          }
        } else {
             if ((!item.expiration_date || item.valid_status !== 'valid')  ||
                 (item.charges[0].ProductType__c === 'MinuteClinic')) {
                 this.productServices[i].purchasedStatus = false;
              } else {
                this.productServices[i].purchasedStatus = true;
              }
         }
     }
  };

  public resetAll = function(): void {
    this.filters.filter(function(val, i, all) {
      all[i].active = false;
    });
    this.productServices.filter(function(val, i, all) {
      all[i].showFilter = false;
      all[i].filterApplied = false;
      all[i].cardShadow = '';
    });
  };
}
