import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { OrderSummaryService } from '../order-summary.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionStorageService } from 'angular-web-storage';
import { CalculateTotalService } from '../calculate-total.service';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Product } from '../checkout-status/checkout-status.component';
import * as _ from 'lodash';

export interface AddOn {
  addon: string;
}

export interface PackagePlan {
  packagePlan: string;
}

export interface Purchase {
  isPurchased: Boolean;
}

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  public subscription: Subscription;
  public taxes: Subscription;
  public salesTax = null;
  public serviceLevel = '';
  public modelData: any;
  public localhost = environment.serverUrl;
  public orderData = 'orderData';
  public orderPkgData = 'orderPkgData';
  public listedAddon: AddOn[] = [];
  public addedCoupons: AddOn[] = [];
  public listedPackagePlan: PackagePlan[] = [];
  public clinicServices;
  public updatePackage: any;
  public updateTotal: Subscription;
  public total = {
    total: 0
  };
  public resetCart: Subscription;
  public creditAmount = 0;
  public addPassportPrice = false;
  public showTotal = false;
  public packageTitle;
  public productName = '';
  public pageToken;
  public checkoutPage = false;
  public couponToZero;
  public initTotal: Product;
  public purchasedMembership: any;
  public setServiceLevelOne = '';
  public renewPackage = true;
  public currentPackage = false;
  public grandTotal: any;
  public showInitTotal = true;
  public serviceLevelSubSc: Subscription;
  public sL: any;
  constructor(private order: OrderSummaryService,
    private session: SessionStorageService,
    private http: HttpClient,
    private calculate: CalculateTotalService,
    private route: Router,
    private model: ModelService,
    private router: ActivatedRoute,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initTotal = sessionStorage.getItem('product') ? JSON.parse(sessionStorage.getItem('product')) : {};
    this.purchasedMembership = sessionStorage.getItem('isPurchased') ? JSON.parse(sessionStorage.getItem('isPurchased')) : false;
    this.pageToken = sessionStorage.getItem('token');
    this.addedCoupons = JSON.parse(sessionStorage.getItem('addedCoupons'));
    this.serviceLevel = sessionStorage.getItem('serviceLevel');
    // const upgrade = this.route.url.slice(0, 8);
    // const customize = this.route.url.slice(0, 10);
    // const renewal = this.route.url.slice(0, 8);
    this.currentPackage = JSON.parse(sessionStorage.getItem('currentPackage'));
    this.renewPackage = JSON.parse(sessionStorage.getItem('renewPackage'));
    const updatePackage = JSON.parse(sessionStorage.getItem('total'));
    if (this.renewPackage) {
      this.initTotal.price = this.initTotal.price ? this.initTotal.price : 0;
    }
    if (this.route.url === '/Customize') {
      this.addPassportPrice = true;
      this.calculate.totalSet.subscribe((t: any) => {
        this.showInitTotal = false;
        this.total = {
          total: t.total
        };
      });
    }
    if (this.route.url === `/RenewalRedirect`) {
      this.showTotal = false;
      if (!this.renewPackage) {
        this.showInitTotal = false;
      } else {
        this.showInitTotal = true;
      }
      this.total = {
        total: 0
      };
      this.calculate.totalSet.subscribe((t: any) => {
        if (this.renewPackage) {
          this.total = t;
          this.updatePackage = t.total;
          this.showInitTotal = false;
          this.showTotal = false;
        } else {
          this.total = t;
          this.showInitTotal = false;
          this.showTotal = false;
        }
      });
    }
    if (this.route.url === '/Checkout') {
      this.showTotal = true;
      if ((this.serviceLevel === '4' || this.serviceLevel === '5') &&
        (this.initTotal.price > 0)) {
        this.addPassportPrice = true;
      }
      if (!sessionStorage.getItem('total')) {
        this.grandTotal = this.initTotal.price ? this.initTotal.price : 0;
      } else {
        const tota = JSON.parse(sessionStorage.getItem('total'));
        this.total = tota.total;
        this.grandTotal = this.total.total;
      }
      this.calculate.totalSet.subscribe((t: any) => {
        this.showInitTotal = false;
        this.total = t.total;
        this.grandTotal = parseInt(t.total);
        if (sessionStorage.getItem('coupon_enabled') && t.total === 0) {
          this.couponToZero = true;
          this.showTotal = true;
        }
      });
      this.checkoutPage = true;
    }
    if ((this.route.url === `/Upgrade?token=${this.pageToken}`) ||
      (this.route.url === `/EvergreenUpgrade?token=${this.pageToken}`)) {
      this.addPassportPrice = this.initTotal.price ? true : false;
      this.setServiceLevelOne = this.sL;
      this.showInitTotal = true;
      this.showTotal = false;
      this.calculate.totalSet.subscribe((t: any) => {
        this.total = {
          total: t.total
        };
        this.showInitTotal = false;
      });
      // this.updatePackage = sessionStorage.getItem('updateProductPrice');
    }

    this.clinicServices = JSON.parse(sessionStorage.getItem(this.orderData));
    if (this.clinicServices) {
      for (const clinSerKey in this.clinicServices) {
        if (this.clinicServices) {
          this.listedAddon.push(this.clinicServices[clinSerKey]);
        }
      }
    }

    this.serviceLevelSubSc = this.order.currentServiceLevel.subscribe((sl) => {
      this.sL = sl;
    });
    this.taxes = this.model.sentTaxes.subscribe((tax) => {
      if (tax.taxAmount >= 0) {
        this.salesTax = parseInt(tax.taxAmount);
        this.creditAmount = tax.cancelAmt;
        this.ref.markForCheck();
      }
    });

    this.resetCart = this.order.resetListCart.subscribe(() => {
      this.listedAddon = [];
    });

    this.updateTotal = this.order.updateAddons.subscribe((c) => {
      if (c === null) {
        this.addPassportPrice = false;
        this.total = { total: c };
      }
      this.total = { total: c };
      if (this.total && this.total.total !== null && this.setServiceLevelOne !== '1') {
        this.addPassportPrice = true;
        this.listedAddon = [];
      }
    });

    this.subscription = this.order.addons.subscribe((addons) => {
      this.showInitTotal = false;
      this.showTotal = false;
      const purchasedData = JSON.parse(sessionStorage.getItem('total'));
      if (!addons.remove) {
        const index = this.listedAddon.findIndex((e) => e.addon === addons.addon);
        if (index === -1) {
          this.listedAddon.push(addons);
        } else {
          this.listedAddon[index] = addons;
        }
        //this.listedAddon.push(addons);
        sessionStorage.setItem(this.orderData, JSON.stringify(this.listedAddon));
      } else {
        if (this.listedAddon) {
          if ((this.purchasedMembership) && (this.purchasedMembership.isPurchased)) {
            if (this.hasPackagePurchased(purchasedData.total.cart.cart_items)) {
              this.addPassportPrice = true;
            } else {
              this.addPassportPrice = false;
            }
          }
          this.listedAddon.filter(function(val, ind, all) {
            if (all[ind].addon === addons.addon) {
              all.splice(ind, 1);
            }
          });
        }
        sessionStorage.removeItem(this.orderData);
        sessionStorage.setItem(this.orderData, JSON.stringify(this.listedAddon));
      }
    });

    this.subscription = this.order.packagePlans.subscribe((packagePlans) => {
      if (!packagePlans.remove) {
        this.renewPackage = true;
        this.initTotal.price = packagePlans.price;
      } else {
        this.renewPackage = false;
        this.initTotal.price = null;
      }
    });
  }

  public isCurrency = function(amountString: any) {
    return isNaN(parseInt(amountString));
  };

  private hasPackagePurchased = function(cartItems) {
    let isPurchased = false;
    cartItems.filter(function(val) {
      if (val.type === 'base') {
        isPurchased = true;
      }
    });
    return isPurchased;
  };
}
