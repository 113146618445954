import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replaceDash'})
export class ReplaceDashPipe implements PipeTransform {

    transform(value: any) {
        if (value) {
            return value.replace('-', ' ');
        }
        return value;
    }
}
