import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { ModelService } from '../model.service';
import { CalculateTotalService } from '../calculate-total.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as _ from 'lodash';
import { UserService } from '../user.service';
import { User } from '../interfaces/user';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-activate-radiation-membership',
  templateUrl: './activate-radiation-membership.component.html',
  styleUrls: ['./activate-radiation-membership.component.scss']
})
export class ActivateRadiationMembershipComponent implements OnInit {
  public localHost = environment.serverUrl;
  public pageToken: any;
  public header = { 'Content-Type': 'application/x-www-form-urlencoded' };
  public productPrice: any;
  public firstName: String;
  public lastName: String;
  public total = 0;
  public productId: String;
  public renewal_id = null;

  constructor(private router: ActivatedRoute, 
    public http: HttpClient,
    public spinner: SpinnerService,
    private model: ModelService,
    private calculate: CalculateTotalService,
    private session: SessionStorageService,
    private user: UserService,
    private route: Router) { 
    router.queryParams.subscribe(token => {
      this.pageToken = token['token'];
      this.setUserDetails()
      this.setRadiationPrice();
      sessionStorage.setItem('token', this.pageToken);
      if(token['renewal_id']) {
        this.renewal_id = token['renewal_id'];
      }
    });
  }

  ngOnInit() {
    sessionStorage.setItem('titleFlag', JSON.stringify('Radiation'));
  }

  setRadiationPrice() {
    const self = this;
    this.spinner.checkHttpPending(true);
    const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${this.pageToken}`, { headers: this.header });
        packages.subscribe((res: any) => {
          res.filter(function(val) {
            if (val.rate_plan[0].planType && val.rate_plan[0].planType === 'Base') {
              console.log(val.rate_plan);
              self.getRadiationPackage(val.Id, val.rate_plan[0].serviceLevel);
            }
          });
        });
  }

  getRadiationPackage(id: any, serviceLevel: any) {
    const self = this;
    this.http.post(`${this.localHost}/commerce/products/addonList_get`,
    `token=${this.pageToken}&package_id=${id}&service_level=${serviceLevel}`, { headers: this.header })
    .subscribe((result: any) => {
      result.filter(function(val) {
          if (val.charges[0].entitlement === 'Radiation Exposure Monitoring') {
            self.productPrice = val.sumPrice;
            self.productId = val.Id;
            sessionStorage.setItem('radiationPrice', val.sumPrice);
       
          }
      });
    }, err => {
      console.log(err);
    }, () => {
      this.spinner.checkHttpPending(false);
    });
  }

  setUserDetails() {
    this.http.post(`${this.localHost}/commerce/products/userDetails_get`, `token=${this.pageToken}`, { headers: this.header })
            .subscribe((result: User) => {
              this.user.getUserNames({firstName: result.first_name, 
                lastName: result.last_name, 
                userId: result.userId});
              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              this.firstName = result.first_name;
              this.lastName = result.last_name;
              this.spinner.checkHttpPending(false);
            
            });
  }

  goBackToParentPortal() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }

  confirmSelection() {
    this.model.setModelData(this.productId, '', '');
    this.calculate.calculateTotal(0,
      this.model.model, this.model.items, this.model.coupons)
    .subscribe(da => {
      this.total = da;
      this.calculate.setTotalValue(da);
      this.model.isShippable(da.cart);
      this.spinner.checkHttpPending(false);
      sessionStorage.setItem('total', JSON.stringify({total: da}));
        });
      if(this.renewal_id) {
        sessionStorage.setItem('renewal_id', this.renewal_id);
      }
    this.route.navigateByUrl('ShippingAddress');
  }

}
