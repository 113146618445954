import { Component, NgZone, AfterViewInit, HostListener, ViewChild, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { ValidateCreditCardNumber } from '../validators/credit-card-number.validator';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { CouponsComponent } from '../coupons/coupons.component';
import {
  NgbModal, NgbAccordionConfig, NgbPanelChangeEvent,
  ModalDismissReasons, NgbModalRef, NgbActiveModal, NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../spinner/spinner.service';
import { CountrySelect } from '../validators/country-select.validator';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  public checkOutform: FormGroup;
  public addressVerify: FormGroup;
  public subscription: Subscription;
  public localhost = environment.serverUrl;
  public visaSelected = true;
  public mcSelected = true;
  public pageToken: any;
  public aeSelected = true;
  public dSelected = true;
  public showPayment = true;
  public months = ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years: any[] = [];
  public States: string[] = [];
  public countries: any[];
  public ccNum: any;
  public dontCheckTwice = true;
  public sameAddress: boolean;
  public checkout: any;
  public cardType: string;
  public showShippable: boolean;
  public billingAddress = false;
  public taxChecked = false;
  public isCreditCard = false;
  public panelId = 'billing';
  public nextState = true;
  public thisYear: any;
  public countryField: any;
  public ba: Object;
  public sa: Object;
  public cc: object;
  public cardNumber: Number;
  public visa = new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?)$');
  public mastercard = new RegExp('^(?:5[1-5][0-9]{14})$');
  public americaexpress = new RegExp('^(?:3[47][0-9]{13})$');
  public discovRegEx = new RegExp('6(?:011|5[0-9]{2})[0-9]{12}');
  public addressOne: string;
  public addressTwo: string;
  public country: string;
  public city: string;
  public Height: any;
  public pageHeight: string;
  public state: string;
  public zip5: string;
  public zip3: string;
  public checkoutPage = false;
  public userEmail: string;
  public mobileLayout = false;
  public buttonText = 'Place Order';
  public selectBillingMenu = false;
  public selectShippingMenu = false;
  public selectCardMenu = false;
  private addressChecked = false;
  private modalRef: NgbModalRef;
  private checkoutAddressVerify = true;
  @ViewChild('content') private content;
  @ViewChild('contentWarning') private contentWarning;
  @ViewChild(CouponsComponent) child: CouponsComponent;
  @ViewChild('acc') private acc;
  public zip: any;
  public maskedCardNum: any;
  public refId: any;

  constructor(private http: HttpClient,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private session: SessionStorageService,
    private model: ModelService,
    private modalService: NgbModal,
    private spinner: SpinnerService,
    private user: UserService,
    private route: Router,
    public breakpointObserver: BreakpointObserver,
    private collaspe: NgbAccordionConfig) { }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.Height = event.target.innerHeight;
    const adjustedHeight = this.Height - 100;
    const adjustToString = adjustedHeight.toString();
    const selectHeight = document.getElementById('bottom-wrapper');
    selectHeight.style.top = adjustToString + 'px';
  }

  ngOnInit() {
    const countryStr = sessionStorage.getItem('countries');
    const userData = JSON.parse(sessionStorage.getItem('user'));

    window.card = window.card || {};
    window.card.namespace = window.card.namespace || {};
    window.card.namespace.setAddonPayment = this.setAddonPayment.bind(this);

    this.userEmail = userData.email;
    this.countries = this.user.getCountryList(JSON.parse(countryStr));
    this.countries.unshift({
      country_name: 'Select Country',
      region: '',
      abbr: '',
      abbr_three: ''
    });
    if (sessionStorage.getItem('showPayment')) {
      const sp = JSON.parse(sessionStorage.getItem('showPayment'));
      this.showPayment = sp.showPayment;
    }
    this.Height = (window.innerHeight);
    const adjustedHeight = this.Height - 100;
    const selectHeight = document.getElementById('bottom-wrapper');
    const adjustToString = adjustedHeight.toString();
    selectHeight.style.top = adjustToString + 'px';

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileLayout = true;
          this.buttonText = 'Order';
          if (this.route.url === '/Checkout') {
            this.pageHeight = '0px';
          } else {
            this.pageHeight = adjustedHeight.toString() + 'px';
          }
        } else {
          this.mobileLayout = false;
          this.buttonText = 'Place Order';
          this.pageHeight = adjustedHeight.toString() + 'px';
        }
      });
        this.sameAddress = true;
        this.showShippable = JSON.parse(sessionStorage.getItem('shippable'));
        this.pageToken = sessionStorage.getItem('token');
        this.user.getUserNames(sessionStorage.getItem('user'));
        this.thisYear = Number(moment().format('YYYY'));
        for (let i = 0; i < 15; i++) {
          this.years.push(this.thisYear + i);
        }
        this.years.unshift('');
        this.checkOutform = new FormGroup({
          shippingAddress: new FormGroup({
            addressOne: new FormControl('', Validators.required),
            addressTwo: new FormControl(''),
            country: new FormControl('', [Validators.required, CountrySelect]),
            city: new FormControl('', Validators.required),
            state: new FormControl('', [Validators.required, CountrySelect]),
            zip: new FormControl('', Validators.required),
          }),
          billingAddress: new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            addressOne: new FormControl('', Validators.required),
            addressTwo: new FormControl(''),
            country: new FormControl('', [Validators.required, CountrySelect]),
            city: new FormControl('', Validators.required),
            state: new FormControl('', [Validators.required, CountrySelect]),
            zip: new FormControl('', Validators.required),
          }),
          creditCardAddress: new FormGroup({
            country: new FormControl('', [Validators.required, CountrySelect]),
            city: new FormControl('', Validators.required),
            state: new FormControl('', [Validators.required, CountrySelect]),
            zip: new FormControl('', Validators.required),
          }),
          otheremail: new FormControl('', Validators.email)
        });
        this.addressVerify = new FormGroup({
          chooseAddress: new FormControl('recommended')
        });
        this.checkout = {
          status: 'init-Status',
          button: this.buttonText,
          paymentZero: false,
          isSubmitable: false,
          formButton: true,
          email: ''
        };
   
    this.initAddressChecks();
    this.checkForError();
    this.setBillingStateValue();
    this.setShippingngStateValue();
  }

  public loadHostedPage = function () {

    var data = 'var callback = function(response) {' +
      'if(response.success) {' +
          'card.namespace.setAddonPayment(response.refId);' +
      '} else {' +
         'alert("errorcode="+response.errorCode + ", errorMessage="+response.errorMessage);' +
     ' }' +
     '}; var prepopulateFields = {};';
     var script = this._renderer2.createElement('script');
     script.type = 'text/javascript';
     script.text = data;
     this._renderer2.appendChild(this._document.body, script);

     const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
     this.pageToken = sessionStorage.getItem('token');
     this.spinner.checkHttpPending(true);
     this.http.post(`${this.localhost}/commerce/Checkout/paymentData_get`,
     `token=${this.pageToken}`, { headers: headerPayment }).subscribe((data: any) => {
      const keyArray = data.zuoraData.result.key.split("\r\n");
        data = 'var params = {' +
          'tenantId: ' + data.zuoraData.result.tenantId + ',' +
          'id:"' + data.pageId + '",' +
          'token:"' + data.zuoraData.result.token + '",' +
          'signature:"' + data.zuoraData.result.signature + '",' +
          'style:"inline",' +
          'key: "' + keyArray[1] + '",' +
          'submitEnabled:"true",' +
          'param_supportedTypes:"AmericanExpress,Visa,MasterCard,Discover",' +
          'url:"' + data.uri + '",' +
          'paymentGateway:"New IC PayPal Gateway" ' +
        '};' +
        'Z.setEventHandler("onloadCallback", function() {' +
        'console.info("HPM page is loaded."); ' +
        '});' +
        'Z.render(' +
            'params,' +
            'prepopulateFields,' +
            'callback' +
        ');' ;
        script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.text = data;
        this._renderer2.appendChild(this._document.body, script);
        this.spinner.checkHttpPending(false);
     });
    };

    public setAddonPayment = function(payRefId) {
      this.spinner.checkHttpPending(true);
      this.ngZone.run(() => this.setPaymentId(payRefId));
    }
    
    public setPaymentId = function(payRefId) {
      
      if (payRefId) {
        this.refId = payRefId;
        sessionStorage.setItem('payRefId', payRefId);
        const headerPayment = {'Content-Type': 'application/x-www-form-urlencoded'};
        const params = `token=${this.pageToken}&payRefId=${payRefId}`;
        this.http.post(`${this.localhost}/commerce/Checkout/getPaymentMethodData_post`,
                     params, { headers: headerPayment }).subscribe((data: any) => {
           this.cardType = data.result.CreditCardType;
           this.maskedCardNum = data.result.CreditCardMaskNumber;
           this.spinner.checkHttpPending(false);
        });
        this.validateBilling();
        this.checkForError();
        this.ref.detectChanges();
      }
    }

  private setBillingStateValue = function() {
    this.countryField = this.checkOutform.controls.billingAddress.get('country');
    this.countryField.valueChanges.subscribe(
      (val: string) => {
        if ((val) && ((val === 'US') || (val === 'CA'))) {
          this.user.getStates(val).subscribe(cl => {
            this.States = this.user.getStateList(cl);
            this.selectBillingMenu = true;
            this.ref.detectChanges();
          });
        } else {
          this.selectBillingMenu = false;
          this.checkOutform.controls.billingAddress.get('state').reset();
          this.ref.detectChanges();
        }
      }
    );
  };

  private setShippingngStateValue = function() {
    this.countryField = this.checkOutform.controls.shippingAddress.get('country');
    this.countryField.valueChanges.subscribe(
      (val: string) => {
        if ((val) && ((val === 'US') || (val === 'CA'))) {
          this.user.getStates(val).subscribe(cl => {
            this.States = this.user.getStateList(cl);
            this.selectShippingMenu = true;
            this.ref.detectChanges();
          });
        } else {
          this.selectShippingMenu = false;
          this.checkOutform.controls.shippingAddress.get('state').reset();
          this.ref.detectChanges();
        }
      }
    );
  };

public removeCard = function() {
  this.refId = null;
  this.maskedCardNum = null;
  this.cardType = null;
  sessionStorage.setItem('payRefId', null);
  this.validateBilling();
  this.checkForError();
  this.ref.detectChanges();
  this.acc.toggle('payment');
} 

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public checkLength = function(len, ele, control) {
    const fieldLength = ele.toString().length;
    if (fieldLength <= len) {
      return true;
    } else {
      if (control === 'zip') {
        let str = ele.toString();
        str = str.substring(0, str.length - 1);
        this.checkOutform.controls.creditCardAddress.get('zip').setValue(str);
      }
      if (control === 'cvv') {
        let str = ele.toString();
        str = str.substring(0, str.length - 1);
        this.checkOutform.controls.creditCard.get('cvv').setValue(Number(str));
      }
    }
  };

  public checkCount = function(total, control) {
    this.checkOutform.valueChanges.subscribe(val => {
      if (val.creditCardAddress.zip) {
        this.checkLength(total, val.creditCardAddress.zip, control);
      }
    });
  };

  public modifyAddress() {
    if (this.addressVerify.controls.chooseAddress.value === 'recommended') {
      if (this.addressOne === '' || this.addressOne === undefined) {
        this.addressOne = ' ';
      }
      this.checkOutform.controls.shippingAddress.setValue({
        addressOne: this.addressTwo,
        addressTwo: this.addressOne,
        country: this.country,
        city: this.city,
        state: this.state,
        zip: this.zip5,
      });
      this.modalRef.close();
      this.checkoutAddressVerify = false;
    } else {
      this.modalRef.close();
    }
  }

  public validateBilling = function() {
    let noErrors = true;
    Object.keys(this.checkOutform.controls.billingAddress.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.checkOutform.controls.billingAddress.get(key).errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
            this.checkOutform.controls.billingAddress.get(key).markAsTouched();
          }
        });
      }
    });
    if(noErrors && this.showPayment && this.refId){
      this.checkout = {
        status: 'billValidate',
        button: this.buttonText,
        paymentZero: false,
        isSubmitable: true,
        formButton: false,
        email: ''
      };
    }
    else if (noErrors && this.showPayment) {
      this.acc.toggle('payment');
      this.nextState = true;
      this.checkout = {
        status: 'billValidate',
        button: this.buttonText,
        paymentZero: false,
        isSubmitable: false,
        formButton: false,
        email: ''
      };
    } else {
      if (noErrors) {
        this.checkout = {
          status: 'allValidateNoPayment',
          button: this.buttonText,
          paymentZero: true,
          isSubmitable: false,
          formButton: false,
          email: ''
        };
      } else {
        this.checkout = {
          status: 'allValidateNoPayment',
          button: this.buttonText,
          paymentZero: false,
          isSubmitable: false,
          formButton: false,
          email: ''
        };
      }
      this.acc.toggle('receipt');
    }
  };

  public beforeChange($event: NgbPanelChangeEvent) {
    this.panelId = $event.panelId;
    this.nextState = $event.nextState;
    if($event.nextState && $event.panelId == 'payment' && !this.maskedCardNum) {
      this.loadHostedPage();
    }
  }

  public checkShippingValidation() {
    let noErrors = true;
    Object.keys(this.checkOutform.controls.shippingAddress['controls']).forEach(key => {
      const controlErrors: ValidationErrors = this.checkOutform.controls.shippingAddress.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
            this.checkOutform.controls.shippingAddress.get(key).markAsTouched();
          }
        });
      }
    });
    if (noErrors) {
      sessionStorage.setItem('shippingAddress', JSON.stringify(this.sa));
      this.acc.toggle('payment');
    }
    if (!this.showPayment) {
      this.onBlur();
    }
  }

  public sameAsAddress = function() {
    let noErrors = true;
    Object.keys(this.checkOutform.controls.billingAddress.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.checkOutform.controls.billingAddress.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError) {
            noErrors = false;
            this.checkOutform.controls.billingAddress.get(key).markAsTouched();
          }
        });
      }
    });

    if (noErrors) {
      if (this.sameAddress) {
        const val = {
          shippingAddress: {
            addressOne: this.checkOutform.controls.billingAddress.controls.addressOne.value,
            addressTwo: this.checkOutform.controls.billingAddress.controls.addressTwo.value,
            city: this.checkOutform.controls.billingAddress.controls.city.value,
            state: this.checkOutform.controls.billingAddress.controls.state.value,
            zip: this.checkOutform.controls.billingAddress.controls.zip.value,
          }
        };
        this.checkOutform.get('shippingAddress').disable();
        this.verifyAddress();
      } else {
        this.checkOutform.get('shippingAddress').enable();
      }
      this.acc.toggle('shipping');
    }
    this.taxes = this.model.sentTaxes.subscribe((tax) => {
      if (tax.taxAmount >= 0) {
        this.salesTax = parseInt(tax.taxAmount);
        this.creditAmount = tax.cancelAmt;
      }
    });
  };

  public verifyAddress = function() {
    let val;
    if (this.sameAddress) {
      this.checkOutform.controls.shippingAddress.setValue({
        addressOne: null,
        addressTwo: null,
        country: null,
        city: null,
        state: null,
        zip: null,
      });
      val = {
        shippingAddress: {
          addressOne: this.checkOutform.controls.billingAddress.controls.addressOne.value,
          addressTwo: this.checkOutform.controls.billingAddress.controls.addressTwo.value,
          city: this.checkOutform.controls.billingAddress.controls.city.value,
          state: this.checkOutform.controls.billingAddress.controls.state.value,
          zip: this.checkOutform.controls.billingAddress.controls.zip.value,
        }
      };
    } else {
      val = {
        shippingAddress: {
          addressOne: this.checkOutform.controls.shippingAddress.controls.addressOne.value,
          addressTwo: this.checkOutform.controls.shippingAddress.controls.addressTwo.value,
          city: this.checkOutform.controls.shippingAddress.controls.city.value,
          state: this.checkOutform.controls.shippingAddress.controls.state.value,
          zip: this.checkOutform.controls.shippingAddress.controls.zip.value,
        }
      };
    }
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const verifyAddress = this.http.post(`${this.localhost}/commerce/Checkout/verifyAddress`,
      `token=${this.pageToken}&address1=${val.shippingAddress.addressOne}&address2=${val.shippingAddress.addressTwo}
    &city=${val.shippingAddress.city}&state=${val.shippingAddress.state}&zip=${val.shippingAddress.zip}`,
      { headers: header }).subscribe(address => {
        if (address.Address.Address2) {
          if (address.Address.Address1) {
            this.addressOne = address.Address.Address1;
          }
          this.addressTwo = address.Address.Address2;
          this.city = address.Address.City;
          this.country = this.ba.country;
          this.state = address.Address.State;
          this.zip5 = address.Address.Zip5;
          if (address.Address.Zip3) {
            this.zip3 = address.Address.Zip3;
          }
          if (address.Address.Zip4) {
            this.zip4 = address.Address.Zip4;
          }
          if (this.billingAddress) {
            if (this.checkoutAddressVerify) {
              this.modalRef = this.modalService.open(this.content);
              this.modalRef.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
            }
            this.panelId = 'shipping';
            if (this.sameAddress) {
              this.checkOutform.controls.shippingAddress.setValue({
                addressOne: this.ba.address,
                addressTwo: this.ba.suite,
                country: this.ba.country,
                city: this.ba.city,
                state: this.ba.state,
                zip: this.ba.zip,
              });
            }
          }
          this.addressChecked = true;
        } else {
          if (!address.Address.Valid) {
            this.modalRef = this.modalService.open(this.contentWarning);
          }
        }
      }, err => {
        console.log(err);
      });
  };

  public checkForError = function() {
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.checkOutform.valueChanges.subscribe(val => {      
      if (this.refId) {
        this.isCreditCard = true;
        
      } else {
        this.isCreditCard = false;
      }
      if (this.sameAddress) {
        this.sa = {
          addressOne: val.billingAddress.addressOne,
          addressTwo: val.billingAddress.addressTwo,
          city: val.billingAddress.city,
          state: val.billingAddress.state,
          country: val.billingAddress.country,
          zip: val.billingAddress.zip
        };
        const zipStringShipping = this.sa.zip.toString();
        if (zipStringShipping.length >= 5 && this.checkoutAddressVerify) {
          this.isShippingAddress = true;
        }

      } else if ((val.shippingAddress.hasOwnProperty('addressOne') && val.shippingAddress.addressOne ||
        val.shippingAddress.hasOwnProperty('addressTwo') && val.shippingAddress.addressTwo) &&
        val.shippingAddress.state && val.shippingAddress.city &&
        val.shippingAddress.country) {
        this.sa = {
          addressOne: val.shippingAddress.addressOne,
          addressTwo: val.shippingAddress.addressTwo,
          city: val.shippingAddress.city,
          state: val.shippingAddress.state,
          country: val.shippingAddress.country,
          zip: val.shippingAddress.zip
        };
        const zipStringShipping = this.sa.zip.toString();
        if (zipStringShipping.length >= 5 && this.checkoutAddressVerify) {
          this.isShippingAddress = true;
        }
      } else {
        this.isShippingAddress = false;
      }
      if (this.showShippable) {
        if (this.isCreditCard && this.billingAddress && this.isShippingAddress) {

          this.model.clearData();
          this.model.getPaymentData({ card: this.cc, shipping: this.sa, billing: this.ba });
          this.checkout = {
            status: 'errorCheck-cc-billing-isShippingTrue',
            button: this.buttonText,
            paymentZero: false,
            isSubmitable: true,
            formButton: true,
            email: this.checkOutform.controls.otheremail.value
          };
        } else {
          this.checkout = {
            status: 'errorCheck-cc-billing-isShippingFalse',
            button: this.buttonText,
            paymentZero: false,
            isSubmitable: false,
            formButton: false,
            email: this.checkOutform.controls.otheremail.value
          };
        }
      } else {
        if (this.isCreditCard && this.billingAddress) {
          this.model.clearData();
          this.model.getPaymentData({ card: this.cc, shipping: this.sa, billing: this.ba });
          this.checkout = {
            status: 'errorCheck-all-good',
            button: this.buttonText,
            paymentZero: false,
            isSubmitable: true,
            formButton: true,
            email: this.checkOutform.controls.otheremail.value
          };
        } else {
          this.checkout = {
            status: 'errorCheck-all-bad',
            button: this.buttonText,
            paymentZero: false,
            isSubmitable: false,
            formButton: this.showPayment ? true : false,
            email: this.checkOutform.controls.otheremail.value
          };
        }
      }


    });
  };

  public addCoupons = function() {
    const total = JSON.parse(sessionStorage.getItem('total'));
    if (total.total.total <= 0) {
      this.showPayment = false;
      sessionStorage.setItem('showPayment', JSON.stringify({ showPayment: false }));
    }
    this.taxChecked = true;
    this.onBlur();
  };


  public initAddressChecks = function() {

    this.checkOutform.controls.shippingAddress.valueChanges.subscribe(shipping => {
      let zip = null;
      if (shipping.zip) {
        zip = shipping.zip.toString();
      }
      if (shipping.country && shipping.city && shipping.state &&
        shipping.addressOne && (zip.length >= 5)) {
        this.billingToShippingCheck = true;
        this.dontCheckTwice = true;
      }
      if (this.sa && this.sameAddress) {
        if ((this.sa.country !== shipping.country) || (this.sa.city !== shipping.city)
          || (this.sa.addressOne !== shipping.addressOne) ||
          (this.sa.zip !== shipping.zip)) {
          this.dontCheckTwice = true;
          this.checkoutAddressVerify = true;
          this.sa.addressOne = shipping.addressOne;
          this.sa.addressTwo = shipping.addressTwo;
          this.sa.country = shipping.country;
          this.sa.city = shipping.city;
          this.sa.zip = shipping.zip;
        }
      }
      if (this.billingToShippingCheck && this.dontCheckTwice) {
        this.checkoutAddressVerify = true;
      }

    });
  };

  public onBlur = function() {
    if (this.checkOutform.controls.otheremail.value) {
      this.checkout.email = this.checkOutform.controls.otheremail.value;
    }
    setTimeout(() => {
      if (this.billingToShippingCheck && this.dontCheckTwice) {
        this.dontCheckTwice = false;

        if (!this.addressChecked) {
          this.verifyAddress();
        }
      }
    }, 100);

    this.checkOutform.controls.billingAddress.valueChanges.subscribe(val => {
      const zip = val.zip.toString();
      if (this.showShippable) {
        if (val.country && val.city && val.state && val.firstName && val.lastName && val.addressOne && (zip.length >= 5)) {
          this.billingAddress = true;
          this.taxChecked = true;
        } else {
          this.billingAddress = false;
          this.taxChecked = false;
        }
      } else {
        if (val.country && val.city && val.state && val.firstName && val.lastName && (zip.length >= 5)) {
          this.billingAddress = true;
          this.taxChecked = true;
        } else {
          this.billingAddress = false;
          this.taxChecked = false;
        }
      }
      this.ba = {
        country: val.country,
        state: val.state,
        city: val.city,
        firstName: val.firstName,
        lastName: val.lastName,
        address: val.addressOne,
        suite: val.addressTwo,
        zip: val.zip
      };
    });
    if (this.billingAddress && this.taxChecked) {
      this.taxChecked = false;
      this.model.getTaxData(this.ba);
      sessionStorage.setItem('billingAddress', JSON.stringify(this.ba));
    }
    let hasValidShppingAddress = false;

    if (this.isShippingAddress) {
      if (this.sa.country && this.sa.city && this.sa.state &&
        this.sa.addressOne && (this.sa.zip.length >= 5)) {
        hasValidShppingAddress = true;
      }
    }

    if (this.showShippable) {
      if (this.isCreditCard && this.billingAddress && this.isShippingAddress) {
        this.model.clearData();
        this.model.getPaymentData({ card: this.cc, shipping: this.sa, billing: this.ba });
        this.checkout.isSubmitable = true;
        this.checkout = {
          status: 'onBlur-Shippable',
          button: this.buttonText,
          paymentZero: false,
          isSubmitable: true,
          formButton: true,
          email: this.checkOutform.controls.otheremail.value
        };
      }
      else if (!this.showPayment && this.billingAddress && this.isShippingAddress && hasValidShppingAddress) {
        this.checkout = {
          status: 'errorCheck-all-good',
          button: this.buttonText,
          paymentZero: true,
          isSubmitable: true,
          formButton: false,
          email: this.checkOutform.controls.otheremail.value
        };
      }
      else {
        this.checkout = {
          status: 'onBlur-notShippable',
          button: this.buttonText,
          paymentZero: false,
          isSubmitable: false,
          formButton: false,
          email: this.checkOutform.controls.otheremail.value
        };
      }
    } else {
      if (this.isCreditCard && this.billingAddress) {
        this.model.clearData();
        this.model.getPaymentData({ card: this.cc, shipping: this.sa, billing: this.ba });
        this.checkout.isSubmitable = true;
        this.checkout = {
          status: 'onBlur-credit-billing-validate',
          button: this.buttonText,
          paymentZero: false,
          isSubmitable: true,
          formButton: true,
          email: this.checkOutform.controls.otheremail.value
        };
      } else {
        if (!this.showPayment && this.billingAddress) {
          if (this.checkout.status === 'billValidate') {
            this.checkout = {
              status: 'errorCheck-all-good',
              button: this.buttonText,
              paymentZero: true,
              isSubmitable: true,
              formButton: false,
              email: this.checkOutform.controls.otheremail.value
            };
          } else {
            this.checkout = {
              status: 'onBlur-credit-billing-notValidate',
              button: this.buttonText,
              paymentZero: true,
              isSubmitable: true,
              formButton: false,
              email: this.checkOutform.controls.otheremail.value
            };
            if(this.checkOutform.controls.otheremail.status === 'VALID'){
              this.checkout.isSubmitable = true;
            }
          }
        }
      }
    }

  };
}
