import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-ecommerce-panel-large',
  templateUrl: './ecommerce-panel-large.component.html',
  styleUrls: ['./ecommerce-panel-large.component.scss']
})
export class EcommercePanelLargeComponent implements OnInit {
  public price: string;
  public secureButtonColor: string;
  @Input() componentType: string;
  constructor() {

  }

  ngOnInit() {
  }

}
