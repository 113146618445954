import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secure-large-button',
  templateUrl: './secure-large-button.component.html',
  styleUrls: ['./secure-large-button.component.scss']
})
export class SecureLargeButtonComponent implements OnInit {
  public buttonText = 'CHOOSE';
  public buttonColor = 'gray';
  constructor() { }

  ngOnInit() {
  }

}
