import { Component, Output, OnInit, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { UserService } from '../user.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import { User } from '../interfaces/user';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-evergreen-membership',
  templateUrl: './evergreen-membership.component.html',
  styleUrls: ['./evergreen-membership.component.scss']
})
export class EvergreenMembershipComponent implements OnInit {
  public localHost = environment.serverUrl;
  public pageToken: any;
  public firstName: string;
  public lastName: string;
  public membership: any[] = [];
  public passportId: any;
  public passport: any[] = [];
  public passportIncludes: any[] = [];
  public productName: string;
  public token = 'token';
  public includes: any[] = [];
  public policy: string[] = [];
  public productPrice: string;
  public passportIncludesArray: any = [];
  public notDuplicatesPassport: any = [];
  public passportSubArray: any = [];
  public basicSubArray: any = [];
  public basicName: string;
  public basicRatePlan: any = [];
  public modelData = 'modelData';
  public userNames;
  public payRefId: any;
  public isCouponEnabled;
  public billingData: Object;
  public passportPrice = 0;
  public userPlanType: any;
  public basicIncludes: any = [];
  public basicIncludesArray: any = [];
  public renewal_id = null;
  public shorten = false;
  public reactivationRequired = false;
  public reactivationPlanId = '';
  public reactivationPlanPrice = 0;
  public hasReward = false;
  public rewardPlanId = '';
  public rewardPlanPrice = 0;
  public forceReactivation = false;

  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private user: UserService,
    private spinner: SpinnerService,
    public breakpointObserver: BreakpointObserver,
    private ref: ChangeDetectorRef) {
    let initPrice = null;
    let productName = '';
    let passportId = '';
    let serviceLevel = '';

    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    router.queryParams.subscribe(token => {
      this.spinner.checkHttpPending(true);
      this.pageToken = token['token'];
      if (token['token']) {
        if (token['renewal_id']) {
          this.renewal_id = token['renewal_id'];
        }

        const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${token['token']}`, { headers: header });
        packages.subscribe((res: any) => {
          this.membership = res;
          sessionStorage.setItem('packageInfo', JSON.stringify(res));
          this.membership.filter(function(val) {
            if (val.rate_plan.sumPrice !== 'FREE') {
              val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
            }
            if (val.Name === 'SEC3URE Passport') {
              initPrice = Number(val.rate_plan[0].sumPrice);
              serviceLevel = val.rate_plan[0].serviceLevel;
              productName = val.Name;
              passportId = val.Id;
            }
          });
          this.productPrice = initPrice;
          sessionStorage.setItem('secure_passport_price', this.productPrice);
          sessionStorage.setItem('serviceLevel', serviceLevel);
          sessionStorage.setItem('productId', passportId);
          sessionStorage.setItem('reactivation_required', JSON.stringify(this.reactivationRequired));
          let reactivation = (token['reactivation'] == undefined)? 0 : token['reactivation'];
          this.productName = productName;
          this.http.post(`${this.localHost}/commerce/products/userDetails_get`, `token=${token['token']}&reactivation=${reactivation}`, { headers: header })
            .subscribe((result: User) => {
              if(result.show_warning_banner) {
                this.forceReactivation = true;
              }

              if (result.reactivation_required == true) {
                this.http.post(`${this.localHost}/commerce/products/getReactivationPlan`, `token=${token['token']}`, { headers: header })
                  .subscribe((reactivationPlan: any) => {
                    this.reactivationPlanId = reactivationPlan.Id;
                    this.reactivationPlanPrice = reactivationPlan.sumPrice;
                    this.reactivationRequired = true;
                    sessionStorage.setItem('reactivation_required', JSON.stringify(this.reactivationRequired));
                    sessionStorage.setItem('reactivation_plan_id', reactivationPlan.Id);
                    this.ref.detectChanges();
                    this.spinner.checkHttpPending(false);
                  });
              }
              else {
                this.spinner.checkHttpPending(false);
              }

              if (result.has_reward == true) {
                this.spinner.checkHttpPending(true);
                this.http.post(`${this.localHost}/commerce/products/getRewardPlan`, `token=${token['token']}&referral_count=${result.reward_info.referral_count}`, { headers: header })
                  .subscribe((reactivationPlan: any) => {
                    this.rewardPlanId = reactivationPlan.Id;
                    this.rewardPlanPrice = reactivationPlan.sumPrice;
                    this.hasReward = true;
                    sessionStorage.setItem('hasReward', JSON.stringify(this.hasReward));
                    sessionStorage.setItem('rewardPlanId', reactivationPlan.Id);
                    this.ref.detectChanges();
                    this.spinner.checkHttpPending(false);
                  });
              }
              else {
                this.spinner.checkHttpPending(false);
              }

              this.userPlanType = result.user_plan_type;
              this.isCouponEnabled = result.coupon_enabled === '1';
              sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled ))
              this.user.getUserNames({
                firstName: result.first_name,
                lastName: result.last_name,
                userId: result.userId
              });
              sessionStorage.setItem('email', result.email);
              sessionStorage.setItem('first_name', result.first_name);
              sessionStorage.setItem('last_name', result.last_name);
              sessionStorage.setItem('middle_name', result.middle_name);
              sessionStorage.setItem('userId', result.userId);
              sessionStorage.setItem('applicationId', result.applicationId);

              sessionStorage.setItem('address1', result.address1);
              sessionStorage.setItem('address2', result.address2);
              sessionStorage.setItem('city', result.city);
              sessionStorage.setItem('state', result.state);
              sessionStorage.setItem('country', result.country);
              sessionStorage.setItem('zip', result.zip);

              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              sessionStorage.setItem('campaignId', result.campaignId);
              sessionStorage.setItem('campaignToken', result.campaignToken);

              sessionStorage.setItem('passportUrl', result.passport_url);

              sessionStorage.setItem('campaignProduct', JSON.stringify(result.campaignProduct));

              if (!this.ref['destroyed']) {
                this.ref.detectChanges();
              }
            });

          const headerPayment = { 'Content-Type': 'application/x-www-form-urlencoded' };
          const params = `token=${this.pageToken}`;
          this.http.post(`${this.localHost}/commerce/UpdateUserData/getExistingPaymentMethodId`,
            params, { headers: headerPayment }).subscribe((data: any) => {
              if (data) {
                this.payRefId = data;
                sessionStorage.setItem('payRefId', data);
              } else {
                sessionStorage.setItem('payRefId', '-1');
              }
            });
          
        });
      }
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  ngOnInit() {
    sessionStorage.clear();
    sessionStorage.setItem('itemsSelected', JSON.stringify(false));
    sessionStorage.setItem('titleFlag', JSON.stringify('Passport_2.0'));
    sessionStorage.setItem(this.token, this.pageToken);
    this.model.setModelData('', '', '');
    this.model.shippableProduct = false;
    sessionStorage.setItem('layoutTag', 'Evergreen');
    sessionStorage.setItem('entry_url', 'EvergreenMembership');

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.shorten = true;
        }
      });
  }
  goBackSec3ure = function() {
    window.location.href = sessionStorage.getItem('inboundUrl');
  };

  goWorkAddress() {
    const serviceLevel = sessionStorage.getItem('serviceLevel');
    this.passportId = sessionStorage.getItem('productId');
    this.spinner.checkHttpPending(true);
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.spinner.checkHttpPending(true);
    let reactivationPlanArg = `&reactivation_plan_id=${this.reactivationPlanId}`;
    let rewardPlanArg = `&reward_plan_id=${this.rewardPlanId}`;
    this.http.post(`${this.localHost}/commerce/products/addonList_get`,
      `token=${this.pageToken}&package_id=${this.passportId}&service_level=${serviceLevel}${reactivationPlanArg}${rewardPlanArg}`, { headers: header })
      .subscribe(result => {
        this.spinner.checkHttpPending(false);
      }, err => {
        console.log(err);
      }, () => { });

    const firstName = sessionStorage.getItem('first_name');
    const lastName = sessionStorage.getItem('last_name');
    const addressOne = sessionStorage.getItem('address1');
    const addressTwo = sessionStorage.getItem('address2');
    const city = sessionStorage.getItem('city');
    const state = sessionStorage.getItem('state');
    const country = sessionStorage.getItem('country');
    const zip = sessionStorage.getItem('zip');
    if (this.renewal_id) {
      sessionStorage.setItem('renewal_id', this.renewal_id);
    }
    if (addressOne && city && state && country && zip && addressOne != "null" && city != "null" && state != "null" && country != "null" && zip != "null") {
      this.model.clearData();

      this.billingData = {
        cardholder: firstName + ' ' + lastName,
        country: country,
        state: state,
        city: city,
        addressOne: addressOne,
        addressTwo: addressTwo,
        zip: zip
      };
      sessionStorage.setItem('billingAddress', JSON.stringify(this.billingData));
      this.model.getPaymentData({ billing: this.billingData });
    }
    sessionStorage.setItem('titleFlag', JSON.stringify('Passport_2.0'))
    this.route.navigateByUrl('ShippingAddress');
  }


}

