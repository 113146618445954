import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule, NgbActiveModal, NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';


import { AppComponent } from './app.component';
import { MembershipComponent } from './membership/membership.component';
import { CustomizeComponent } from './customize/customize.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { EcommercePanelLargeComponent } from './ecommerce-panel-large/ecommerce-panel-large.component';
import { SecureBrandComponent } from './secure-brand/secure-brand.component';
import { SecureLargeButtonComponent } from './secure-large-button/secure-large-button.component';
import { EcommercePanelMediumComponent } from './ecommerce-panel-medium/ecommerce-panel-medium.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderSummaryService } from './order-summary.service';
import { ProductSelectionService } from './product-selection.service';
// import { CheckoutReadyService } from './';
import { TermsAcceptService } from './terms-accept.service';
import { ClinicServicesComponent } from './clinic-services/clinic-services.component';
import { EcommerceSmallPanelComponent } from './ecommerce-small-panel/ecommerce-small-panel.component';
import { CheckoutStatusComponent } from './checkout-status/checkout-status.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ValidationComponent } from './validation/validation.component';
import { ModelService } from './model.service';
import { UserService } from './user.service';
import { AngularWebStorageModule } from 'angular-web-storage';
import { ModalComponent } from './modal/modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CapitalizePipe } from './pipes/capitalization-pipe';
import { ReplaceDashPipe } from './pipes/replace-pipe';
import { SpinnerService } from './spinner/spinner.service';
import { InterceptorService } from './interceptor.service';
import { CalculateTotalService } from './calculate-total.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CouponsComponent } from './coupons/coupons.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
// import { ChangeDetectionDirective } from './change-detection.directive';
import { RenewalComponent } from './renewal/renewal.component';
import { ProductInterceptComponent } from './product-intercept/product-intercept.component';
import { RenewCustomizeComponent } from './renew-customize/renew-customize.component';
import { RenewClinicComponent } from './renew-clinic/renew-clinic.component';
import { RenewalRedirectComponent } from './renewal-redirect/renewal-redirect.component';
import { EvergreenMembershipComponent } from './evergreen-membership/evergreen-membership.component';
import { CancelMembershipComponent } from './cancel-membership/cancel-membership.component';
import { CancelMembershipConfirmationComponent } from './cancel-membership-confirmation/cancel-membership-confirmation.component';
import { ActivateRadiationMembershipComponent } from './activate-radiation-membership/activate-radiation-membership.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { WorkAddressComponent } from './work-address/work-address.component';
import { CreditcardRegistrationComponent } from './creditcard-registration/creditcard-registration.component';
import { RegistrationConfirmComponent } from './registration-confirm/registration-confirm.component';
import { CreditCardRadiationComponent } from './credit-card-radiation/credit-card-radiation.component';
import { ActivateRadiationConfirmationComponent } from './activate-radiation-confirmation/activate-radiation-confirmation.component';
import { ManageAutopayComponent } from './manage-autopay/manage-autopay.component';
import { ManageAutopayConfirmComponent } from './manage-autopay-confirm/manage-autopay-confirm.component';
import { TechBundleMembershipComponent } from './tech-bundle-membership/tech-bundle-membership.component';
import { TechBundleMembershipConfirmComponent } from './tech-bundle-membership-confirm/tech-bundle-membership-confirm.component';
import { LayoutModule } from '@angular/cdk/layout';


@NgModule({
  declarations: [
    AppComponent,
    MembershipComponent,
    CustomizeComponent,
    EcommercePanelLargeComponent,
    SecureBrandComponent,
    SecureLargeButtonComponent,
    EcommercePanelMediumComponent,
    OrderSummaryComponent,
    ClinicServicesComponent,
    EcommerceSmallPanelComponent,
    CheckoutStatusComponent,
    CapitalizePipe,
    ReplaceDashPipe,
    CheckoutComponent,
    ValidationComponent,
    ModalComponent,
    SpinnerComponent,
    TermsConditionsComponent,
    CouponsComponent,
    // ChangeDetectionDirective,
    ConfirmComponent,
    UpgradeComponent,
    RenewalComponent,
    ProductInterceptComponent,
    RenewCustomizeComponent,
    RenewClinicComponent,
    RenewalRedirectComponent,
    EvergreenMembershipComponent,
    CancelMembershipComponent,
    CancelMembershipConfirmationComponent,
    ActivateRadiationMembershipComponent,
    ShippingAddressComponent,
    WorkAddressComponent,
    CreditcardRegistrationComponent,
    RegistrationConfirmComponent,
    CreditCardRadiationComponent,
    ActivateRadiationConfirmationComponent,
    ManageAutopayComponent,
    ManageAutopayConfirmComponent,
    TechBundleMembershipComponent,
    TechBundleMembershipConfirmComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    AngularWebStorageModule,
    NgbModule.forRoot()
  ],
  providers: [OrderSummaryService, ModelService, SpinnerService, TermsAcceptService,
    CalculateTotalService, NgbActiveModal, ProductSelectionService,
    UserService, NgbAccordionConfig,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
