import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { ModelService } from '../model.service';
import { SpinnerService } from '../spinner/spinner.service';
import { User } from '../interfaces/user';
import { UserService } from '../user.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-cancel-membership',
  templateUrl: './cancel-membership.component.html',
  styleUrls: ['./cancel-membership.component.scss']
})
export class CancelMembershipComponent implements OnInit {
  public pageToken: any;
  private localHost = environment.serverUrl;
  private header = { 'Content-Type': 'application/x-www-form-urlencoded' };
  public packageInfo: any;
  public passportId: any;
  public productPrice: any;
  public productName: any;
  public firstName: String;
  public lastName: String;
  private ref: ChangeDetectorRef;


  constructor(private router: ActivatedRoute,
    private route: Router,
    public session: SessionStorageService,
    private http: HttpClient,
    private model: ModelService,
    private spinner: SpinnerService,
    private userInfo: UserService) { 
    router.queryParams.subscribe(token => {
      this.spinner.checkHttpPending(true);
      this.pageToken = token['token'];
      sessionStorage.setItem('token', token['token']);
    });
  }

  ngOnInit() {
    if(this.pageToken) {
      this.setPackageDetails();
      this.setUserDetails();
    }
  }

  setPackageDetails() {
    const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${this.pageToken}`, { headers: this.header });
    packages.subscribe((res: any) => {
      this.packageInfo = sessionStorage.setItem('packageInfo', JSON.stringify(res));
      let self = this;
      res.filter(function(val) {
        if (val.rate_plan.sumPrice !== 'FREE') {
          val.rate_plan.sumPrice = Number(val.rate_plan.sumPrice);
        }
        if (val.Name === 'SEC3URE Passport') {
          self.passportId = val.Id;
          self.productPrice =  Number(val.rate_plan[0].sumPrice);
          self.productName = val.Name;
          sessionStorage.setItem('expiration_date', val.expiration_date);
          sessionStorage.setItem('package_price', self.productPrice);
        }
      });
      this.spinner.checkHttpPending(false);
    });
  }

  setUserDetails() {
    this.http.post(`${this.localHost}/commerce/products/userDetails_get`, `token=${this.pageToken}`, { headers: this.header })
            .subscribe((result: User) => {
              //this.userPlanType = result.user_plan_type;
              sessionStorage.setItem('inboundUrl', result.inbound_base_url);
              this.firstName = result.first_name;
              this.lastName = result.last_name;
              this.spinner.checkHttpPending(false);
            });
  }

  goBackToParentPortal() {
    this.spinner.checkHttpPending(true);
    window.location.href = sessionStorage.getItem('inboundUrl');
  }

  confirmSelection() {
    this.route.navigateByUrl('CancelMembershipConfirmation');
  }

}
