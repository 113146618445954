import { Component, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { environment } from './environments/environment';
import { ClinicServicesComponent } from './clinic-services/clinic-services.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from './user.service';
import { SessionStorageService } from 'angular-web-storage';
import { MembershipComponent } from './membership/membership.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  public subscription: Subscription;
  public checkoutStatus: Subscription;
  public showCheckout = false;
  public isPending = false;
  public Height: number;
  public Width: number;
  public layoutTag: any;
  public userFirstName: string;
  public userLastName: string;
  public userId: string;
  public pageToken: any;
  constructor(
    private spinner: SpinnerService,
    private user: UserService,
    private router: Router,
    private location: Location,
    private session: SessionStorageService,
    private ref: ChangeDetectorRef) {
    this.subscription = this.user.userNames.subscribe((userNames) => {
      if ((userNames.firstName) && (userNames.lastName) && (userNames.userId)) {
          this.userFirstName = userNames.firstName;
          this.userLastName = userNames.lastName;
          this.userId = userNames.userId;
          this.ref.detectChanges();
      }    
     
      
    });
  }
  ngAfterViewInit() {
    this.layoutTag = sessionStorage.getItem('layoutTag');
    this.spinner.pendingSubject.subscribe((pending) => {
      this.isPending = pending;
      this.ref.markForCheck();
    });
  }
  goBack = function() {
    if (this.router.url.includes(`Membership`) || this.router.url.includes(`Renewal`) ||
        this.router.url.includes('TechBundleMembership')) {
      window.location.href = sessionStorage.getItem('inboundUrl');
    } else {
      this.location.back();
    }

  };
}
