import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../model.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { SpinnerService } from '../spinner/spinner.service';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operator/filter';
import { User } from '../interfaces/user';
import { UserService } from '../user.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-product-intercept',
  templateUrl: './product-intercept.component.html',
  styleUrls: ['./product-intercept.component.scss']
})
export class ProductInterceptComponent implements OnInit {
  isCouponEnabled: boolean;
  userNames: { firstName: string; lastName: string; email: string;  userId: string};
  public pageToken: any;
  public readyForNav = false;
  public localHost = environment.serverUrl;
  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private spinner: SpinnerService,
    private http: HttpClient,
    private model: ModelService,
    private session: SessionStorageService,
    private userInfo: UserService
  ) { 
  }

  ngOnInit() {
    let productPrice, productName, serviceLevel, productId;
    sessionStorage.clear();
    const header = { 'Content-Type': 'application/x-www-form-urlencoded' };
    this.router.queryParams.subscribe(token => {
      this.spinner.checkHttpPending(true);
      this.pageToken = token['token'];
      sessionStorage.setItem('token', this.pageToken);
      if (token['token']) {
        const packages = this.http.post(`${this.localHost}/commerce/products/packageList_get`,
          `token=${token['token']}`, { headers: header });
        packages.subscribe((res: any) => {
          res.filter(function(val, i, all) {
            if (all.length === 1) {
              productId = val.Id;
              productName = val.rate_plan[0].productName;
              productPrice = val.rate_plan[0].sumPrice;
              serviceLevel = val.rate_plan[0].serviceLevel;
            }
          });
          const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      this.http.post(`${this.localHost}/commerce/products/addonList_get`,
        `token=${this.pageToken}&package_id=${productId}&service_level=${serviceLevel}`, { headers: header })
        .subscribe(result => {
          this.http.post(`${this.localHost}/commerce/products/userData_get`, `token=${token['token']}`, { headers: header })
            .subscribe((results: User) => {
              this.userNames = { firstName: results.first_name, lastName: results.last_name, email: results.email, userId: results.userId };
              sessionStorage.setItem('user', JSON.stringify(this.userNames));
              this.userInfo.getUserNames(this.userNames);
              this.isCouponEnabled = results.coupon_enabled === '1';
              sessionStorage.setItem('coupon_enabled', JSON.stringify(this.isCouponEnabled));
              sessionStorage.setItem('inboundUrl', results.inbound_base_url);
              if (results.tdapOnFile !== null) { 
                 sessionStorage.setItem('tdapOnFile', JSON.stringify(results.tdapOnFile['tdapOnFile']));
              }
              this.userInfo.getCountries().subscribe(cl => {
                this.userInfo.getCountryList(cl);
                sessionStorage.setItem('countries', JSON.stringify(cl) );
                this.spinner.checkHttpPending(false);
                this.route.navigateByUrl('Checkout');
            });
            });
          });
          
          sessionStorage.setItem('serviceLevel', serviceLevel);
          sessionStorage.setItem('product', JSON.stringify({name: productName, price: productPrice}));
        });
      }
    });
  }

}
