import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public redirectUrl = '';
  public Height: any;
  constructor(public session: SessionStorageService) {
    this.redirectUrl = sessionStorage.getItem('inboundUrl');
  }

  ngOnInit() {
    this.Height = (window.innerHeight);
    const confirm = document.getElementById('confirmation');
    confirm.style.height = this.Height + 'px';
  }

  public viewService = function () {
    window.location.href = this.redirectUrl;
  };
}
