import { filter } from 'rxjs/operator/filter';
import { Injectable } from '@angular/core';
import { CalculateTotalService } from './calculate-total.service';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { environment } from './environments/environment';
import { SessionStorageService } from 'angular-web-storage';

@Injectable()
export class ModelService {
public myTax: any;
public localhost = environment.serverUrl;
public model: any[] = [];
public modelForUI: any[] = [];
public items: any[] = [];
public pageToken: any;
public coupons: any[] = [];
public people: any[] = [];
public quanity: any[] = [];
public emptyCart = true;
public userOjbect: any[] = [];
public shippableProduct = false;
public countries: any[] = [];
public submitData;
public sentTaxes = new Subject<any>();
public modelAddons = new Subject<any>();
public modelClinic = new Subject<any>();
public checkoutStatusButton = new Subject<any>();

  constructor(public calculate: CalculateTotalService,
    private http: HttpClient,
    private session: SessionStorageService) { 
    }
public addtoCart = function() {
this.calculate.calculateTotal();
};

public clearData = function() {
  this.submitData = {};
};

public getModelAddons = function(products, status) {
this.modelAddons.next(products);
this.modelClinic.next(products);
this.checkoutStatusButton.next(status);
};

public subscribeToTaxData(val) {
  this.pageToken = sessionStorage.getItem('token');
  const zip = val.zip.toString();
  const header = {'Content-Type': 'application/x-www-form-urlencoded'};

  return this.http.post(`${this.localhost}/commerce/checkout/saveContactDataGetTax`,
  'user_fname=' + val.firstName + '&user_lname=' + val.lastName +
  '&user_address1=' + val.address + '&user_address2=' + val.suite +
  '&user_city=' + val.city + '&user_state=' + val.state + '&user_zip=' + zip +
  '&user_country=' + val.country + '&token=' + this.pageToken, {headers: header});

}

public getTaxData = function(val) {
 this.subscribeToTaxData(val).subscribe((getTax: Response) => {
  this.myTax = getTax;
  this.getTaxInfo(getTax);
}, err => {
  console.log(err);
});
};

public getTaxInfo = function(tax) {
this.sentTaxes.next(tax);
};

public deleteFromCart = function() {
  this.calculate.calculateTotal();
};

public setCouponCode = function (couponCode) {
  this.coupons.push(couponCode);
};

public getPaymentData = function(card) {
 this.submitData = card;
};

public isShippable = function(shippable) {
  this.shippableProduct = false;
  let shippIt = false;
if (shippable) {
    shippable.cart_items.filter(function(val, index, all) {
        if (val.isShippable) {
            shippIt = true;
        }
    });
}
this.shippableProduct = shippIt;
};
public setModelData = function(addonsId, itemsDetails, peopleArray) {
  if (addonsId.length !== 0) {

    this.model.filter(function(v, i, a){
       if (v === addonsId) {
         a.splice(i, 1);
       }
    });
    if (itemsDetails.length > 0) {
        this.items.filter(function(v, i, a){
           if (v.Id === addonsId) {
             a.splice(i, 1);
           }
        });

        this.people.filter(function(v, i, a){
           if (v.Id === addonsId) {
              a.splice(i, 1);
           }
        });
    }

    this.model.push(addonsId);
    this.items.push(itemsDetails);
    this.people.push(peopleArray);
  } else {
    this.model = [];
    this.items = [];
    this.people = [];
  }
};

public setModelforUI = function(user) {

  this.modelForUI.push(user);
};
}
